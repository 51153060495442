import { ShipmentRowExtended } from '../../../interfaces';

export enum DialogChargeTypeLabels {
  paymentByTime = 'Pago por tiempo',
  paymentByTimeValue = 'paymentByTime',
  paymentType = 'Tipo de pago',
  traditionalPayment = 'Pago tradicional',
  traditionalPaymentValue = 'traditionalPayment'
}

export interface IDialogChargeTypeLabels {
  paymentByTime: string;
  paymentByTimeValue: string;
  paymentType: string;
  traditionalPayment: string;
  traditionalPaymentValue: string;
}

export interface IGeneralData {
  cancelButton: string;
  imgRef: string;
  resume?: string;
  saveButton: string;
  shipment: ShipmentRowExtended;
  title: string;
}

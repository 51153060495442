import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import { DIALOG_LOCATION_CONSTANTS } from '../../../constants/dialog-location.constants';
import { DialogLocationLabel, Shipper } from '../../../interfaces';
import { DialogLocationLabels, DialogLocationTags } from './dialog-location.labels';
import { GenericRegexp } from './../../../regexp/generic.regexp';
import { LocationProvider } from '../../../providers/locations/location-provider.service';
import { Locations } from '../../../interfaces/locations';
import { PaginatorService } from '../../../services/paginator/paginator.service';
import { ShipperService } from '../../../providers/shipper/shipper.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-dialog-location',
  templateUrl: './dialog-location.component.html',
  styleUrls: ['./dialog-location.component.scss', '../../../app.component.scss']
})

export class DialogLocationComponent implements OnInit {
  public buttonManuallyConfirmLabel: string;
  public dataSource: MatTableDataSource<Locations>;
  public displayedColumns: Array<string>;
  public identifierFormGroup: UntypedFormGroup;
  public isEcuadorDefaultLocation: boolean;
  public labels: DialogLocationLabel;
  public locationInfo: Object;
  public locationsAux: Array<any>;
  public locationsAuxLength: any;
  public locationType: string;
  public nameFormGroup: UntypedFormGroup;
  public orderType: string;
  public postalCodeFormGroup: UntypedFormGroup;
  public searchValue: any;
  public searchValueFormControl: any;
  public selected: any;
  public shipperData: Shipper;
  public shipperOid: string;
  public visible: boolean;

  constructor(
    private appService: AppService,
    private locationsProvider: LocationProvider,
    public dialogRef: MatDialogRef<DialogLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly builder: UntypedFormBuilder,
    private providerLabels: PaginatorService,
    private shipperService: ShipperService,
    private toast: ToastrAlertsService
  ) { this.labels = DialogLocationTags; }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * @description Initializes the component by setting up various properties and fetching necessary data.
   */
  public async ngOnInit() {
    this.locationType = this.data.title;
    this.locationInfo = this.data.location;
    this.orderType = this.data.orderType ? this.data.orderType : null;
    this.buttonManuallyConfirmLabel = this.data.buttonManuallyConfirm ?? AppConstants.EMPTY_STRING;
    this.visible = false;
    this.locationsAux = [];
    this.selected = new UntypedFormControl(0);
    this.searchValueFormControl = new UntypedFormControl('', Validators.required);
    this.displayedColumns = DIALOG_LOCATION_CONSTANTS.DISPLAYEDCOLUMNS;
    this.initForm(this.builder);
    this.providerLabels.paginatorLabels(this.paginator);
    this.shipperOid = this.appService.getShipperOid();
    await this.getShipperByOid(this.shipperOid);
    this.isEcuadorDefaultLocation = this.shipperData?.configuracion?.regions?.default?.prefix === DIALOG_LOCATION_CONSTANTS.ECUADORIAN_PREFIX;
  }

  public async getLocations(searchOptionSelected: any) {
    this.dataSource = new MatTableDataSource([]);
    if (this.locationType === DIALOG_LOCATION_CONSTANTS.ORIGIN) {
      let searchOriginLocationsBy = null;
      if (searchOptionSelected === 0) {
        this.searchValue = this.postalCodeFormGroup.value.postalCodeValue;
        searchOriginLocationsBy = DIALOG_LOCATION_CONSTANTS.SEARCH_PARAM_BY_ZIP;
      } else if (searchOptionSelected === DIALOG_LOCATION_CONSTANTS.SEARCH_BY_IDENTIFIER) {
        this.searchValue = this.identifierFormGroup.value.identifier.trim();
        searchOriginLocationsBy = DIALOG_LOCATION_CONSTANTS.IDENTIFIER_KEY;
      } else {
        this.searchValue = this.nameFormGroup.value.nameValue.trim();
        searchOriginLocationsBy = DIALOG_LOCATION_CONSTANTS.SEARCH_PARAM_BY_NAME;
      }
      try {
        const res = await this.locationsProvider.getOrigin(searchOptionSelected, this.searchValue.trim(), searchOriginLocationsBy);
        this.groupLocations(res);
        if (this.locationsAux.length === 0) {
          this.visible = false;
          this.toast.warningAlert(DialogLocationLabels.noLocationsFound);
        } else {
          this.dataSource.paginator = this.paginator;
          this.visible = true;
        }
      } catch (error) {
        console.log(error);
        this.toast.warningAlert(DialogLocationLabels.noLocationsFoundByShipper);
      }
    } else {
      let searchDestinationsBy = null;
      if (searchOptionSelected === 0) {
        this.searchValue = this.postalCodeFormGroup.value.postalCodeValue;
        searchDestinationsBy = DIALOG_LOCATION_CONSTANTS.SEARCH_PARAM_BY_ZIP;
      } else if (searchOptionSelected === DIALOG_LOCATION_CONSTANTS.SEARCH_BY_IDENTIFIER) {
        this.searchValue = this.identifierFormGroup.controls[DIALOG_LOCATION_CONSTANTS.IDENTIFIER_KEY].value.trim();
        searchDestinationsBy = DIALOG_LOCATION_CONSTANTS.IDENTIFIER_KEY;
      } else {
        this.searchValue = this.nameFormGroup.value.nameValue;
        searchDestinationsBy = DIALOG_LOCATION_CONSTANTS.SEARCH_PARAM_BY_NAME;
      }

      try {
        const res = await this.locationsProvider.getDestination(searchOptionSelected, this.searchValue.trim(), searchDestinationsBy);
        this.groupLocations(res);
        if (this.locationsAux.length === 0) {
          this.visible = false;
          this.toast.warningAlert(DialogLocationLabels.noLocationsFound);
        } else {
          this.dataSource.paginator = this.paginator;
          this.visible = true;
        }
      } catch (error) {
        console.log(error);
        this.toast.warningAlert(DialogLocationLabels.noLocationsFoundByShipper);
      }
    }
  }

  /**
   * @description Retrieves the shipper information by its OID and assigns it to the shipperInSession property.
   * @param shipperOid - The OID of the shipper to retrieve.
   */
  private async getShipperByOid(shipperOid): Promise<void> {
    this.shipperData = await this.shipperService.getShipperById(shipperOid);
  }

  private groupLocations(res: object): void {
    const locations: any = res;
    this.locationsAux = [];
    locations.ubicaciones.forEach(location => {
      const details = {
        _id: location._id,
        identifier: location.clienteId,
        locationId: location.clienteId,
        locationRFC: location.rfc,
        locationRuc: this.isEcuadorDefaultLocation ? location.rfc : undefined,
        name: location.nombre,
        address: location.direccion,
        postalCode: location.codigoPostal,
        municipality: location.municipio,
        state: location.estado,
        settlement: location.colonia,
        latitude: location.latitud,
        longitude: location.longitud,
        documentationDirections: location.documentationDirections ? location.documentationDirections : [],
        evidenceDirections: location.evidenceDirections ? location.evidenceDirections : []
      };

      if (location?.serviceTime) {
        details['serviceTime'] = location.serviceTime;
      }

      this.locationsAux.push(details);
    });
    this.dataSource = new MatTableDataSource(this.locationsAux);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public getRecord(info: object): void {
    info = { ...info, locationSelectedBy: DIALOG_LOCATION_CONSTANTS.LOCATIONS_CATALOG }
    this.dialogRef.close(info);
  }

  public onClickClose(): void {
    if (this.locationInfo) {
      this.dialogRef.close(this.locationInfo);
    } else {
      this.dialogRef.close();
    }
  }

  private initForm(fb: UntypedFormBuilder): void {
    const maxLength = this.isEcuadorDefaultLocation ? AppConstants.ECUADOR_POSTAL_CODE_MAX_LENGTH : AppConstants.ECUADOR_POSTAL_CODE_MAX_LENGTH;
    this.postalCodeFormGroup = fb.group({
      postalCodeValue: new UntypedFormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(maxLength)])
    });
    this.nameFormGroup = fb.group({
      nameValue: new UntypedFormControl(null, [Validators.required])
    });
    this.identifierFormGroup = fb.group({
      identifier: new UntypedFormControl(null, [Validators.required, Validators.pattern(GenericRegexp.NON_EMPTY_STRING)])
    });
  }

  public onKeyPressCode(event: KeyboardEvent): void {
    const pattern = /[0-9\b]/;
    const inputChar = event.key;

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}

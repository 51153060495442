export const DIALOG_STANDARD_BACKGROUND_INFO_PROPERTIES = {
  backgroundCheckCircleIconColor: 'resume-mat-check-circle-icon',
  backgroundInfoIconColor: 'resume-mat-info-icon',
  boldHtmlElementClose: '</b>',
  boldHtmlElementOpen: '<b>',
  confirm: 'Confirm',
  matCheckCircleIcon: 'check_circle',
  matInfoIcon: 'info',
  matWarningIcon: 'warning',
  modalImageCopyPath: '../../../assets/icons/icon-copy-cta.svg',
  modalSize: '650px',
  newLineHtmlElement: '<br>',
  redIconColor: 'resume-mat-red-icon-color'
};

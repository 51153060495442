import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { RatingModule } from 'primeng/rating';

@NgModule({
  imports: [
    ButtonModule,
    DialogModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    RatingModule
  ],
  exports: [
    ButtonModule,
    DialogModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    RatingModule
  ]
})
export class UtilsModule { }

import { SortTypes } from '../../interfaces/marketplace';
import { Rate } from '../../interfaces/';

export const ConfigurationConstants = {
  ACCOUNT_CONTROL_NAME: 'accountControlName',
  ACCOUNTS_RELEASE_ORDERS_CONTROL_NAME: 'accountsReleaseOrdersControlName',
  AUTOMATIC_TENDERING_ASSIGNATION: 'Automática',
  BACKSPACE: 'Backspace',
  CEDISEARLYHOUR: 'earlyArrivalHours',
  CEDISEARLYMINUTE: 'earlyArrivalMinutes',
  CEDISLATEHOUR: 'lateArrivalHours',
  CEDISLATEMINUTE: 'lateArrivalMinutes',
  CONFIGURATION_ROUTE: '/configuration',
  CONFIRM_STATUS : 'Confirm',
  DATE_FORMAT: 'dd/MM/yyyy h:mm:ss a',
  DEFAULT_MAX_DRIVER_INACTIVE_TIME: 2,
  DEFAULT_TENDERING_OFFERS: 3,
  ERROR_TEMPERATURES: 'La Temperatura Máxima no puede ser menor a la Temperatura Mínima',
  ERROR_TEMPERATURES_SAVED: 'Error al actualizar las temperaturas',
  INITIAL_MAX_TEMPERATURE: 5,
  INITIAL_MAX_TEMPERATURE_STRING: '5 °C',
  INITIAL_MIN_TEMPERATURE: 0,
  INITIAL_MIN_TEMPERATURE_STRING: '0 °C',
  LOGO_DIALOG_IMAGE : '../../../assets/confirmConfigModal.svg',
  MANUAL_TENDERING_ASSIGNATION: 'Manual',
  MAX_CUSTODY_SHIPPER_VALUE: 9999999.99,
  MAX_QUANTITY_PERCENTAGE: 100,
  MIN_CUSTODY_SHIPPER_VALUE: 0.01,
  NO_NUMERICAL_INTEGER_CHARACTERS: '.,+-eE',
  SLASH: '/',
  SYMBOL_TEMPERATURE: ' °C',
  TEMPERATURES_SAVED: 'La configuración ha sido guardada.',
  TENDERING_DIALOG_WIDTH: '51%',
  TENDERING_ICON: '../../../assets/illustration-config.svg'
};

export const SortOffersTendering: Array<SortTypes> = [
  {id: 1, value: 'Precio (más bajo)', name: 'Precio'},
  {id: 2, value: 'Calificación (mejor calificado)', name: 'Calificación'}
];

export const MaximumOffersTendering: Array<number> = [
  3,
  5,
  7
];

export const ParametersToAutomaticAssignation: Array<SortTypes> = [
  {id: 1, value: 'Precio'},
  {id: 2, value: 'Calificación'},
  {id: 3, value: 'Periodo de tiempo'}
];

export const RatingServices: Array<Rate> = [
  {rating: 0, description: 'Tiempo de entrega', category: 'servicios', position: 1},
  {rating: 0, description: 'Rechazo de viajes', category: 'servicios', position: 2},
  {rating: 0, description: 'Puntualidad de carga', category: 'servicios', position: 3},
  {rating: 0, description: 'Entrega de evidencias', category: 'servicios', position: 4}
];

export const RatingCost: Array<Rate> = [
  {rating: 0, description: 'Tarifa base', category: 'costos', position: 1},
  {rating: 0, description: 'Cargos adicionales', category: 'costos', position: 2}
];

export const RatingSecurity: Array<Rate> = [
  {rating: 0, description: 'Siniestros', category: 'seguridad', position: 1},
  {rating: 0, description: 'GPS 24/7', category: 'seguridad', position: 2},
  {rating: 0, description: 'Protocolo de reacción', category: 'seguridad', position: 3},
  {rating: 0, description: 'Chapa satelital', category: 'seguridad', position: 4}
];

export const RatingQuality: Array<Rate> = [
  {rating: 0, description: 'Limpieza', category: 'calidad'}
];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RouteAssociationResponse, ShipmentRouteResponse, ShipmentsInfoBody } from '../../interfaces/tms-router/shipment-route-info';
import { environment } from '../../../environments/environment';

const URL_BASE = environment.tmsRouterApi;

/**
 * @description TMS Routing Service Provider, performs HTTP requests to retrieve shipment route information.
 */
@Injectable()
export class TmsRouterProvider {
  /**
   * @description Constructor for TmsRouterProvider.
   * @param {HttpClient} http - HTTP client for making requests.
   */
  constructor(private http: HttpClient) { }

  /**
   * @description Retrieves route information for a specific tenant's shipment.
   * @param {string} tenantId - Id of the tenant.
   * @param {string} startDate - Start date in YYYY-MM-DD format.
   * @param {string} endDate - End date in YYYY-MM-DD format.
   * @returns {Promise<ShipmentRouteResponse>} A promise resolving with the route information.
   */
  public async getShipmentRouteInfo(tenantId: string, startDate: string | Date, endDate: string | Date): Promise<ShipmentRouteResponse> {
    return this.http.get<ShipmentRouteResponse>(
      URL_BASE + `/routes/tenant/${tenantId}?startDate=${startDate}&endDate=${endDate}`).toPromise();
  }

  /**
   * @description Updates shipment route information for a specific tenant.
   * @param {string} tenantId - The unique identifier of the tenant.
   * @param {ShipmentsInfoBody} body - The request body containing shipment information to update.
   * @returns {Promise<RouteAssociationResponse>} The response indicating the route association.
   */
  public async updateShipmentRouteInfo(tenantId: string, body: ShipmentsInfoBody): Promise<RouteAssociationResponse> {
    return this.http.post<RouteAssociationResponse>(URL_BASE + `/routes/tenant/${tenantId}`, body).toPromise();
  }
}

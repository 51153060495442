import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { NetPromoterScore } from '../../models/net-promoter-score';
import { NetPromoterScoreArrayResponse, NetPromoterScoreResponse } from '../../interfaces/supplynet-core/net-promoter-score.interface';

const API_URL = `${environment.supplynetCoreApiUrl}/tenants`;
const CHECK_DISPLAY = 'validateScoreDisplay';
const SOLUTION_ID_PARAM = 'solutionId';
const USER_ID_PARAM = 'userId';

/**
 * @class
 * @description - Net Promoter Score service class.
 */
@Injectable()
export class NetPromoterScoreService {
  /**
   * @description - Creates a Net Promoter Score Provider service instance.
   * @param { AppService } _appService - Application service.
   * @param { HttpClient } _http - HTTP provider service.
   */
  constructor(
    private _appService: AppService,
    private _http: HttpClient
  ) {}

  /**
   * @description - Creates a new net promoter score record in database.
   * @param { NetPromoterScore } netPromoterScore - Net promoter score to create.
   *
   * @returns { Promise<NetPromoterScoreResponse> } - Request response.
   */
  public createNetPromoterScore(netPromoterScore: NetPromoterScore): Observable<NetPromoterScoreResponse> {
    const tenantId  = netPromoterScore.tenantId ?? this._appService.getShipperOid();

    return this._http.post<NetPromoterScoreResponse>(`${API_URL}/${tenantId}/net-promoter-scores`, netPromoterScore)
    .pipe(
      catchError((error: any) => {
        throw error;
      })
    );  
  }

  /**
   * @description - Updates a net promoter score record in database.
   * @param { NetPromoterScore } netPromoterScore - Net promoter score to update.
   *
   * @returns { Observable<NetPromoterScoreResponse> } - Request response.
   */
  public updateNetPromoterScore(netPromoterScore: NetPromoterScore): Observable<NetPromoterScoreResponse> {
    const tenantId  = netPromoterScore.tenantId ?? this._appService.getShipperOid();

    return this._http.put<NetPromoterScoreResponse>(`${API_URL}/${tenantId}/net-promoter-scores/${netPromoterScore.id}`, netPromoterScore)
    .pipe(
      catchError((error: any) => {
        throw error;
      })
    ); 
  }

  /**
   * @description - Gets net promoter score records in database by userId and solutionID.
   * @param { string } userId - User identifier.
   * @param { string } tenantIdParam - Tenant identifier.
   * @param { string } solutionId - Solution identifier.
   * @param { boolean } validateScore - Whether or not checks for valid score records.
   *
   * @returns { Observable<NetPromoterScoreArrayResponse> } - Request response.
   */
  public getNetPromoterScoresByUserAndSolution(userId: string, tenantIdParam: string, solutionId: string, validateScore?: boolean): Observable<NetPromoterScoreArrayResponse> {
    const tenantId  = tenantIdParam ?? this._appService.getShipperOid();
    const options = {
      params: new HttpParams()
        .set(USER_ID_PARAM, userId)
        .set(SOLUTION_ID_PARAM, solutionId)
        .set(CHECK_DISPLAY, validateScore)
    };

    return this._http.get<NetPromoterScoreArrayResponse>(`${API_URL}/${tenantId}/net-promoter-scores`, options)
    .pipe(
      catchError((error: any) => {
        throw error;
      })
    );      
  }
}

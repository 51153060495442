export const DIALOG_LOCATION_CONSTANTS = {
  DISPLAYEDCOLUMNS: ['identifier', 'name', 'address', 'postalCode', 'state'],
  IDENTIFIER_KEY: 'identifier',
  LOCATIONS_CATALOG: 'locationsCatalog',
  ORIGIN: 'Origen',
  SEARCH_BY_IDENTIFIER: 2,
  SEARCH_PARAM_BY_NAME: 'nombre',
  SEARCH_PARAM_BY_ZIP: 'codigoPostal',
  ECUADORIAN_PREFIX: 'ECU'
};

export enum ViewOrderProperties {
  deleteDialogWidth = '650px',
  editDialogWidth = '870px',
  viewGuidesIcon = '../../../../assets/viewGuides.svg',
  warningDialog = '750px',
  warningIcon = '../../../assets/icons/torton-icon.svg'
}

export const VIEW_ORDER_CONSTANTS = {
  ORDER_LIMIT: 3000,
  TIMEOUT_LIMIT: 4000
}

<div class="dialog-title-section">
  <h3>{{ 'dialogAssociateGuidesLabels.associateCourierGuide' | translate }} {{ shipmentId }}</h3>
</div>

<div class="order-guides-inputs-section">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="orderIdentifier">
      <th mat-header-cell *matHeaderCellDef> {{ 'dialogAssociateGuidesLabels.orderIdentifier' | translate }} </th>
      <td mat-cell *matCellDef="let order"> {{ order.orderIdentifier }} </td>
    </ng-container>
    <ng-container matColumnDef="courierGuide">
      <th mat-header-cell *matHeaderCellDef> {{ 'dialogAssociateGuidesLabels.courierGuide' | translate }} </th>
      <td mat-cell *matCellDef="let order">
        <mat-form-field [class.mat-form-field-invalid]="isCourierGuideInputInvalid(order.courierGuide)">
          <input
            id="dag-guide-input"
            matInput
            type="text"
            min="3"
            minlength="3"
            maxlength="50"
            required
            placeholder="{{ 'dialogAssociateGuidesLabels.courierGuide' | translate }}"
            [(ngModel)]="order.courierGuide"
          >
        </mat-form-field>      
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="buttons-section">
  <button
    id="dag-cancel-button"
    mat-button
    class="secondary-button"
    (click)="onCancel()">
    {{ 'dialogAssociateGuidesLabels.cancel' | translate }}
  </button>
  <button
    id="dag-associate-guides-button"
    mat-button
    class="primary-button"
    [disabled]="isAssociateButtonDisable()"
    (click)="onAssociateGuides()">
    {{ 'dialogAssociateGuidesLabels.associateGuides' | translate }}
  </button>
</div>

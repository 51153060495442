<p-dialog [(visible)]="isVisible" [position]="dialogPosition" [modal]="true" [style]="{width: '25vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [modal]="isBackgroundBlocked" (onHide)="onClickSave(false)">
  <ng-template pTemplate="header">
    <span class="nps-rating-dialog-header" *ngIf="isRatingSectionActive">
      {{ 'netPromoterScoreLabels.howYouRateYourExperience' | translate }}
    </span>
    <span class="nps-rating-dialog-header" *ngIf="!isRatingSectionActive">
      <img id="nps-rating-image" class="nps-rating-image"
        src="../../../assets/icons/nps/nps-completed-icon.svg" alt="thank-u-image">
      </span>
  </ng-template>

  <div name="nps-rating-section" *ngIf="isRatingSectionActive">
    <div name="nps-rating-score-div" class="nps-rating-div">
      <p-rating [(ngModel)]="scoreValue" [stars]="scoreValueRangeMax" [cancel]="false" (onRate)="onClickRate($event)">
      </p-rating>
    </div>
    <br>
    <div name="nps-rating-form-div" class="nps-rating-div" *ngIf="scoreValue">
      <form [formGroup]="netPromoterScoreForm">
        <p-inputNumber id="score" name="score" formControlName="score" readonly="true" mode="decimal"
          ariaLabel="" inputId="score" [disabled]="true" [required]="true" [min]="1" [max]="scoreValueRangeMax" [hidden]="true" />
        <span class="p-float-label">
          <textarea [required]="true" name="user-observations" class="nps-rating-form-text-area"
            formControlName="comments" id="user-observations" rows="5" cols="35" maxlength="300" minlength="5"
            pInputTextarea required>
          </textarea>
          <label class="nps-rating-form-text-area-label" for="user-observations">{{ 'netPromoterScoreLabels.whatCanImprove' | translate }}</label>
        </span>
      </form>
    </div>

    <div name="nps-rating-button-div" class="nps-rating-button-div" *ngIf="scoreValue">
      <button pButton id="nps-rating-cancel-button" class="nps-rating-cancel-button"
        (click)="onClickSave(false)" [disabled]="isSaveButtonDisabled">
        {{'netPromoterScoreLabels.cancelButton' | translate}}
      </button>
      <button pButton id="nps-rating-submit-button" class="nps-rating-submit-button"
        (click)="onClickSave(true)" [disabled]="!netPromoterScoreForm.valid || isSaveButtonDisabled">
        {{'netPromoterScoreLabels.sendButton' | translate}}
      </button>
    </div>
  </div>

  <div name="nps-rating-thanks-section" *ngIf="!isRatingSectionActive">
    <p *ngIf="!isScoredBefore" class="nps-rating-thanks-p">
      {{ 'netPromoterScoreLabels.thankForComments' | translate }}
    </p>
    <p *ngIf="!isScoredBefore" class="nps-rating-opinion-p">
      {{ 'netPromoterScoreLabels.yourOpinionIsVeryImportant' | translate }}
    </p>
    <p *ngIf="isScoredBefore" class="nps-rating-opinion-p">
      {{ 'netPromoterScoreLabels.opinionAlreadyReceived' | translate }}
    </p>
    <div name="nps-rating-button-div" class="nps-rating-button-div" *ngIf="scoreValue">
      <button pButton id="nps-rating-close-button" class="nps-rating-submit-button"
        (click)="onClickClose()">
        {{'netPromoterScoreLabels.okButton' | translate}}
      </button>
    </div>
  </div>
</p-dialog>
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import {
  Detail,
  FilterMenu,
  ResponseResultSet,
  ResultSet,
  ShipmentExcelReport,
  ShipmentFilterParams,
  ShipmentRequest,
  ShipmentRequestBody,
  User
} from '../../interfaces';
import { DetailStatus, Profiles } from '../../enums';
import { environment } from '../../../environments/environment';
import { ReactivatedResponse } from '../../interfaces/tracking.interface';
import { ResponseShipmentRequestByStatus, ShipmentRequestByStatus } from '../../interfaces/shipmentRequestByStatus';
import { ResponseStopStatusQuantity, StopStatusQuantity } from '../../interfaces/stop-status-quantity';
import { UserService } from '../../providers/user/user.service';

const API_URL = environment.apiUrl;
const SHIPMENT_API_URL = environment.shipmentApiUrl;
const CLIENT = 'Cliente';
const CLOSE = 'close';
const TRACING = 'Seguimiento';
const URI_CLOSE = '/close/';
const URI_REACTIVATE = '/reactivate/';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {
  public detailsDelivered: number;
  public detailsArrived: number;
  public detailsPartial: number;
  public detailsPending: number;
  public detailsRejected: number;
  public detailsUndelivered: number;
  public quantityByStatus: Array<StopStatusQuantity>;
  public shipmentsByFilters: Subject<Array<FilterMenu>>;
  public shipmentSelected: Subject<ShipmentRequest>;
  public shipmentsSearchBar: Subject<{shipment: string, db: boolean}>;
  public shipperOid: string;
  public userOid: string;

  constructor(
    private _appService: AppService,
    private http: HttpClient,
    private userService: UserService
  ) {
    this.resetStopStatusQuantity();
    this.shipmentsByFilters = new Subject<Array<FilterMenu>>();
    this.shipmentSelected = new Subject<ShipmentRequest>();
    this.shipmentsSearchBar = new Subject<{ shipment: string, db: boolean}>();
    this.userOid = this._appService.getUserOid();
    this.getShipperOid();
  }

  public async getShipperOid() {
    this.shipperOid = this._appService.getShipperOid();
    if (!this.shipperOid) {
      this.shipperOid = await this._appService.getShipper();
    }
  }

  /**
  *@description This function returns the total and an array of shipmentRequests
  *@param Status: string is the status type of the shipmentRequest
  *@param limit: number how many shipments you want to find
  *@param skip: number how many shipments you want to skip
  *@return ResultSet that brings the total of shipments and an array of shipmentRequests
  **/
  public async getShipments(status: string, limit: number, skip: number): Promise<ResultSet> {
    return await this.http.get<ResultSet>(API_URL +
      '/solicitudes/usuarios/' +
      this.userOid +
      '/misMatches?estatus=' + status +
      '&cantidad=' + limit +
      '&saltar=' + skip)
      .toPromise();
  }

  /**
   * @description This function returns in route shipments for the Carrier within the status
   * 'Asignado', 'Arribado', 'Inicio Carga', 'Fin Carga', 'En transito' y 'Entregados'
   * @return ResultSet that brings the total of shipments and an array of shipmentRequests
   */
  public async getCarrierInRouteShipments(limit: number, skip: number): Promise<ResultSet> {
    const todayEndDate = moment().endOf('day').toDate();
    const todayStartDate = moment().startOf('day').toDate();
    const today = {
      begin: todayStartDate,
      end: todayEndDate
    };
    return await this.http.post<ResultSet>(`${API_URL}/shipments/shipmentsInRoute` +
      `?limit=${limit}&skip=${skip}`, today)
      .toPromise();
  }
  /**
  *@description This function returns the total and an array of shipmentRequests with status 'Asignado',
  'Arribado', 'Inicio Carga', 'Fin Carga', 'En transito' y 'Entregados'
  *@return ResultSet that brings the total of shipments and an array of shipmentRequests
  **/
  public async getInRouteShipments(limit: number, skip: number, tracking: boolean, account?: string): Promise<ResultSet> {
    const todayEndDate = moment().endOf('day').toDate();
    const todayStartDate = moment().startOf('day').toDate();
    const infoUser: User = await this.userService.getUserById(this.userOid);
    const shipperOid = this._appService.getShipperOid();
    const today = {
      begin: todayStartDate,
      end: todayEndDate
    };
    let payload = {
      fechaInicio: todayStartDate,
      fechaFin: todayEndDate
    };
    if (account) {
      payload = {...payload, ...{embarcador: infoUser.embarcador._id}};
    }
    if (account && tracking) {
      if (account === CLIENT) {
        return await this.http.post<ResultSet>(API_URL +
          '/solicitudes/cuentas/' +
          infoUser.cuenta + '/fechas', payload)
          .toPromise();
      } else {
        const elementTracing = {
          seguimiento: infoUser._id,
          associatedAccounts: infoUser.associatedAccounts,
        };
        payload = {...payload, ...elementTracing};
        return await this.http.post<ResultSet>(API_URL +
          '/solicitudes/accountTrackers/' +
          this.userOid + '/dates', payload)
          .toPromise();
      }
    } else if (account && !tracking) {
      if (account === CLIENT) {
        return await this.http.post<ResultSet>(API_URL +
          '/solicitudes/usuarios/' +
          this.userOid +
          '/misMatches?estatus=InRoute&shipperOid=' + this.shipperOid +
          '&limit=' + limit +
          '&skip=' + skip +
          '&tracking=' + tracking +
          '&client=' + infoUser.cuenta,
          today)
          .toPromise();
      } else {
        return await this.http.post<ResultSet>(API_URL +
          '/solicitudes/usuarios/' +
          this.userOid +
          '/misMatches?estatus=InRoute&shipperOid=' + this.shipperOid +
          '&limit=' + limit +
          '&skip=' + skip +
          '&tracking=' + tracking +
          '&account=' + account,
          today)
          .toPromise();
      }
    } else {
      return await this.http.post<ResultSet>(API_URL +
        '/solicitudes/usuarios/' +
        this.userOid +
        '/misMatches?estatus=InRoute&shipperOid=' + shipperOid +
        '&limit=' + limit +
        '&skip=' + skip +
        '&tracking=' + tracking, today)
        .toPromise();
    }
  }

  /**
   * @description This function returns all the information of one ShipmentRequest.
   * @param {string} shipment - String could be the 'id' or the 'reference' of the shipmentRequest.
   * @param {string} profile - Profile that makes the searching.
   * @param {string} shipperId - Current shipper identifier.
   * @param {boolean} isSummaryTemperatures - Indicates if a searching made for temperature tracking view.
   * @param {boolean} isCurrentShipments - Indicates if a searching made for current shipments view.
   * @return {ShipmentRequest | Array<ShipmentRequest>} One shipmentRequest.
   */
  public async getOneShipment(shipment: string, profile?: string, shipperId?: string, isSummaryTemperatures?: boolean, isCurrentShipments?:boolean): Promise<any> {
    const shipperOid = shipperId ? shipperId : this._appService.getShipperOid();
    if (profile === TRACING) {
      return await this.http.get<ShipmentRequest>(API_URL +
        '/solicitudes/' + shipment +
        '/dashboard?emb=' + this.shipperOid +
        '&seguimiento=' + this.userOid)
        .toPromise();
    } else if (profile === CLIENT) {
      const infoUser = await this.userService.getUserById(this._appService.getUserOid());
      return await this.http.get<ShipmentRequest>(API_URL +
        '/solicitudes/' + shipment +
        '/dashboard?emb=' + this.shipperOid +
        '&cuenta=' + infoUser.cuenta)
        .toPromise();
    } else if (isSummaryTemperatures) {
      return await this.http.get<Array<ShipmentRequest>>(API_URL +
        '/solicitudes/' + shipment +
        '/dashboard?emb=' + shipperOid +
        '&action=historico' +
        '&userOid=' + this.userOid +
        '&isSummaryTemperatures=' + isSummaryTemperatures)
        .toPromise();
    } else {
      let url = API_URL + '/solicitudes/' + shipment + '/dashboard?emb=' + shipperOid + '&action=historico' +
        '&userOid=' + this.userOid;

      if (isCurrentShipments) {
        url += url + '&isCurrentShipments=' + isCurrentShipments;
      }

      return await this.http.get<ShipmentRequest>(url)
        .toPromise();
    }
  }

  /**
   * @description Generate request for get the shipment request by array of folios
   * @param {ShipmentRequestBody} body object to send with criteria of search
   * @param {string} shipperId: the 'id' of the shipper
   * @return ResultSet that brings the total of shipments and an array of shipmentRequests
   **/
  public async getShipmentsByFolio(body: ShipmentRequestBody, shipperId?: string): Promise<Array<ShipmentRequest>> {
    const shipperOid = shipperId ?? this._appService.getShipperOid();
    const url = `${API_URL}/shipmentRequest?shipperId=${shipperOid}&action=historico&userOid=${this.userOid}`;
    return await this.http.post<Array<ShipmentRequest>>(url, body).toPromise();
  }

  /**
  *@description This function helps to filter in the shipments grid by 'id' or 'reference'
  *@param shipment: string could be the 'id' or the 'reference' of the shipmentRequest
  **/
  public searchShipment(shipment: string, db: boolean): void {
    this.shipmentsSearchBar.next({ shipment, db });
  }

  /**
  *@description This function helps to filter in the shipments specific filters
  *@param filters: FilterMenu[] all the params of the filters
  **/
  public filterShipment(filters: Array<FilterMenu>): void {
    this.shipmentsByFilters.next(filters);
  }

  /**
  *@description This function is to clear the observable when no filters are selected
  **/
  public clearFilterShipment(): void {
    this.shipmentsByFilters.next();
  }

  /**
  *@description This function actives the observable for the searchingbox
  *@return Observable of a shipment:string
  **/
  public getSearchShipment(): Observable<{shipment: string, db: boolean}> {
    return this.shipmentsSearchBar.asObservable();
  }

  /**
  *@description This function actives the observable for the filters
  *@return Observable of FilterMenu[]
  **/
  public getFilterShipment(): Observable<Array<FilterMenu>> {
    return this.shipmentsByFilters.asObservable();
  }

  /**
  *@description This function actives the observable for the shipmentsBar
  *@return Observable of ShipmentRequest
  **/
  public getShipmentSelected(): Observable<ShipmentRequest> {
    return this.shipmentSelected.asObservable();
  }

  /**
  *@description Helps to select one of the shipments
  *@param shipment: ShipmentRequest
  **/
  public selectShipment(shipment: ShipmentRequest): void {
    this.shipmentSelected.next(shipment);
  }

  /**
  *@description This function is to clear the observable when no shipment is selected
  **/
  public clearShipmentSelected(): void {
    this.shipmentSelected.next();
  }

  /**
  *@description This function returns the total and an array of shipmentRequests
  *@param filters: FilterMenu[] all the filters to send it to the backend
  *@param limit: number how many shipments you want to find
  *@param skip: number how many shipments you want to skip
  *@return ResultSet that brings the total of shipments and an array of shipmentRequests
  **/
  public async searchByFilters(filters: Array<FilterMenu>, limit?: number, skip?: number, account?: string): Promise<ResultSet> {
    const shipperOid = this._appService.getShipperOid();
    const common = `${API_URL}/solicitudes/usuarios/${this.userOid}/misMatches?cantidad=` +
      `${limit}&saltar=${skip}&shipperOid=`;

    if (account === CLIENT) {
      const infoUser: User = await this.userService.getUserById(this.userOid);
      return await this.http.post<ResultSet>( common + this.shipperOid +
        '&client=' + infoUser.cuenta, filters)
        .toPromise();
    } else if (account) {
      return await this.http.post<ResultSet>( common + this.shipperOid +
        '&account=' + account, filters)
        .toPromise();
    } else if (!shipperOid) {
      return this.getShipmentsCarrier(filters, limit, skip);
    } else {
      return await this.http.post<ResultSet>( common + shipperOid, filters)
        .toPromise();
    }
  }

  /**
   * @description Get stop status quantity for each status
   * @param filters FilterMenu
   * @returns stop status quantity group
   */
  public async getGeneralDeliverySummary(filters: FilterMenu[], account?: string): Promise<StopStatusQuantity[]> {
    const shipperOid = this._appService.getShipperOid();
    if (account === CLIENT) {
      const infoUser: User = await this.userService.getUserById(this.userOid);
      return await this.http.post<StopStatusQuantity[]>(API_URL +
        '/solicitudes/' + this.shipperOid + '/generalDeliverySummary?client=' + infoUser.cuenta, filters).toPromise();
    } else if (account) {
      return await this.http.post<StopStatusQuantity[]>(API_URL +
        '/solicitudes/' + this.shipperOid + '/generalDeliverySummary?account=' + account, filters).toPromise();
    } else if (!shipperOid) {
      return await this.http.post<StopStatusQuantity[]>(API_URL +
        '/shipments/generalDeliverySummary', filters).toPromise();
    } else {
      return await this.http.post<StopStatusQuantity[]>(API_URL +
        '/solicitudes/' + shipperOid + '/generalDeliverySummary', filters).toPromise();
    }
  }

  /**
  *@description Resets the counters of the general delivery status bar
  **/
  public resetStopStatusQuantity(): void {
    this.quantityByStatus = [];
    this.detailsArrived = 0;
    this.detailsDelivered = 0;
    this.detailsPartial = 0;
    this.detailsPending = 0;
    this.detailsRejected = 0;
    this.detailsUndelivered = 0;
  }

  /**
  *@description For each detail of each shipmentRequest counts and generates the array for the
  *             general delivery status bar
  **/
  public calculeteStopStatusQuantity(shipments: Array<ShipmentRequest>): Array<StopStatusQuantity> {
    this.resetStopStatusQuantity();
    shipments.forEach((shipment: ShipmentRequest) => {
      shipment.detalles.forEach((detail: Detail) => {
        this.selectStatus(detail.estatus);
      });
    });
    this.generateStopStatusQuantity();
    return this.quantityByStatus;
  }

  /**
  *@description Selects the status and count all the status
  **/
  public selectStatus(status: Detail['estatus']): void {
    if (status === DetailStatus.Pending) {
      this.detailsPending++;
    } else if (status === DetailStatus.Arrived) {
      this.detailsArrived++;
    } else if (status === DetailStatus.Delivered) {
      this.detailsDelivered++;
    } else if (status === DetailStatus.Partial) {
      this.detailsPartial++;
    } else if (status === DetailStatus.Undelivered) {
      this.detailsUndelivered++;
    } else if (status === DetailStatus.Rejected) {
      this.detailsRejected++;
    } else { return; }
  }

  /**
  *@description Generates the array for the general delivery status bar
  **/
  public generateStopStatusQuantity(): void {
    if (this.detailsPending > 0) {
      let Pending: StopStatusQuantity;
      Pending = {
        _id: DetailStatus.Pending,
        estatus: DetailStatus.Pending,
        total: this.detailsPending
      };
      this.quantityByStatus.push(Pending);
    }
    if (this.detailsArrived > 0) {
      let Arrived: StopStatusQuantity;
      Arrived = {
        _id: DetailStatus.Arrived,
        estatus: DetailStatus.Arrived,
        total: this.detailsArrived
      };
      this.quantityByStatus.push(Arrived);
    }
    if (this.detailsDelivered > 0) {
      let Delivered: StopStatusQuantity;
      Delivered = {
        _id: DetailStatus.Delivered,
        estatus: DetailStatus.Delivered,
        total: this.detailsDelivered
      };
      this.quantityByStatus.push(Delivered);
    }
    if (this.detailsPartial > 0) {
      let Partial: StopStatusQuantity;
      Partial = {
        _id: DetailStatus.Partial,
        estatus: DetailStatus.Partial,
        total: this.detailsPartial
      };
      this.quantityByStatus.push(Partial);
    }
    if (this.detailsUndelivered > 0) {
      let Undelivered: StopStatusQuantity;
      Undelivered = {
        _id: DetailStatus.Undelivered,
        estatus: DetailStatus.Undelivered,
        total: this.detailsUndelivered
      };
      this.quantityByStatus.push(Undelivered);
    }
    if (this.detailsRejected > 0) {
      let Rejected: StopStatusQuantity;
      Rejected = {
        _id: DetailStatus.Rejected,
        estatus: DetailStatus.Rejected,
        total: this.detailsRejected
      };
      this.quantityByStatus.push(Rejected);
    }
  }

  /**
   * @description Get shipment request summary
   * @param filters FilterMenu[] Searching criteria
   */
  public async getShipmentRequestSummary(filters: Array<FilterMenu>, account?: string): Promise<Array<ShipmentExcelReport>> {
    const shipperOid = this._appService.getShipperOid();
    if (account === CLIENT) {
      const infoUser: User = await this.userService.getUserById(this.userOid);
      return await this.http.post<Array<ShipmentExcelReport>>(API_URL +
        '/shipmentRequest/' + this.userOid + '/shipmentRequestSummary?shipperOid='
        + this.shipperOid + '&client=' + infoUser.cuenta, filters).toPromise();
    } else if (account) {
      return await this.http.post<Array<ShipmentExcelReport>>(API_URL +
        '/shipmentRequest/' + this.userOid + '/shipmentRequestSummary?shipperOid='
        + this.shipperOid + '&account=' + account, filters).toPromise();
    } else {
      return await this.http.post<Array<ShipmentExcelReport>>(API_URL +
        '/shipmentRequest/' + this.userOid + '/shipmentRequestSummary?shipperOid='
        + shipperOid, filters).toPromise();
    }
  }

  /**
   * @description Get shipment request summary by shipment object id
   * @param shipmentOid string Shipment to be searched
   */
  public async getShipmentRequestSummaryById(shipmentOid: string, account?: string): Promise<Array<ShipmentExcelReport>> {
    const shipperOid = this._appService.getShipperOid();
    if (account === CLIENT) {
      const infoUser: User = await this.userService.getUserById(this.userOid);
      return await this.http.get<Array<ShipmentExcelReport>>(API_URL +
        '/shipmentRequest/' + this.userOid + '/shipmentRequestSummary?shipperOid='
        + this.shipperOid + '&shipmentOid=' + shipmentOid + '&client=' + infoUser.cuenta).toPromise();
    } else if (account) {
      return await this.http.get<Array<ShipmentExcelReport>>(API_URL +
        '/shipmentRequest/' + this.userOid + '/shipmentRequestSummary?shipperOid='
        + this.shipperOid + '&shipmentOid=' + shipmentOid + '&account=' + account).toPromise();
    } else {
      return await this.http.get<Array<ShipmentExcelReport>>(API_URL +
        '/shipmentRequest/' + this.userOid + '/shipmentRequestSummary?shipperOid='
        + shipperOid + '&shipmentOid=' + shipmentOid).toPromise();
    }
  }


  /**
   * @description Get shipmentRequest status quantity for each status
   * @param filters FilterMenu
   * @returns shipmentRequest status quantity group
   */
  public async getShipmentRequestByStatus(filters: Array<FilterMenu>, account?: string): Promise<Array<ShipmentRequestByStatus>> {
    const shipperOid = this._appService.getShipperOid();
    if (account === CLIENT) {
      const infoUser: User = await this.userService.getUserById(this.userOid);
      return await this.http.post<Array<ShipmentRequestByStatus>>(API_URL +
        '/solicitudes/' + this.shipperOid + '/shipmentRequestByStatus?client=' + infoUser.cuenta, filters).toPromise();
    } else if (account) {
      return await this.http.post<Array<ShipmentRequestByStatus>>(API_URL +
        '/solicitudes/' + this.shipperOid + '/shipmentRequestByStatus?account=' + account, filters).toPromise();
    } else if (!shipperOid) {
      return await this.http.post<Array<ShipmentRequestByStatus>>(API_URL +
        '/shipments/shipmentsByStatus', filters).toPromise();
    } else {
      return await this.http.post<Array<ShipmentRequestByStatus>>(API_URL +
        '/solicitudes/' + shipperOid + '/shipmentRequestByStatus', filters).toPromise();
    }
  }

  /**
   * @description Get the shipments like profile carrier
   * @param {FilterMenu} filters Item of the filter
   */
  public async getShipmentsCarrier(filters?: Array<FilterMenu>, limit?: number, skip?: number): Promise<ResultSet> {
    const setLimit = limit || 60;
    const setSkip = skip || 0;
    return await this.http.post<ResultSet>(
      `${API_URL}/shipments/shipmentRequestBoard?limit=${setLimit}&skip=${setSkip}`,
      filters
    ).toPromise();
  }

  /**
   * @description Reactivates or closes shipment request
   * @param {string} shipmentRequestOid shipment request to be reactivated or closed
   * @param {string} action whether reactivates or closes request
   * @returns {Promise<ReactivatedResponse>} Object with the response to update
   */
  public async reactivateCloseShipmentRequest(shipmentRequestOid: string, action: string): Promise<ReactivatedResponse> {
    const tenantId = this._appService.getShipperOid();
    let actionUri;
    if (action === CLOSE) {
      actionUri = URI_CLOSE;
    } else {
      actionUri = URI_REACTIVATE;
    }
    return this.http.post<ReactivatedResponse>(API_URL + '/shipment-request/tenant/' + tenantId + actionUri + shipmentRequestOid, {
      user: this._appService.getShipperNameCookie()
    }).toPromise();
  }

  /**
   * @description Gets the shipments filtered by the filters on current shipments view.
   * @param {Array<FilterMenu>} filters - Filters sent to get shipments according to them.
   * @param {number} limit - Add a limit to search shipments request by HTTP petition.
   * @param {number} skip - Said to Mongo where begins returning results.
   * @param {ShipmentFilterParams} params - Params to search shipments request.
   * @returns {ResultSet | Array<ShipmentExcelReport>} Results according to shipments filters.
   */
  public async searchShipmentsRequestByFilter(filters: Array<FilterMenu>, limit?: number, skip?: number, params?: ShipmentFilterParams):
    Promise<ResultSet | Array<ShipmentExcelReport>> {
    const shipperOid = this._appService.getShipperOid();
    let route = `${SHIPMENT_API_URL}/shipper/${shipperOid}/shipmentsByFilters?limit=` +
    `${limit}&skip=${skip}`;

    if (params && params.isExcelGeneration) {
      route += '&isExcelGeneration=' + params.isExcelGeneration;
    }

    const response = await this.http.post<ResponseResultSet>(route, filters).toPromise();

    return response.item;
  }

  /**
   * @description Gets the shipments request according to range date passed.
   * @param {ShipmentFilterParams} params - Params to search shipments request.
   * @param {number} limit - Add a limit to search shipments request by HTTP petition.
   * @param {number} skip - Said to Mongo where begins returning results.
   * @returns {ResultSet | Array<ShipmentExcelReport>} Results according to range date sent.
   */
  public async getShipmentRequestByRangeDate(params: ShipmentFilterParams, limit: number, skip: number):
    Promise<ResultSet | Array<ShipmentExcelReport>> {
    const accountsOid = params.accountsOid ?? [];
    const shipperOid = this._appService.getShipperOid();
    let route = SHIPMENT_API_URL + '/shipper/' + shipperOid + '/byRangeDate?beginDate=' + params.beginDate.toISOString() +
    '&endDate=' + params.endDate.toISOString() + '&skip=' + skip + '&limit=' + limit;

    if (params.profileName === Profiles.Carrier) {
      route += '&profileName=' + params.profileName + '&carrierName=' + params.carrierName;
    }

    if (params.isExcelGeneration) {
      route += '&isExcelGeneration=' + params.isExcelGeneration;
    }

    if (params.shouldGetCourierShipments) {
      route += '&shouldGetCourierShipments=' + params.shouldGetCourierShipments;
    }
    const response = await this.http.post<ResponseResultSet>(route, accountsOid).toPromise();

    return response.item;
  }

  /**
   * @description Gets shipments request status summary.
   * @param {Array<FilterMenu>} filters - Filters setted to get the shipments request.
   * @param {ShipmentFilterParams} params - Params to search shipments request.
   * @returns {Array<ShipmentRequestByStatus>} Shipment request status summary.
   */
  public async getShipmentRequestStatus(filters: Array<FilterMenu>, params?: ShipmentFilterParams):
    Promise<Array<ShipmentRequestByStatus>> {
    const shipperOid = this._appService.getShipperOid();
    let route = SHIPMENT_API_URL + '/shipper/' + shipperOid + '/shipmentsStatus?';

    if (params?.endDate && params?.beginDate) {
      route += 'beginDate=' + params.beginDate + '&endDate=' + params.endDate;
    }

    if (params?.profileName ===  Profiles.Carrier) {
      route += '&profileName=' + params.profileName + '&carrierName=' + params.carrierName;
    }

    const response = await this.http.post<ResponseShipmentRequestByStatus>(route, filters).toPromise();

    return response.item;
  }

  /**
   * @description Gets delivery summary details.
   * @param {Array<FilterMenu>} filters - Filters setted to get the shipments request.
   * @param {ShipmentFilterParams} params - Params to search shipments request.
   * @returns {Array<StopStatusQuantity>} A summary of delivery details from shipments request found.
   */
  public async getDeliverySummaryDetails(filters: Array<FilterMenu>, params?: ShipmentFilterParams): Promise<Array<StopStatusQuantity>> {
    const shipperOid = this._appService.getShipperOid();
    let route = SHIPMENT_API_URL + '/shipper/' + shipperOid + '/deliverySummary?';

    if (params?.endDate && params?.beginDate) {
      route += 'beginDate=' + params.beginDate + '&endDate=' + params.endDate;
    }

    if (params?.profileName ===  Profiles.Carrier) {
      route += '&profileName=' + params.profileName + '&carrierName=' + params.carrierName;
    }
    const response = await this.http.post<ResponseStopStatusQuantity>(route, filters).toPromise();

    return response.item;
  }
}

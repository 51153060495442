<div class="quick-filters-container">
  <ng-container *ngFor="let filter of filters">
    <mat-card class="filter-item" (click)="emitAction(filter.name)" [class.selected]="filter.name === selectedAction">
      <div class="stats">{{ filter.recordsQuantity}}</div>

      <div class="labels-container">
        <div class="main-label" translate>{{ filter.title }}</div>
        <div class="sub-label" translate>{{ filter.subtitle }}</div>
      </div>

      <span
        *ngIf="!filter.isSvgIcon"
        class="material-icons filter-icon"
        [class.success]="true">
        {{ filter.icon }}
      </span>

      <img
        *ngIf="filter.isSvgIcon"
        src="{{ filter.icon }}"
        class="image-icon-class">
    </mat-card>
  </ng-container>
</div>

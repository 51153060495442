/**
 * @description This section is to import all angular modules
 */
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { registerLocaleData } from '@angular/common';
import localeESP from '@angular/common/locales/es';
import localeESPExtra from '@angular/common/locales/extra/es';
registerLocaleData(localeESP, 'es', localeESPExtra);

/**
 * @description This section is to import all npm modules
 */
import { AgmDirectionModule } from 'agm-direction';
import { CookieService } from 'ngx-cookie-service';
import { HaversineService } from 'ng2-haversine';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxPrintModule } from 'ngx-print';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from './modules/shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import {
  ScfCardModule,
  ScfChartModule,
  ScfCommunicationService,
  ScfDBGridModule,
  ScfDBHeaderModule,
  ScfHeaderModule,
  ScfNotifierModule,
  ScfSidebarModule
} from 'scf-library';

/**
 * @description This section is to import all components required
 */
import { AppComponent } from './app.component';
import { DialogAddAdditionalChargesComponent } from
  './components/dialog/dialog-add-additional-charges/dialog-add-additional-charges.component';
import { DialogAssignTransportComponent } from './components/dialog/dialog-assign-transport/dialog-assign-transport.component';
import { DialogAuthErrorComponent } from './components/dialog/dialog-auth-error/dialog-auth-error.component';
import {
  DialogBillingSchemeVariableComponent
} from './components/dialog/dialog-account-billing-scheme/dialog-billing-scheme-variable/dialog-billing-scheme-variable.component';
import { DialogBulkLoadErrorsComponent } from './components/dialog/dialog-bulk-load-errors/dialog-bulk-load-errors.component';
import { DialogChargeValidationComponent } from './components/dialog/dialog-charge-validation/dialog-charge-validation.component';
import { DialogCheckTemperaturesComponent } from './components/dialog/dialog-check-temperatures/dialog-check-temperatures.component';
import {
  DialogCreateBulkLoadConfigComponent
} from './components/dialog/dialog-create-bulk-load-config/dialog-create-bulk-load-config.component';
import { DialogDuplicateAssignmentComponent } from './components/dialog/dialog-duplicate-assignment/dialog-duplicate-assignment.component';
import { DialogEditFreightCostComponent } from './components/dialog/dialog-edit-freight-cost/dialog-edit-freight-cost.component';
import { DialogFilePreviewComponent } from './components/dialog/dialog-file-preview/dialog-file-preview.component';
import { DialogFilesDetailComponent } from './components/dialog/dialog-files-detail/dialog-files-detail.component';
import { DialogGuideCaptureComponent } from './components/dialog/dialog-guide-capture/dialog-guide-capture.component';
import { DialogImportationProcessComponent } from './components/dialog/dialog-importation-process/dialog-importation-process.component';
import {
  DialogImportationVehiclesDetailsComponent
} from './components/dialog/dialog-importation-vehicles-details/dialog-importation-vehicles-details.component';
import { DialogInputComponent } from './components/dialog/dialog-input/dialog-input.component';
import { DialogLocationComponent } from './components/dialog/dialog-location/dialog-location.component';
import { DialogLogDetailsComponent } from './components/dialog/dialog-log-details/dialog-log-details.component';
import {
  DialogOrderBulkLoadConfigComponent
} from './components/dialog/dialog-order-bulk-load-config/dialog-order-bulk-load-config.component';
import { DialogOrderDetailComponent } from './components/dialog/dialog-order-detail/dialog-order-detail.component';
import { DialogOrderRemoveComponent } from './components/dialog/dialog-order-remove/dialog-order-remove.component';
import { DialogPasswordComponent } from './components/dialog/dialog-password/dialog-password.component';
import { DialogPrivateCarrierComponent } from './components/dialog/dialog-private-carrier/dialog-private-carrier.component';
import { DialogReasonCreateComponent } from './components/dialog/dialog-reason/dialog-reason-create/dialog-reason-create.component';
import { DialogRevertFailedStampComponent } from './components/dialog/dialog-revert-failed-stamp/dialog-revert-failed-stamp.component';
import { DialogScannedGuidesComponent } from './components/dialog/dialog-scnd-guides-detail/dialog-scanned-guides-detail.component';
import { DialogSelectOptionComponent } from './components/dialog/dialog-select-option/dialog-select-option.component';
import { DialogSelectWarehouseComponent } from './components/dialog/dialog-select-warehouse/dialog-select-warehouse.component';
import { DialogSessionExpiredComponent } from './components/dialog/dialog-session-expired/dialog-session-expired.component';
import { DialogShipmentDetailComponent } from './components/dialog/dialog-shipment-detail/dialog-shipment-detail.component';
import { DialogShipperCreatedComponent } from './components/dialog/dialog-shipper-created/dialog-shipper-created.component';

import { DialogStandardComponent } from './components/dialog/dialog-standard/dialog-standard.component';
import { DialogStandardFocusComponent } from './components/dialog/dialog-standard-focus/dialog-standard-focus.component';
import { DialogSuccessDetailComponent } from './components/dialog/dialog-success-detail/dialog-success-detail.component';
import { DialogTransportAssignmentComponent } from './components/dialog/dialog-transport-assignment/dialog-transport-assignment.component';
import { DialogViewGuidesComponent } from './components/dialog/dialog-view-guides-detail/dialog-view-guides-detail.component';
import { DialogViewsGuidesComponent } from './components/dialog/dialog-view-guides/dialog-view-guides.component';
import { routing } from './app.routing';

/**
 * @description This section is to import all providers and services required
 */
import { AccountBillingSchemeConceptProvider } from './providers/account-billing-schemes/account-billing-scheme-concept.service';
import { AccountBillingSchemeProvider } from './providers/account-billing-schemes/account-billing-scheme.service';
import { AccountProvider } from './providers/accounts/account-provider.service';
import { AdditionalChargeProvider } from './providers/additional-charge/additional-charge.provider.service';
import { ApplicationInsightsErrorHandler } from './monitoring/application-insights/application-insights-error-handler';
import { ApplicationInsightsService } from './monitoring/application-insights/application-insights.service';
import { AppService } from './app.service';
import { ArrayToolsService } from './services/utils/array-tools.service';
import { AuthService } from './authentication/auth.service';
import { BillingSheetProvider } from './providers/billing-sheet/billing-sheet.provider.service';
import { BlobProvider } from './providers/evidence/blob-provider.service';
import { BoolToWordPipe } from './pipes/bool-to-word.pipe';
import { CarrierProvider } from './providers/carrier/carrier.provider.service';
import { CmrConsignmentNoteService } from './services/cmr-consignment-note/cmr-consignment-note';
import { CommunicationService } from './services/communication';
import { ConfigurationProvider } from './providers/configuration/configuration.provider.service';
import { CostReviewProvider } from './providers/cost-review/cost-review.provider.service';
import { CourierService } from './providers/courier/courier-service.provider';
import { CofeprisUtilsService } from './services/product/cofepris-utils.service';
import { CostingSheetService } from './services/billing-sheet/billing-sheet.service';
import { DataToolsService } from './services/utils/data-tools.service';
import { DateToolsService } from './services/utils/date-tools.service';
import { DDMMYYYY_FORMAT } from './services/date-format';
import { DriverProvider } from './providers/driver/driver.provider.service';
import { EvidenceProvider } from './providers/evidence/evidence-provider.service';
import { EvidencesProviderService } from './providers/evidence/evidences-provider.service';
import { ExcelValidationService } from './services/utils/excel-validation.service';
import { FileConversorService } from './services/file-conversor';
import { FormattedDateService } from './services/utils/formatted-date.service';
import { FormsService } from './services/utils/forms.service';
import { FreightRateProvider } from './providers/freight-rate/freight-rate.provider.service';
import { ImportationService } from './providers/importation/importation.services';
import { IncidenceProvider } from './providers/incidence/incidence-provider.service';
import { IntegratorProvider } from './providers/integrator/integrator-provider.service';
import { InvoiceProposalProvider } from './providers/invoice-proposal/invoice-proposal.provider.service';
import { InvoiceProvider } from './providers/invoice/invoice.provider.service';
import { LabelProvider } from './providers/label/label-provider.service';
import { LoadAllocationService } from './services/load-allocation/load-allocation.service';
import { LoadTypeProvider } from './providers/load-type/load-type.provider.service';
import { LocalStorageService } from './services/utils/local-storage.service';
import { LocationProvider } from './providers/locations/location-provider.service';
import { MobilityShipmentService } from './services/mobility-shipment.service';
import { MunicipalityService } from './services/utils/municipality.service';
import { NotificationsService } from './providers/notifications/notifications.service';
import { OrderPrioritizationService } from './services/order-prioritization/order-prioritization';
import { OrderProvider } from './providers/orders/order-provider.service';
import { PackageProvider } from './providers/package/package-provider.service';
import { PaginatorService } from './services/paginator/paginator.service';
import { ReasonProvider } from './providers/reason/reason.provider.service';
import { ReceiverProvider } from './providers/receiver/receiver-provider.service';
import { RegionProvider } from './providers/regions/region-provider.service';
import { ReportProvider } from './providers/reports/report-provider.service';
import { RequestToolsService } from './services/utils/request-tools.service';
import { ResponseInterceptorProvider } from './providers/response-interceptor/response-interceptor.service';
import { ReviewCustomerBillingService } from './services/review-customer-billing/review-customer-billing.service';
import { ReviewPaymentSupplierService } from './services/review-payment-supplier/review-payment-supplier.service';
import { RoadnetProvider } from './providers/roadnet/roadnet-provider.service';
import { RoadnetService } from './services/roadnet/roadnet-service';
import { RoleProvider } from './providers/role/role-provider.service';
import { SalesSheetProvider } from './providers/sales-sheet/sales-sheet.provider.service';
import { SalesSheetService } from './services/sales-sheet/sales-sheet.service';
import { SasProvider } from './providers/evidence/sas-provider.service';
import { ServerlessProvider } from './providers/serverless/serverless-provider.service';
import { ServiceTypeProvider } from './providers/service-type/service-type-provider.service';
import { ShipmentProvider } from './providers/shipments/shipment-provider.service';
import { ShipmentSaleChargeProvider } from './providers/shipment-sale-charge/shipment-sale-charge-provider.service';
import { StaticCatalogsService } from './services/utils/static-catalogs.service';
import { TaxStampProvider } from './providers/tax-stamp/tax-stamp.provider';
import { TmsRouterProvider } from './providers/tms-router/tms-router.provider';
import { ToastrAlertsService } from './services/utils/toastr-alerts.service';
import { TokenInterceptorProvider } from './providers/token-interceptor/token-interceptor.service';
import { TrailerProvider } from './providers/trailers/trailers.provider';
import { UrlBuilderService } from './services/utils/url-builder.service';
import { UtilsService } from './services/utils.service';
import { VehicleControlProvider } from './providers/vehicle-control/vehicle-control.provider';
import { VehicleProvider } from './providers/vehicles/vehicles.service';
import { VehiculeTypeProvider } from './providers/vehiculeType/vehiculeType.provider.service';
import { ViewProvider } from './providers/view/view-provider.service';
import { WarehouseProvider } from './providers/warehouse/warehouse-provider.service';

import { CorrectMisspellingPipe } from './pipes/correct-misspelling.pipe';
/**
 * @description This section is to import all pages required
 */
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { CustomerInvoiceComponent } from './pages/costs/customer-invoice/customer-invoice.component';
// import { LoginComponent } from './pages/login/login.component';
import { NoPermissionComponent } from './pages/no-permission/no-permission.component';
import { PdfComponent } from './pages/pdf/pdf.component';

/**
 * @description This section is to import all iframes required
 */
import { AccountsComponent } from './components/iframes/accounts/accounts.component';
import { CarrierRequestComponent } from './components/iframes/carrier-request/carrier-request.component';
import { CatalogsComponent } from './components/iframes/catalogs/catalogs.component';
import { DialogAssociateGuidesComponent } from './components/dialog/dialog-associate-guides/dialog-associate-guides.component';
import { DialogChargeTypeComponent } from './components/dialog/dialog-charge-type/dialog-charge-type.component';
import { DialogCustomerInvoiceDetailComponent } from './components/dialog/dialog-customer-invoice-detail/dialog-customer-invoice-detail.component';
import { 
  DialogGenerateChargesStandardComponent 
} from './components/dialog/dialog-generate-charges-standard/dialog-generate-charges-standard.component';
import { 
  DialogStandardBackgroundInfoComponent 
} from './components/dialog/dialog-standard-background-info/dialog-standard-background-info.component';
import { DialogInvoiceRecordComponent } from './components/dialog/dialog-invoice-record/dialog-invoice-record.component';
import { DialogSalesRateConfirmComponent } from './components/dialog/dialog-sales-rate/dialog-sales-rate-confirm/dialog-sales-rate-confirm.component';
import { DialogShipmentCreationComponent } from './components/dialog/dialog-shipment-creation/dialog-shipment-creation.component';
import { DialogTemperatureAlertComponent } from './components/dialog/dialog-temperature-alert/dialog-temperature-alert.component';
import { IncidenceCatalogComponent } from './components/iframes/incidence-catalog/incidence-catalog.component';
import { LocationCatalogComponent } from './components/iframes/location-catalog/location-catalog.component';
import { LocationsLoadComponent } from './components/iframes/locations-load/locations-load.component';
// import { LogoutComponent } from './components/iframes/logout/logout.component';
import { ManageNotificationsComponent } from './components/iframes/manage-notifications/manage-notifications.component';
import { NetPromoterScoreModule } from './modules/net-promoter-score/net-promoter-score.module';
import { PagesComponent } from './pages.component';
import { RecurrenceComponent } from 'src/app/components/recurrence/recurrence.component';
import { RejectionCatalogComponent } from './components/iframes/rejection-catalog/rejection-catalog.component';
import { RequestLoadComponent } from './components/iframes/request-load/request-load.component';
import { RequestLogComponent } from './components/iframes/request-log/request-log.component';
import { SecurityComponent } from './components/iframes/security/security.component';
import { ShipmentEventsComponent } from './components/iframes/shipment-events/shipment-events.component';
import { ShipperConfigurationComponent } from './components/iframes/shipper-configuration/shipper-configuration.component';
import { ShipperRequestsComponent } from './components/iframes/shipper-requests/shipper-requests.component';
import { ShippersComponent } from './components/iframes/shippers/shippers.component';
import { TravelHistoryComponent } from './components/iframes/travel-history/travel-history.component';

/**
 * @description This section is to import environment variables
 **/
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';

import { AuthErrorInterceptor } from './interceptors/auth-error.interceptor';
import { LanguageTranslateService } from './services/translate/language-translate.service';
import { SsoAuthenticationService } from './services/authentication/sso-authentication.service';
import { SupplyNetLoginComponent } from './pages/supplynet-login/supplynet-login.component';
import { SupplyNetLogoutComponent } from './pages/supplynet-logout/supplynet-logout.component';
import { TokenInterceptor } from './interceptors/token.interceptor';

const googleMaps = environment.googleMaps;


/**
 * @description - Initializes Keycloak Instance.
 * @param { SsoAuthenticationService } ssoAuthService - SSO Autehntication Service.
 *
 * @returns { Promise<boolean> } - Whether Keycloak is initiated or not.
 */
function kcFactory(ssoAuthService: SsoAuthenticationService): () => Promise<boolean> {
  return () => {
    return ssoAuthService.init();
  };
}

@NgModule({
  declarations: [
    AccountsComponent,
    AppComponent,
    CarrierRequestComponent,
    CatalogsComponent,
    ConfigurationComponent,
    CustomerInvoiceComponent,
    DialogAddAdditionalChargesComponent,
    DialogAssignTransportComponent,
    DialogAssociateGuidesComponent,
    DialogAuthErrorComponent,
    DialogBillingSchemeVariableComponent,
    DialogBulkLoadErrorsComponent,
    DialogChargeTypeComponent,
    DialogChargeValidationComponent,
    DialogCheckTemperaturesComponent,
    DialogCreateBulkLoadConfigComponent,
    DialogCustomerInvoiceDetailComponent,
    DialogDuplicateAssignmentComponent,
    DialogEditFreightCostComponent,
    DialogFilePreviewComponent,
    DialogFilesDetailComponent,
    DialogGenerateChargesStandardComponent,
    DialogGuideCaptureComponent,
    DialogImportationProcessComponent,
    DialogImportationVehiclesDetailsComponent,
    DialogInputComponent,
    DialogStandardBackgroundInfoComponent,
    DialogInvoiceRecordComponent,
    DialogLocationComponent,
    DialogLogDetailsComponent,
    DialogOrderBulkLoadConfigComponent,
    DialogOrderDetailComponent,
    DialogOrderRemoveComponent,
    DialogPasswordComponent,
    DialogPrivateCarrierComponent,
    DialogReasonCreateComponent,
    DialogRevertFailedStampComponent,
    DialogScannedGuidesComponent,
    DialogSelectOptionComponent,
    DialogSelectWarehouseComponent,
    DialogSessionExpiredComponent,
    DialogShipmentCreationComponent,
    DialogShipmentDetailComponent,
    DialogShipperCreatedComponent,
    DialogStandardComponent,
    DialogStandardFocusComponent,
    DialogSalesRateConfirmComponent,
    DialogSuccessDetailComponent,
    DialogTemperatureAlertComponent,
    DialogTransportAssignmentComponent,
    DialogViewGuidesComponent,
    DialogViewsGuidesComponent,
    IncidenceCatalogComponent,
    LocationCatalogComponent,
    LocationsLoadComponent,
    //LoginComponent,
    //LogoutComponent,
    ManageNotificationsComponent,
    NoPermissionComponent,
    PagesComponent,
    PdfComponent,
    RecurrenceComponent,
    RejectionCatalogComponent,
    RequestLoadComponent,
    RequestLogComponent,
    SecurityComponent,
    ShipmentEventsComponent,
    ShipperConfigurationComponent,
    ShipperRequestsComponent,
    ShippersComponent,
    SupplyNetLoginComponent,
    SupplyNetLogoutComponent,
    TravelHistoryComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AgmCoreModule.forRoot({ apiKey: googleMaps, libraries: ['places'] }),
    AgmDirectionModule,
    BrowserAnimationsModule,
    CommonModule,
    DragDropModule,
    MatDialogModule,
    MatMenuModule,
    MatSliderModule,
    NetPromoterScoreModule,
    NgxBarcodeModule,
    NgxPrintModule,
    QRCodeModule,
    routing,
    ScfCardModule,
    ScfChartModule,
    ScfDBGridModule,
    ScfDBHeaderModule,
    ScfHeaderModule,
    ScfNotifierModule,
    ScfSidebarModule,
    SharedModule,
    RouterModule,
    ToastrModule.forRoot({
      enableHtml: true,
      timeOut: 6000,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      closeButton: true,
      toastClass: 'custom-toastr',
      titleClass: 'custom-title-toastr',
      messageClass: 'custom-message-toastr'
    }),
    HttpClientModule
  ],
  providers: [
    AccountBillingSchemeConceptProvider,
    AccountBillingSchemeProvider,
    AccountProvider,
    AdditionalChargeProvider,
    ApplicationInsightsService,
    AppService,
    ArrayToolsService,
    AuthService,
    BillingSheetProvider,
    CostingSheetService,
    BlobProvider,
    BoolToWordPipe,
    CarrierProvider,
    CmrConsignmentNoteService,
    CommunicationService,
    ConfigurationProvider,
    CookieService,
    CorrectMisspellingPipe,
    CostReviewProvider,
    CourierService,
    CofeprisUtilsService,
    DataToolsService,
    DatePipe,
    DateToolsService,
    DecimalPipe,
    DragDropModule,
    DriverProvider,
    EvidenceProvider,
    EvidencesProviderService,
    ExcelValidationService,
    FileConversorService,
    FormattedDateService,
    FormsService,
    FreightRateProvider,
    GoogleMapsAPIWrapper,
    HaversineService,
    ImportationService,
    IncidenceProvider,
    IntegratorProvider,
    InvoiceProposalProvider,
    InvoiceProvider,
    LanguageTranslateService,
    LabelProvider,
    LoadAllocationService,
    LoadTypeProvider,
    LocalStorageService,
    LocationProvider,
    MobilityShipmentService,
    MunicipalityService,
    NotificationsService,
    OrderPrioritizationService,
    OrderProvider,
    PackageProvider,
    PaginatorService,
    ReviewPaymentSupplierService,
    ReviewCustomerBillingService,
    ReasonProvider,
    ReceiverProvider,
    RegionProvider,
    ReportProvider,
    RequestToolsService,
    RoadnetProvider,
    RoadnetService,
    RoleProvider,
    SalesSheetProvider,
    SalesSheetService,
    SasProvider,
    ScfCommunicationService,
    ServerlessProvider,
    ServiceTypeProvider,
    ShipmentSaleChargeProvider,
    ShipmentProvider,
    StaticCatalogsService,
    TaxStampProvider,
    TmsRouterProvider,
    ToastrAlertsService,
    TrailerProvider,
    UrlBuilderService,
    UtilsService,
    VehicleControlProvider,
    VehicleProvider,
    VehiculeTypeProvider,
    ViewProvider,
    WarehouseProvider,
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorProvider, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptorProvider, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DDMMYYYY_FORMAT },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MatDialogRef, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: APP_INITIALIZER, deps: [SsoAuthenticationService], multi: true, useFactory: kcFactory },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthErrorInterceptor }
  ],
  entryComponents: [
    DialogAssociateGuidesComponent,
    DialogChargeTypeComponent,
    DialogCheckTemperaturesComponent,
    DialogCustomerInvoiceDetailComponent,
    DialogDuplicateAssignmentComponent,
    DialogEditFreightCostComponent,
    DialogFilePreviewComponent,
    DialogImportationProcessComponent,
    DialogInvoiceRecordComponent,
    DialogLocationComponent,
    DialogOrderDetailComponent,
    DialogOrderRemoveComponent,
    DialogPrivateCarrierComponent,
    DialogScannedGuidesComponent,
    DialogSelectWarehouseComponent,
    DialogShipperCreatedComponent,
    DialogStandardComponent,
    DialogStandardFocusComponent,
    DialogSuccessDetailComponent,
    DialogTransportAssignmentComponent,
    DialogViewGuidesComponent,
    DialogViewsGuidesComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

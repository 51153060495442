import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { QuickFilterProperties } from '../../interfaces/vehicle-access-control';

@Component({
  selector: 'app-quick-filters',
  styleUrls: ['./quick-filters.component.scss'],
  templateUrl: './quick-filters.component.html'
})

/**
 * Components for quick filters.
 */
export class QuickFiltersComponent implements OnInit {
  @Output() public filtered = new EventEmitter<string>();
  @Input() public filters: Array<QuickFilterProperties>;
  @Input() public isDisabled?: boolean;
  @Input() public preSelectedAction?: string;

  public selectedAction: string;

  /**
   * @description Emits action to parent component.
   * @param {string} action - Action identifier to emmit.
   */
  public emitAction(action: string): void {
    if (!this.isDisabled && this.selectedAction !== action) {
      this.selectedAction = action;
      this.filtered.emit(action);
    } else if (!this.isDisabled) {
      this.selectedAction = undefined;
      this.filtered.emit(undefined);
    }
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public ngOnInit(): void {
    this.selectedAction = this.preSelectedAction ? this.preSelectedAction : undefined;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConstants } from '../../constants/app-constants.constants';
import {
  ArrayShipmentRequest,
  ArrayShipmentsId,
  BatchedResponse,
  DragAndDropFile,
  MatchedGuidesResponse,
  RemoveAssignedOrders,
  ResponseShipmentRequest,
  RevertableShipmentResponse,
  SearchShipmentBody,
  ShareShipmentDataConfig,
  ShipmentLog,
  ShipmentRequest,
  Shipments,
  ShipmentsFound,
  ShipmentsRequestToDelivery,
  ShipmentStatusUpdateBody,
  ShipmentStatusUpdateResponse,
  ShipmentTaxStampUpdateBody,
  ShipmentUpdateResponse,
  UpdateShipmentStatusParams
} from '../../interfaces';
import { BlobProvider } from '../../providers/evidence/blob-provider.service';
import { ChangeShipmentRequestStatusResponse, ParamsRequest } from '../../interfaces/status-changes';
import { environment } from '../../../environments/environment';
import { LoadPlanData, ShipmentUpdateMobilityId, ShipmentResponse, ShipmentUpdateBodyRequest } from '../../interfaces/shipment';
import { ImportationShipments } from '../../../app/interfaces/importation-shipments';
import {
  MassiveTransportAssignationResponse,
  MassiveTransportAssignExcelRow,
} from '../../interfaces/massive-movements';
import {
  RegisterWarehouseAccessParams,
  RegisterWarehouseDepartureParams,
  UpdateShipmentLoadDatesParams,
  UpdateShipmentRequestStatusBody,
  UpdateShipmentRequestStatusResponse
} from '../../interfaces/vehicle-access-control';
import { RequestToolsService } from '../../services/utils/request-tools.service';
import { ShipmentEvidenceRequestResponse } from '../../interfaces/evidenceDownload/orderEvidenceDownload';
import { RevertShipmentStatusUpdateBody } from '../../interfaces/revert-shipment';
import { ShipmentCheckConfirmationResponse, ShipmentCreateResponse } from './../../interfaces/shipment';
import { ShipmentLogs } from '../../interfaces/shipment-logs';
import { ShipmentOrderData } from '../../interfaces/shipment-order-update.interface';
import { ShipmentSelectFilter } from '../../enums/shipment-selectFilter';
import { SearchShipmentLoadNotExecute, ShipmentChargeBody, ShipmentLoadNotExecuteResponse, ShipmentSaleChargeLoadNotExecuteResponse } from '../../interfaces/shipment-load-not-executed';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';
import { TrackingService } from '../../providers/tracking/tracking.service';

import { Observable } from 'rxjs';

const apiUrl = environment.apiUrl;
const shipmentApiUrl = environment.shipmentApiUrl;
const tmsFreightCostApiUrl = environment.tmsFreightCostApiUrl;

@Injectable()
export class ShipmentProvider {

  constructor(
    private blobProvider: BlobProvider,
    private http: HttpClient,
    private request: RequestToolsService,
    private toastService: ToastrAlertsService,
    private trackingService: TrackingService
  ) { }

  /**
   * @description Create a shipment
   * @param {Object} shipments Shipment's body
   * @param {string} userName Shipper username
   * @param {boolean} isCollectionRequest Flag to check by which view is called
   * @returns {ShipmentCreateResponse} Shipment created
   */
  public async createShipment(shipments: Object, userName: string, isCollectionRequest?: boolean): Promise<ShipmentCreateResponse> {
    isCollectionRequest = isCollectionRequest ?? false;
    return this.http.post<ShipmentCreateResponse>(shipmentApiUrl + '?user=' + userName + '&isCollectionRequest=' +
      isCollectionRequest, shipments).toPromise();
  }

  public async getShipmentsByShipperId(tenantId: string, shipperId: string): Promise<Shipments[]> {
    return this.http.get<Shipments[]>(shipmentApiUrl + '/tenantId/' + tenantId + '/shipperId/' + shipperId).toPromise();
  }

  public async releaseShipment(shipment: string, body?: object): Promise<void> {
    return this.http.put<void>(apiUrl + '/shipmentRequest/' + shipment + '/releaseShipment', body).toPromise();
  }

  /**
   * @description It updates the shipment's status. If it's Confirmado, it also updates the Mobility Id to the shipment.
   * @param {UpdateShipmentStatusParams} params - Contains neccessary params to execute the petition. 
   */
  public async updateShipmentStatus(params: UpdateShipmentStatusParams): Promise<ShipmentStatusUpdateResponse> {
    return await this.http.put<ShipmentStatusUpdateResponse>(shipmentApiUrl + '/tenantId/' + params.tenantId + '/shipperId/' +
      params.shipperId + '/shipmentId/' + params.shipmentId + '/status?user=' + params.username, params.newShipmentStatus).toPromise();
  }

  /**
   * @description It updates the shipment's status, adding the field taxStampStatus
   * @param {string} tenantId Tenant object id associated
   * @param {string} shipperId Shipper's shipments
   * @param {string} shipmentId Shipment identifier
   * @param {ShipmentTaxStampUpdateBody} newtaxStampStatus Object with the new taxStampStatus
   * @returns
   */
  public async updateTaxStampStatus(
    tenantId: string,
    shipperId: string,
    shipmentId: string,
    newtaxStampStatus: ShipmentTaxStampUpdateBody): Promise<string> {
    return await this.http.put<string>(shipmentApiUrl + '/tenantId/' + tenantId + '/shipperId/'
      + shipperId + '/shipmentId/' + shipmentId + '/taxStamp', newtaxStampStatus).toPromise();
  }

  public async deleteShipment(tenantId: string, shipperId: string, shipmentToDelete: string): Promise<any> {
    return await this.http.delete<Object>(shipmentApiUrl + '/tenantId/' + tenantId + '/shipperId/' + shipperId
      + '/shipment/' + shipmentToDelete).toPromise();
  }

  public async getShipmentByShipmentId(tenantId: string, shipperId: string, shipmentToSearch: string): Promise<Object> {
    return await this.http.get<Object>(shipmentApiUrl + '/tenantId/' + tenantId + '/shipperId/' + shipperId
      + '/shipment/' + shipmentToSearch).toPromise();
  }

  public async getShipmentsByDriver(driverId: string, bodySearch: object): Promise<Object> {
    return await this.http.post<Object>(apiUrl + '/solicitudes/chofer/' + driverId + '?ordenes=true', bodySearch).toPromise();
  }

  /**
   * @description Updates the info from shipment
   * @param {string} tenantId Id from tenant
   * @param {string} shipmentId Id from shipment to update
   * @param shipmentBody New data of shipment
   * @param {string} userName Username who mades the change of shipment
   * @param {boolean} isJustUpdatingDistance Flag to know if the method is called just to update the distance and duration of shipment
   * route
   * @returns {Promise<ShipmentUpdateResponse>} response of endpoint
   */
  public async updateShipment(tenantId: string, shipmentId: string, shipmentBody: any, userName: string, isJustUpdatingDistance?: boolean):
     Promise<ShipmentUpdateResponse> {
    return this.http.put<ShipmentUpdateResponse>(
      `${shipmentApiUrl}/tenantId/${tenantId}/shipperId/${tenantId}/shipment/${shipmentId}?user=${userName}` +
      `&isJustUpdatingDistance=${isJustUpdatingDistance}`, shipmentBody).toPromise();
  }

  /**
   * @description Updates the info from shipment.
   * @param {string} tenantId Id from tenant.
   * @param {ShipmentUpdateBodyRequest} shipmentBody New data of shipment.
   * @param {string} userName Username who mades the change of shipment
   * @returns {Promise<ShipmentUpdateResponse>} response of endpoint
   */
  public async updateMultipleShipments(tenantId: string, shipmentBody: ShipmentUpdateBodyRequest, userName: string): Promise<ShipmentUpdateResponse> {
    return this.http.post<ShipmentUpdateResponse>(`${shipmentApiUrl}/tenantId/${tenantId}/status?user=${userName}`, shipmentBody).toPromise();
  }

  /**
   * @description Import shipments
   * @param {ImportationShipments[]} shipments Shipments to be imported
   * @param {string} shipperOid Shipper's shipments
   * @param {string} isMassiveLoadShipment flag to change the chunkSize to 3 for massive Load shipment
   * @return {Promise<BatchedResponse>} Batched response
   */
  public async importShipments(shipments: ImportationShipments[], shipperOid: string, isMassiveLoad?: boolean): Promise<BatchedResponse> {
    const url = isMassiveLoad ? apiUrl + '/shipments/' + shipperOid + '/importation' + '?massiveLoad=' + isMassiveLoad :
                apiUrl + '/shipments/' + shipperOid + '/importation';
    const chunkSize = isMassiveLoad ? 3 : 5;
    const response = await this.request.doPOSTRequestByBatch(url, shipments, chunkSize);

    return response;
  }

  public async createMobilityShipment(shipments: Object): Promise<Object> {
    return await this.http.post(apiUrl + '/solicitudes', shipments).toPromise();
  }

  /**
   * @description endpoint for create the shipmentRequest by typeTrip 'Cliente recoge'
   * @param {Object} shipment the data for create the shipmentRequest
   * @returns {Promise<ResponseShipmentRequest>} the data of response 'ShipmentRequest'
   */
  public async createShipmentRequest(shipment: Object): Promise<ResponseShipmentRequest> {
    return await this.http.post<ResponseShipmentRequest>(apiUrl + '/shipmentRequest/ConfirmShipment', shipment).toPromise();
  }

  public async getRequestByInternalReference(internalReference: string, tenantId: string): Promise<ShipmentRequest> {
    return await
      this.http.get<ShipmentRequest>(`${apiUrl}/solicitudes/${internalReference}/dashboard?emb=${tenantId}&action=historico`).toPromise();
  }

  /**
   *
   * @param {string} tenantId - Shipper who owns shipments.
   * @param {SearchShipmentBody} searchBody - Filters search.
   * @param {ShipmentSelectFilter} selectFilter - Shipment select filter to retrive shipments with or without orders.
   * @returns {Promise<Array<Shipments>>} Shipments found
   */
  public async shipmentSearch(tenantId: string, searchBody: SearchShipmentBody, selectFilter?: ShipmentSelectFilter): Promise<Array<Shipments>> {
    let route = `${shipmentApiUrl}/tenantId/${tenantId}/search`;

    if (selectFilter) {
      route += `?selectFilter=${selectFilter}`;
    }

    return this.http.post<Array<Shipments>>(route, searchBody).toPromise();
  }

  /**
   * @description Gets Shipment by folio shipment
   * @param {string} tenantId Tenant object id associated
   * @param {string} shipmentId Shipment id associated (EM)
   * @returns {Shipments} Shipment body searched
   */
  public async shipmentSearchByFolio(tenantId: string, shipmentId: string): Promise<Array<Shipments>> {
    const response = await this.http.get<Array<Shipments>>(
      `${shipmentApiUrl}/tenantId/${tenantId}/shipmentId/${shipmentId}/search`).toPromise();
    return response;
  }

  /**
   * @description Get shipments by Roadnet entity key
   * @param tenantId The tenant object id associated
   * @param shipperId The shipper object id associated
   * @param searchBody Array with shipments entity key to find
   */
  public async getShipmentByRoadnetEntityKey(tenantId: string, shipperId: string, searchBody: Array<number>) {
    return this.http.post<Array<Shipments>>(`${shipmentApiUrl}/tenantId/${tenantId}/shipperId/${shipperId}/entityKey`, searchBody)
      .toPromise();
  }

  /**
   * @description Get shipment log
   * @param {ShipmentOId} shipment Shipment objectId
   * @return {Promise<ShipmentLog>} Shipment Log
   */
  public async getShipmentLog(shipmentOId: string): Promise<ShipmentLog> {
    return this.http.get<ShipmentLog>(shipmentApiUrl + '/shipment/' + shipmentOId + '/logs').toPromise();
  }

  /**
   * @description Get shipments by Internal reference
   * @param {string} carrierOid Carrier Object Id
   * @param {Array<string>} references Array of Internal references
   * @returns {Array<ShipmentRequest>} Array of shipment requests
   */
  public async getShipmentsByInternalReferences(carrierOid: string, references: Array<string>): Promise<Array<ShipmentRequest>> {
    const body = { shipments: references };
    return this.http.post<Array<ShipmentRequest>>(`${apiUrl}/shipment/carrier/${carrierOid}`, body).toPromise();
  }

  /**
   * @description Check for shipment validation to perform confirmation action
   * @param {string} shipmentOId ShipmentOId to perform confirmation
   * @returns {Promise<ShipmentCheckConfirmationResponse>} Whether can confirm shipment and error
   */
  public async checkConfirmation(shipmentOId: string): Promise<ShipmentCheckConfirmationResponse> {
    return this.http.get<ShipmentCheckConfirmationResponse>(`${shipmentApiUrl}/checkConfirmation/${shipmentOId}`).toPromise();
  }

  public async getShipmentRequestByOid(shipmentRequestOid: string): Promise<ShipmentRequest> {
    return await this.http.get<ShipmentRequest>(apiUrl + '/solicitudes/' + shipmentRequestOid).toPromise();
  }

  /**
   * @description Get all shipmentRequests available to display in Access Control View.
   * @param {string} shipperId - The current shipperOId to find.
   * @param {string} userId -  User Id to filter shipment to get.
   * @returns {Promise<Array<ShipmentRequest>>} An array with all the shipmentRequests available.
   */
  public async getShipmentsOfDayOnAccessControl(shipperId: string, userId?: string): Promise<Array<ShipmentRequest>> {
    const url = `${apiUrl}/folioAcceso?embarcador=${shipperId}&user=${userId}`;

    return await this.http.get<Array<ShipmentRequest>>(url).toPromise();
  }

  /**
   * @description Get a specific shipmentRequest available to display in Access Control View.
   * @param {string} shipperId - The current shipperOId to find.
   * @param {string} shipmentId - The shipmentId to find.
   * @param {string} userId -  User Id to filter shipment to get.
   * @returns {Promise<ShipmentRequest>} Object with the shipmentRequest data.
   */
  public async getShipmentsByFolioOnAccessControl(shipperId: string, shipmentId: string,
    userId?: string): Promise<ShipmentRequest> {
    const url = `${apiUrl}/folioAcceso/${shipmentId}?embarcador=${shipperId}&user=${userId}`;

    return await this.http.get<ShipmentRequest>(url).toPromise();
  }

  /**
   * @description Update the shipmentRequest status
   * @param {string} shipmentOId ShipmentOid to update
   * @param {UpdateShipmentRequestStatusBody} body Body to be sent
   * @returns {Promise<UpdateShipmentRequestStatusResponse>} Response with shipmentRequest status
   */
  public async updateShipmentRequestStatus(shipmentOId: string, body: UpdateShipmentRequestStatusBody):
    Promise<UpdateShipmentRequestStatusResponse> {
    return await this.http.put<UpdateShipmentRequestStatusResponse>(`${apiUrl}/solicitudes/${shipmentOId}/estatus`, body).toPromise();
  }

  /**
   * @description sends data to core-api to update an stop
   * @param shipmentOId shipment request ObjectId
   * @param body data wich is gonna be sent to database
   */
  public async updateShipmentRequestStop(shipmentOId: string, body: object) {
    await this.http.put<void>(`${apiUrl}/solicitudes/${shipmentOId}/detalles`, body).toPromise();
  }

    /**
   * @description Update orders from shipment then cancel the shipment if dont have orders
   * @param {string} tenant
   * @param {string} shipmentId Body to be sent
   * @param {Array<Order>} orders
   * @returns {Promise<Array<String>>} Response with shipmentRequest status
   */
  public async removeOrdersShipments(tenant: string, shipmentId: string, user: string, body: RemoveAssignedOrders):
    Promise<Array<String>> {
    return this.http
      .put<Promise<Array<String>>>(`${shipmentApiUrl}/tenant/${tenant}/shipment/${shipmentId}/removeOrders?user=${user}`, body).toPromise();
  }

  /**
   * @description Update a one order on shipment with given data
   * @param {ShipmentOrderData} orderData The data to updte on shipment
   * @param {string} tenantId The identifier client
   * @param {string} currentShipmentOid The current shipment identifier
   * @returns {Observable<ShipmentRequest>}
   */
  public updateShipmentOrder(orderData: ShipmentOrderData, tenantId: string, currentShipmentOid: string): Observable<ShipmentRequest> {
    return this.http.put<ShipmentRequest>(`${shipmentApiUrl}/tenant/${tenantId}/shipment/${currentShipmentOid}/order/update`, orderData);
  }

  /**
   * @description Get if shipment is revertable
   * @param {string} shipperId The current shipperOId to find
   * @param {string} shipmentId The shipmentId to find
   * @returns {Promise<RevertableShipmentResponse>} Object with the shipmentRequest data
   */
  public async getRevertableShipment(shipperId: string, shipmentId: string): Promise<RevertableShipmentResponse> {
    return await this.http.get<RevertableShipmentResponse>(`${apiUrl}/shipper/${shipperId}/shipment/${shipmentId}/revert`).toPromise();
  }

  /**
   * @description Get matches between white guides and courier guides
   * @param {string} tenantId The current shipperOId to find
   * @param {string} folio The order's folio to find
   * @returns {Promise<RevertableShipmentResponse>} Object matches guides
   */
   public async getMatchedGuidesByOrder(tenantId: string, folio: string): Promise<Array<MatchedGuidesResponse>> {
    return await this.http.get<Array<MatchedGuidesResponse>>
    (`${shipmentApiUrl}/tenant/${tenantId}/order/${folio}/matchedGuides`).toPromise();
  }

  /**
   * @description Revert shipment
   * @param {string} shipperId The current shipperOId
   * @param {string} shipmentId The shipmentId to revert
   * @param {ShipmentStatusUpdateBody} newShipmentStatus Update body
   * @returns {Promise<any>} Object with the shipmentRequest data
   */
  public async revertShipment(shipperId: string, shipmentId: string, newShipmentStatus: RevertShipmentStatusUpdateBody): Promise<any> {
    return await this.http.post<any>(`${apiUrl}/shipper/${shipperId}/shipment/${shipmentId}/revert`, newShipmentStatus).toPromise();
  }

  /**
   * @description Get shipments status by shipment identifier (EM)
   * @param {string} shipperId The current shipperOId
   * @param {ArrayShipmentsId} shipmentsId A collection with shipment identifiers
   * @returns {Array<object>} A collection with shipments status and extra info
   */
  public async getShipmentStatus(shipperId: string, shipmentsId: ArrayShipmentsId): Promise<Array<object>> {
    return await this.http.post<Array<object>>(`${shipmentApiUrl}/shipperOid/${shipperId}/searchStatus`, shipmentsId).toPromise();
  }

  /**
   * @description Get multiple shipments requests by shipmentRequests folio (S2)
   * @param {string} shipperOid - Current shipperOId
   * @param {Array<ArrayShipmentRequest>} shipmentsRequestFolio - Shipments request folios
   * @param {boolean} isCurrentShipments - Retrive shipment request without files.
   * @returns {Array<ShipmentRequest>} An with shipments request
   */
  public async getShipmentsRequest(shipperOid: string, shipmentsRequestFolio: ArrayShipmentRequest,
    isCurrentShipments?: boolean): Promise<Array<ShipmentRequest>> {
    let path = `${apiUrl}/shipperOid/${shipperOid}/shipmentsRequests/byShipmentFolios`;

    if (isCurrentShipments) {
      path += `?isCurrentShipments=true`;
    }

    return this.http.post<Array<ShipmentRequest>>(path, shipmentsRequestFolio).toPromise();
  }

  /**
   * @description Update multiples shipments request, shipments and orders status
   * @param {string} shipperOid Current shipper Oid
   * @param {ShipmentsRequestToDelivery} shipmentReqBody An object with username and shipment request to deliver
   * @param {string} username Current username
   * @returns {object} A response with a message success
   */
  public async deliveryMultipleShipments(shipperOid: string, shipmentReqBody: Array<ShipmentsRequestToDelivery>,
    username: string): Promise<object> {
    const url = apiUrl + '/shipper/' + shipperOid + '/username/' + username + '/deliveryShipmentsMassive';
    const chunkSize = 250;
    const response = await this.request.doPOSTRequestByBatch(url, shipmentReqBody, chunkSize);

    return response;
  }

  /**
   * @description sends excel data to core-api and validates carrier, driver, vehicle, vehicle type and trailers info
   * @param shipperOId the current session shipper object id
   * @param workingSheetData the working sheet
   * @returns response with valid shipments and errors to be shown in txt file
   */
  public async validateMassiveTransportAssignmentData(shipperOId: string,
    workingSheetData: Array<MassiveTransportAssignExcelRow>): Promise<Array<MassiveTransportAssignExcelRow>> {
    return this.http.post<Array<MassiveTransportAssignExcelRow>>(
      `${apiUrl}/shipper/${shipperOId}/massiveTransportAssignment`,
      workingSheetData
    ).toPromise();
  }

  /**
   * @description sends validated data to shipment api to update shipments transport and update shipments log
   * @param shipperOId the current session shipper object id
   * @param workingSheetData the working sheet
   * @param userName the current session user name
   * @returns response with updated shipments and errors found to be shown in txt file
   */
  public async shipmentMassiveTransportAssignment(shipperOId: string, workingSheetData: Array<MassiveTransportAssignExcelRow>,
    userName: string): Promise<MassiveTransportAssignationResponse> {
    return this.http.put<MassiveTransportAssignationResponse>(
      `${shipmentApiUrl}/shipper/${shipperOId}/massiveTransportAssignment?user=${userName}`,
      workingSheetData
    ).toPromise();
  }

  /**
   * @description Gets shipments for evidence download data
   * @param tenantId Required shipper id to found shipments data
   * @param shipmentsIds List of shipments ids to found
   * @returns Array of Shipments
   */
  public async getShipmentsForEvidenceDownload(tenantId: string, shipmentsIds: Array<string>) {
    return this.http.post<ShipmentEvidenceRequestResponse>
    (`${shipmentApiUrl}/tenantId/${tenantId}/ForEvidenceDownload`, shipmentsIds)
    .toPromise();
  }

  /**
   * @description Share with integrator wms load data from shipment to be confirmed
   * @param {LoadPlanData} loadPlanData Load plan data of shipment
   * @param {string} shipperOid Oid of shipper
   * @returns {ShipmentEvidenceRequestResponse} response of endpoint
   */
  public async shareLoadPlanData(loadPlanData: LoadPlanData, shipperOid: string) {
    return this.http.post<ShipmentEvidenceRequestResponse>
      (`${apiUrl}/loadPlanImportation/${shipperOid}/shareLoadPlan`, loadPlanData).toPromise();
  }

  /**
   * @description Register unrelated orders in portage shipment
   * @param {Array<string>} guides array of guides unrelated
   * @param {string} shipperOid Oid of shipper
   * @param {string} shipmentId Oid of shipment
   */
   public async unrelatedOrders(guides: Array<string>, shipperOid: string, shipmentId: string) {
    return this.http.post<void>
      (`${shipmentApiUrl}/tenant/${shipperOid}/shipment/${shipmentId}/Entry/unrelatedOrders`, guides).toPromise();
  }

  /**
   * @description validates if shipment trip type is customer pickup and if exists idetification files to upload
   * @param {boolean} isCustomerPickupShipment Flag to check if truType is customer pickup
   * @param {Array<DragAndDropFile | string>} identificationFiles files object or string to load
   * @param {string} errorMssg Error to display if the upload of files fails
   * @returns {Promise<Array<string>>} An array with all Urls of files uploaded or empty array if the condition doesn't is satified
   */
  public async validateAndUploadFiles(isCustomerPickupShipment: boolean, identificationFiles: Array<DragAndDropFile | string>,
    errorMssg: string): Promise<Array<string>> {
    try {
      if (isCustomerPickupShipment && identificationFiles) {
        const blobNames = await this.saveIdentificationFiles(identificationFiles);

        return blobNames;
      } else {
        return [];
      }
    } catch (error) {
      this.toastService.errorAlert(errorMssg);
    }
  }

  /**
   * @description Check if identification files have been already saved in shipment to save the url.
   * Otherwise saves the new images selected
   * @param {Array<string | DragAndDropFile>} identificationFiles Url or file object of files to upload
   * @returns {<Array<string>} An array with all url´s from files saved
   */
  public async saveIdentificationFiles(identificationFiles: Array<string | DragAndDropFile>): Promise<Array<string>> {
    const filesUrl = [];
    for (const file of identificationFiles) {
      if (typeof file === 'object') {
        const extension: Array<string> = file.name.split(AppConstants.DOT_CHAR);
        const response = await this.saveFile(file.file, extension[extension.length - 1]);
        filesUrl.push(response);
      } else {
        filesUrl.push.apply(file);
      }
    }

    return filesUrl;
  }

  /**
   * @description Creates Sas Url, blobContainer and then saves the file
   * @param {File} file File to be saved
   * @param {string} extension Extension of the file
   * @returns {string} Sas Url from file saved
   */
  public async saveFile(file: File, extension: string): Promise<string> {
    const resultSAS = await this.trackingService.generateSAS(AppConstants.DOT_CHAR + extension.toLowerCase());
    const sasUrl = resultSAS[AppConstants.KEY_SASURL];
    const MIMEType = await this.blobProvider.getMIMEtype(extension.toLowerCase());
    const blob = new Blob([file], { type: MIMEType });
    await this.blobProvider.sendBlob(sasUrl, blob).catch(() => false);

    return resultSAS[AppConstants.KEY_BLOBNAME];
  }

  /**
   * @description set the new or update record in shipment records,
   * to follow the entire process of shipment changes
   * @param {string} shipment Id to identify the logs
   * @param {ShipmentLogs} body content for shipmentlogs
   */
  public async setShipmentLogs(shipmentId: string, body: ShipmentLogs): Promise<void> {
    return await this.http.put<void>(`${shipmentApiUrl}/shipment/${shipmentId}/logs`, body).toPromise();
  }

  /*
   * @description Updates stop status
   * @param {string} shipmentRequestOid Shipment request object ID
   * @param {object} body Params to update shipment
   */
  public async updateStopStatus(shipmentRequestOid: string, body: object): Promise<void> {
    return await this.http.put<void>
      (`${apiUrl}/shipmentRequest/${shipmentRequestOid}/updateStopStatus`, body).toPromise();
  }

  /**
   * @description set the new or update record in shipment records,
   * to follow the entire process of shipment changes
   * @param {string} shipperId The current shipperOId to find
   * @param {string} accountId as id
   * @param {string} orderId as id
   * @param {Date | string} startDate start date for range search
   * @param {Date | string} endDate end date for range search
   * @return {Promise<Array<Shipments>>} list of shipments
   */
  public async getShipmentsCosted(shipperId: string, accountId: string, orderId: string, startDate: Date | string, endDate: Date | string): Promise<Array<Shipments>> {
    let urlBase = `${shipmentApiUrl}/shipper/${shipperId}/shipmentsCosted`;
    if (startDate && endDate) {
      urlBase += `?startDate=${startDate}&endDate=${endDate}`;
    }
    if (accountId) {
      urlBase += `&accountId=${accountId}`;
    }
    if (orderId) {
      urlBase += `&orderId=${orderId}`;
    }
    return await this.http.get<Array<Shipments>>(urlBase).toPromise();
  }

  /**
   * @description Find the shipments by tenant and array of shipmentids
   * @param {string} shipperId The current shipperOId to find
   * @param {Array<string>} shippmentIds list of shipmentId
   * @return {Promise<ShipmentResponse>} list of shipments
   */
  public async getShipmentByShippmentIds(shipperId: string, shippmentIds: Array<string>): Promise<ShipmentResponse> {
    const url = shipmentApiUrl + '/tenantId/' + shipperId + '/shippmentIds?searchByShipmentId=true';
    return await this.http.post<ShipmentResponse>(url, shippmentIds).toPromise();
  }

  /**
   * @description Gets multiple shipments by shipments OID.
   * @param {string} tenantId - Current Tenat or Shipper Object ID.
   * @param {Array<string>} shipmentsOid - Shipments object identifiers to find.
   * @returns {Array<Shipments>} Shipments object found.
   */
  public async getShipmentsByOIds(tenantId: string, shipmentsOid: Array<string>): Promise<ShipmentsFound> {
    const url = `${shipmentApiUrl}/tenantId/${tenantId}/shippmentIds`;

    return await this.http.post<ShipmentsFound>(url, shipmentsOid).toPromise();
  }

  /**
   * @description Searchs shipments by shipment request folio (S20).
   * @param {string} tenantId - ObjectId of tenant.
   * @param {Array<string>} shipmentRequestsId - An array with shipment requests folios to search.
   * @returns {Promise<Array<Shipments>>} - Data of shipment found with shipment requests provided.
   */
  public async getShipmentsByShipmentRequestsFolios(tenantId: string, shipmentRequestsId: Array<string>): Promise<Array<Shipments>> {
    const url = `${shipmentApiUrl}/tenantId/${tenantId}/shipmentsRequests?shipmentsRequetsId=${shipmentRequestsId}`;

    return await this.http.get<Array<Shipments>>(url).toPromise();
  }

  /**
   * @description Makes a petition to change shipment status depending of current shipment status to desirable status.
   * @param {string} tenantOid - Oid of tenant.
   * @param {ParamsRequest} paramsRequest - Params for change status request.
   * @returns {Promise<ChangeShipmentRequestStatusResponse>} Response of request for change status.
   */
  public async changeShipmentStatus(tenantOid: string, paramsRequest: ParamsRequest): Promise<ChangeShipmentRequestStatusResponse> {
    let url = `${apiUrl}/shipper/${tenantOid}/shipment/${paramsRequest.shipmentFolio}/supportMovement?`
    + `from=${paramsRequest.currentStatus}&to=${paramsRequest.desirableStatus}`;

    if (paramsRequest.supportTicket) {
      url = `${url}&supportTicket=${paramsRequest.supportTicket}`;
    }

    return await this.http.put<ChangeShipmentRequestStatusResponse>(url, paramsRequest).toPromise();
  }

  /**
   * @description Shares shipment data to customer api.
   * @param {string} shipperOId - Current shipper _id.
   * @param {any} dataToShare - Shipment data to share.
   * @param {ShareShipmentDataConfig} shareShipmentDataConfig - Config to share data.
   * @param {string} shipmentOId - Current shipment _id.
   */
  public async shareShipmentData(shipperOId: string, dataToShare: any, shareShipmentDataConfig: any, shipmentOId: string): Promise<void> {
    const body = {
      dataToShare: dataToShare,
      shipperConfigShareShipmentData: shareShipmentDataConfig
    };
    const url = `${shipmentApiUrl}/shipper/${shipperOId}/shipment/${shipmentOId}/shareShipmentData`;

    await this.http.post<void>(url, body).toPromise();
  }

  /**
   * @description Update Shipment to add mobilityId.
   * @param {ShipmentUpdateMobilityId} shipmentsToUpdate - Relation of shipmentIds with mobilityIds to be updated.
   * @returns {<Array<Shipments>>} Shipments updated.
   */
  public async updateShipmentRequestId(shipmentsToUpdate: ShipmentUpdateMobilityId): Promise<Array<Shipments>> {
    return await this.http.put<Array<Shipments>>(`${shipmentApiUrl}/updateMobilityId`, shipmentsToUpdate).toPromise();
  }

  /**
   * @description Gets the shipments not load executed by tenant id.
   * @param {SearchShipmentLoadNotExecute} searchBody - The search criteria for the shipments.
   * @param {number} page - The page number.
   * @param {number} limit - The maximum number of items per page.
   * @returns {Promise<ShipmentLoadNotExecuteResponse>} Shipments updated.
   */
  public async getShipmentLoadNotExecuted(searchBody: SearchShipmentLoadNotExecute, page: number, limit: number): Promise<ShipmentLoadNotExecuteResponse> {
    const tenantId = searchBody.tenantId;
    const url = `${shipmentApiUrl}/tenantId/${tenantId}/loadNotExecuted?page=${page}&limit=${limit}&sortBy=deletedReason.deletedDate`;

    return await this.http.post<ShipmentLoadNotExecuteResponse>(url, searchBody).toPromise();
  }

  /**
   * @description Generates a freight charge for a shipment using the provided tenant ID and shipment data.
   * @param {string} tenantId - Current tenant id.
   * @param {ShipmentChargeBody} shipmentChargeBody - The shipment data for which to generate the freight charge.
   * @param {boolean} isUpdateOrCreate - Flag to specify if the body request has Shipment Cost Charge to create and update.
   * @returns {Promise<ShipmentLoadNotExecuteResponse>} - Shipments updated.
   */
  public async generateFreightCharge(tenantId: string, shipmentChargeBody: ShipmentChargeBody, isUpdateOrCreate?: boolean): Promise<ShipmentLoadNotExecuteResponse> {
    return await this.http.post<ShipmentLoadNotExecuteResponse>(`${shipmentApiUrl}/tenantId/${tenantId}/loadNotExecuted/cancelOpt?isUpdateOrCreate=${isUpdateOrCreate}`, shipmentChargeBody).toPromise();
  }

  /**
   * @description Generates a request to create and modified the shipment using the provided tenant ID and shipment data.
   * @param {string} tenantId - Current tenant id.
   * @param {ShipmentChargeBody} shipmentChargeBody - The shipment data for which to generate the freight charge.
   * @param {boolean} isUpdateOrCreate - Flag to specify if the body request has Shipment Cost Charge to create and update.
   * @returns {Promise<ShipmentLoadNotExecuteResponse>} - Shipments updated.
   */
  public async sendShipmentCharges(tenantId: string, shipmentChargeBody: ShipmentChargeBody, isUpdateOrCreate?: boolean): Promise<ShipmentLoadNotExecuteResponse> {
    return await this.http.post<ShipmentLoadNotExecuteResponse>(`${shipmentApiUrl}/tenantId/${tenantId}/shipmentCostCharges?isUpdateOrCreate=${isUpdateOrCreate}`, shipmentChargeBody).toPromise();
  }

  /**
   * @description Generates a request to modified the shipment using the provided tenant Id and shipment data.
   * @param {string} tenantId - Current tenant id.
   * @param {ShipmentChargeBody} shipmentChargeBody - The shipment data for which to update.
   * @returns {Promise<ShipmentLoadNotExecuteResponse>} - Shipments updated.
   */
  public async closeShipmentSaleCharges(tenantId: string, shipmentChargeBody: ShipmentChargeBody): Promise<ShipmentLoadNotExecuteResponse> {
    return await this.http.post<ShipmentLoadNotExecuteResponse>(
      `${shipmentApiUrl}/tenantId/${tenantId}/shipmentSaleChargeClose`, shipmentChargeBody).toPromise();
  }

  /**
   * @description Closes shipment charges for a given tenant and returns the response from the backend.
   * @param {string} tenantId - Current tenant id.
   * @param {ShipmentChargeBody} shipmentChargeBody - An array of objects representing the shipment charges to be closed.
   * @returns {Promise<ShipmentLoadNotExecuteResponse>} - Shipments updated.
   */
  public async closeShipmentCharges(tenantId: string, shipmentChargeBody: Array<ShipmentChargeBody>): Promise<ShipmentLoadNotExecuteResponse> {
    return await this.http.post<ShipmentLoadNotExecuteResponse>(`${shipmentApiUrl}/tenantId/${tenantId}/shipmentCostChargesClose`, shipmentChargeBody).toPromise();
  }

  /**
   * @description Generates a sale charge for a shipment using the provided tenant ID and shipment data.
   * @param {string} tenantId - Current tenant id.
   * @param {ShipmentChargeBody} shipmentSaleChargeBody - The shipment data for which to generate the sale charge.
   * @returns {Promise<ShipmentLoadNotExecuteResponse>} - Shipments updated.
   */
  public async generateSaleCharges(tenantId: string, shipmentSaleChargeBody: ShipmentChargeBody): Promise<ShipmentLoadNotExecuteResponse> {
    const url = `${shipmentApiUrl}/tenantId/${tenantId}/loadNotExecuted/cancelOpt`;

    return await this.http.post<ShipmentLoadNotExecuteResponse>(url, shipmentSaleChargeBody).toPromise();
  }

  /**
   * @description Gets all shipments with all the charges
   * @param {string} tenantId - Current tenant id.
   * @param {Array<string>} shipmentsIds - The shipments ids that are going to be consulted.
   * @returns {Promise<ShipmentSaleChargeLoadNotExecuteResponse>} - Shipments array with the charges.
   */
  public async getShipmentSaleCharges(tenantId: string, shipmentsIds: Array<string>): Promise<ShipmentSaleChargeLoadNotExecuteResponse> {
    const url = `${tmsFreightCostApiUrl}/shipmentSaleCharges/tenant/${tenantId}/shipmentIds`;

    return await this.http.post<ShipmentSaleChargeLoadNotExecuteResponse>(url, shipmentsIds).toPromise();
  }

  /**
   * @description Records warehouse access for multiple shipments (vehicle access control v2).
   * @param {RegisterWarehouseAccessParams} params - Params to execute the request.
   */
  public async registerWarehouseAccess(params: RegisterWarehouseAccessParams): Promise<void> {
    const url = `${shipmentApiUrl}/tenantId/${params.tenantOId}/warehouseAccess`;
    await this.http.post<ShipmentSaleChargeLoadNotExecuteResponse>(url, params).toPromise();
  }

  /**
   * @description Records warehouse departure for multiple shipments to set it in transit (for vehicle access control v2).
   * @param {RegisterWarehouseAccessParams} params - Params to execute the request.
   */
  public async registerWarehouseDeparture(params: RegisterWarehouseDepartureParams): Promise<void> {
    const url = `${apiUrl}/tenantId/${params.tenantOId}/shipmentRequests/warehouseDeparture`;
    await this.http.post<ShipmentSaleChargeLoadNotExecuteResponse>(url, params).toPromise();
  }

  /**
   * @description Updates load dates from single shipment (for vehicle access control v2).
   * @param {UpdateShipmentLoadDatesParams} params - Params to execute the request.
   */
  public async updateShipmentLoadDates(params: UpdateShipmentLoadDatesParams): Promise<void> {
    const url = `${shipmentApiUrl}/tenantId/${params.tenantOId}/shipment/${params.shipmentId}/loadDates`;
    await this.http.post<ShipmentSaleChargeLoadNotExecuteResponse>(url, params).toPromise();
  }
}

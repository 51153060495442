<div class="info-section">
  <div matTooltip="{{ iconTooltip }}">
    <mat-icon class="legend-info-icon">{{ iconName }}</mat-icon>
  </div>
  <div class="legend-section">
    <span [ngClass]="{'focus-text-with-opacity': shouldApplyOpacityToMainText}">
      {{ mainText }}
    </span> 
    <span *ngIf="mainFocusText" class="mat-header-cell">
      {{ mainFocusText }}.
    </span> 
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AppService } from '../../../app.service';
import { DIALOG_ASSOCIATE_GUIDES } from './dialog-associate-guides.properties';
import { DialogAssociateGuidesData, OrderGuideAssociationTable } from '../../../interfaces/dialog-associate-guides';
import { DialogStandardFocus } from '../../../enums/dialog-stantdard-focus';
import { OrderProvider } from '../../../providers/orders/order-provider.service';
import { OrdersApi } from '../../../interfaces';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-dialog-associate-guides',
  styleUrls: ['./dialog-associate-guides.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-associate-guides.component.html'
})

/**
 * Dialog to associate guide to shipment orders.
 */
export class DialogAssociateGuidesComponent implements OnInit {
  public dataSource: Array<OrderGuideAssociationTable>;
  public displayedColumns: Array<string>;
  public shipmentId: string;

  /**
   * @description Dialog associate guides component constructor.
   * @param {AppService} appService - App Service instance.
   * @param {DialogAssociateGuidesData} data - Provided info to use in dialog with orders and
   * shipment data.
   * @param {MatDialogRef<DialogAssociateGuidesComponent>} dialogRef - Dialog instance to close it.
   * @param {OrderProvider} orderProvider - Service to modify orders.
   * @param {ToastrAlertsService} toastService - Service to show toast.
   */
  constructor(
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: DialogAssociateGuidesData,
    public dialogRef: MatDialogRef<DialogAssociateGuidesComponent>,
    public orderProvider: OrderProvider,
    public toastService: ToastrAlertsService
  ) { }

  /**
   * @description Validates if some order has not courier guide to disable
   * associate guides button.
   * @returns {boolean} True if some order has not guide.
   */
  public isAssociateButtonDisable(): boolean {
    for (const order of this.dataSource) {
      if (this.isCourierGuideInputInvalid(order.courierGuide)) {
        return true;
      }
    }

    return false;
  }

  /**
   * @description Validates if provided input is invalid.
   * @param {string} courierGuide - Courier to validate.
   * @returns {boolean} True if is invalid courier guide.
   */
  public isCourierGuideInputInvalid(courierGuide: string): boolean {
    return !courierGuide || courierGuide.length < DIALOG_ASSOCIATE_GUIDES.courierGuidesMinLength;
  }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public ngOnInit(): void {
    this.initAssociateGuidesTable();
  }

  /**
   * @description Send guides for associate to orders.
   */
  public async onAssociateGuides(): Promise<void> {
    try {
      this.appService.loaderStatus(true);
      const shipperOId = this.appService.getShipperOid();
      const username = this.appService.getUserInfoFromStorage().username;
      await this.orderProvider.associateOrderGuides(this.dataSource, shipperOId, username);
      this.dialogRef.close(DialogStandardFocus.CONFIRM);
    } catch (error) {
      this.toastService.errorAlert(this.data.guidesAssociationErrorMessage);
    } finally {
      this.appService.loaderStatus(false);
    }
  }

  /**
   * @description Closes dialog when user clics on cancel button.
   */
  public onCancel(): void {
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }

  /**
   * @description Init necessary properties for built table to associate guides
   * to orders.
   */
  private initAssociateGuidesTable(): void {
    this.displayedColumns = DIALOG_ASSOCIATE_GUIDES.displayedColumns;
    this.dataSource = [];
    this.shipmentId = this.data.shipment.shipmentId;
    this.dataSource = this.data.orders.map((order: OrdersApi) => {
      return {
        _id: order._id,
        courierGuide: order.courierGuides[0]?.guide,
        orderIdentifier: order.identifier
      };
    });
  }
}

import { Injectable } from '@angular/core';

import { Subscription } from 'rxjs';

import { AppConstants } from '../../constants/app-constants.constants';
import { DateToolsService } from '../utils/date-tools.service';
import { LanguageChangeEventService } from '../translate/language-change-event.service';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageTranslateService } from '../translate/language-translate.service';
import { LOAD_PLAN_PROPERTIES } from '../../components/dialog/dialog-load-plan/dialog-load-plan-constants';
import { LoadPlanLabel } from '../../interfaces/load-plan';
import { OrderByStop, OrdersApi, ProductsDetail, ShipmentPropertiesLabels, ShipmentRowExtended } from '../../../app/interfaces';
import { ShipmenDataTranslateService } from '../translate/shipment-data-translate.service';
import { ShipmentTripTypesLabels } from '../../interfaces/language.interface';
import { ShipmentType } from '../../enums';

/**
 * @description Service with methods used in load plan components to format info displayed from load plan content.
 */
@Injectable()
export class LoadPlanService {
  public labels: LoadPlanLabel;
  public languageSubscription: Subscription;
  public shipmentPropertiesLabels: ShipmentPropertiesLabels;
  public shipmentTripTypesLabels: ShipmentTripTypesLabels;

  /**
   * @description Contains intances from services or providers used in this component.
   * @param {DateToolsService} dateToolsService - Service with methods related to dates formats.
   * @param {LanguageChangeEventService} languageChangeEventService - Service to detection of changes in language active for user.
   * @param {LanguageTranslateService} languageTranslateService - Service to retrieve differents all component labels in active language.
   * @param {ShipmenDataTranslateService} shipmenDataTranslateService - Service to retrieve some shipment labels in current language.
   */
  constructor(
    private dateToolsService: DateToolsService,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private shipmenDataTranslateService: ShipmenDataTranslateService
  ) {
    this.setLanguage();
    this.subscribeLanguageChangeEvents();
    this.getLoadPlanLabels();
  }

  /**
   * @description Handles big quantities to display it in short version.
   * @param {number} value - Number to check and process.
   * @returns {string} Value in short version.
   */
  public handleBigQuantities(value: number): string {
    if (value && value <= LOAD_PLAN_PROPERTIES.maxValueForThousands) {
      return ((value / LOAD_PLAN_PROPERTIES.thousandDivider).toFixed(LOAD_PLAN_PROPERTIES.maxDecimals)) +
      LOAD_PLAN_PROPERTIES.symbolForThousands;
    } else if ((value >= LOAD_PLAN_PROPERTIES.millionDivider) &&
      (value <= LOAD_PLAN_PROPERTIES.maxValueForMillions)) {
      return ((value / LOAD_PLAN_PROPERTIES.millionDivider).toFixed(LOAD_PLAN_PROPERTIES.maxDecimals)) +
      LOAD_PLAN_PROPERTIES.symbolForMillions;
    }
  }

  /**
   * @description Checks length from provided string to check if hass too many characters for load plan orders rows section.
   * @param {string} value - String to check.
   * @returns {boolean} True if provided string has more than 20 characters. Otherwise false.
   */
  public hasPropertyTooManyCharacters(value: string): boolean {
    return value?.length > LOAD_PLAN_PROPERTIES.maxCharactersForOrderRows;
  }

  /**
   * @description Checks if numeric value provided is a greater value for total merchandise data in load plan section.
   * @param {number} value - Numeric value to check.
   * @returns {boolean} - True if numeric value is greater or equals to hundred thousand. Otherwise false.
   */
  public isAGreatNumericValue(value: number): boolean {
    if (value && value >= LOAD_PLAN_PROPERTIES.maxMerchandiseValueAllowed) {
      return true;
    }

    return false;
  }

  /**
   * @description Retrieves a provided date to format it.
   * @param { Date } date - Date from order to format.
   * @returns { string } - Date formated as string.
   */
  public setDate(date: Date | string): string {
    return this.dateToolsService.formatDate(date as string, AppConstants.SLASH, true, AppConstants.DASH);
  }

  /**
   * @description Formats order delivery date.
   * @param {OrdersApi} order - Order data to format delivery date assigned.
   * @returns {string} - Date formated as string.
   */
  public setDeliveryDate(order: OrdersApi): string {
    return this.dateToolsService.getOrderDeliveryDate(order.deliveryDate, order.appointmentHour, this.labels.withoutAppointmentHour);
  }

  /**
   * @description Sets shipment load type selected in current language.
   * @param {string} loadType - Load type assigned to translate label.
   * @returns {string} - Label in active language for load type.
   */
  public setShipmentLoadType(loadType: string): string {
    return this.shipmenDataTranslateService.setLoadTypeLabel(loadType, this.shipmentPropertiesLabels);
  }

  /**
   * @description Sets shipment service type selected in current language.
   * @param {string} serviceType - Service type assigned to translate label.
   * @returns {string} - Label in active language for service type.
   */
  public setShipmentServiceType(serviceType: string): string {
    return this.shipmenDataTranslateService.setServiceTypeLabel(serviceType, this.shipmentPropertiesLabels);
  }

  /**
   * @description Checks shipment trip type to retrieve and display label from trip type in current language.
   * @param {string} tripType - Trip type from shipment.
   * @returns {string} Label from trip type to display in current language.
   */
  public setShipmentTripTypeLabel(tripType: string): string {
    switch (tripType) {
      case ShipmentType.Collection:
        return this.shipmentTripTypesLabels?.collection;
      case ShipmentType.Consolidated:
        return this.shipmentTripTypesLabels?.consolidated;
      case ShipmentType.Courier:
        return this.shipmentTripTypesLabels?.courier;
      case ShipmentType.CustomerPickup:
        return this.shipmentTripTypesLabels?.customerPickUp;
      case ShipmentType.NonStop:
        return this.shipmentTripTypesLabels?.direct;
      case ShipmentType.MultiStop:
        return this.shipmentTripTypesLabels?.distribution;
      case ShipmentType.Portage:
        return this.shipmentTripTypesLabels?.portage;
      case ShipmentType.PortageMultiStop:
        return this.shipmentTripTypesLabels?.portageWithDistribution;
      default: break;
    }
  }

  /**
   * @description Builds label to display in stop section from load plan plan with destination address setted.
   * @param {OrderByStop} stop - Stop data to check.
   * @returns {string} Label with stop info built with destination info.
   */
  public setStopData(stop: OrderByStop): string {
    if (!this.labels?.stop) {
      return;
    }
    let stopName = this.labels?.stop;
    stopName = stopName.replace(AppConstants.NUMBER_SYMBOL, stop.stop.toString());

    return stop.isPortage ? `${stopName}${stop.plannedWarehouse.name}` :
      `${stopName}${stop.name}, ${stop.address}, ${stop.settlement}, ${stop.postalCode},  ${stop.state}`;
  }

  /**
   * @description Checks if shipment requirements should be hided in load plan format.
   * @param {ShipmentRowExtended} shipment - Data of shipment to check assigned requirements.
   * @returns {boolean} True if shipment don't have specials or aditionals requirements assigned. Otherwise false.
   */
  public shouldHideRequirementsSection(shipment: ShipmentRowExtended): boolean {
    return !shipment?.specialRequirements?.requirements?.length && !shipment?.specialRequirements?.otherRequirements;
  }

  /**
   * @description Iterates over all products from provided order to check if price property exists to get the total value from order.
   * @param {Array<ProductsDetail>} products - All products data from order to process.
   * @returns {number} - Total value from order obtained with all products data. (total * price).
   */
  public summarizeTotalFromOrderProducts(products: Array<ProductsDetail>): number {
    let total = 0;

    for (const product of products) {
      if (product.price) {
        total = total + (product.price * product.total);
      }
    }

    return total;
  }

  /**
   * @description Checks the language setted and gets the labels of component and orders in the language setted in scf.
   */
  private async getLoadPlanLabels(): Promise<void> {
    try {
      this.labels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.LOAD_PLAN_LABELS);
      this.shipmentPropertiesLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.SHIPMENT_PROPERTIES_LABELS);
      this.shipmentTripTypesLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.SHIPMENT_TRIP_TYPES);
    } catch (error) { }
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Optional language key string, default is spanish 'es'.
   */
  private setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Subscribes to the language service to detect changes in the languaje selected and refresh component's labels.
   */
  private subscribeLanguageChangeEvents(): void {
    this.languageSubscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLoadPlanLabels();
      },
      (error) => { }
    );
  }
}

export enum DialogLocationLabels {
  noLocationsFound = 'No se encontraron ubicaciones, verifique su información',
  noLocationsFoundByShipper = 'No se encontraron ubicaciones de este embarcador, verifique su información'
}

export enum DialogLocationTags {
  address = 'Dirección',
  canton = 'Cantón',
  dialogResume = 'Selecciona la ubicación',
  identifier = 'Identificador',
  identifierIs = 'El identificador es ',
  manual = 'Manual',
  name = 'Nombre',
  nameIs = 'El nombre es ',
  parish = 'Parroquia',
  postalCode = 'Código postal',
  postalCodeAbbreviation = 'C.P.',
  postalCodeIs = 'El código postal es ',
  province = 'Provincia',
  required = 'requerid',
  state = 'Estado',
  suffixO = 'o'
}

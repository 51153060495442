import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { DialogLoadPlan, LoadPlanLabel } from '../../../interfaces/load-plan';
import {
  HandleExceededOrderInfoParams,
  HandleExceededStopInfoParams,
  LoadPlanContentByOrder,
  LoadPlanContentByStopAndOrder,
  OrderByStop,
  OrdersApi,
  ProductsDetail,
  ShipperConfiguration
} from '../../../interfaces';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { LOAD_PLAN_PROPERTIES } from './dialog-load-plan-constants';
import { LoadPlanService } from '../../../services/load-plan/load-plan.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-dialog-load-plan',
  styleUrls: ['./dialog-load-plan.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-load-plan.component.html',
})

/**
 * @description Main commponent dialog from load plan that contains call to components for load plan in landscape or portrait version.
 */
export class DialogLoadPlanComponent implements OnInit {
  public boxesIcon: string;
  public isLandscapeVersion: boolean;
  public labels: LoadPlanLabel;
  public languageSubscription: Subscription;
  public ordersIcon: string;
  public ordersPerPage: Array<LoadPlanContentByStopAndOrder>;
  public ordersProductsPerPage: Array<LoadPlanContentByOrder>;
  public palletsIcon: string;
  public piecesIcon: string;
  public shipmentAccounts: Array<string>;
  public shipmentOrders: Array<OrdersApi>;
  public shipperConfiguration: ShipperConfiguration;
  public shipperLogo: string;
  public stopsIcon: string;

  /**
   * @description Constains intances from services or providers used in this component.
   * @param {DialogLoadPlan} data - Injectable data to use in this component.
   * @param {MatDialogRef<DialogLoadPlanComponent>} dialogRef - Instance of this component to be used as dialog.
   * @param {LanguageChangeEventService} languageChangeEventService - Service to detection of changes in language active for user.
   * @param {LanguageTranslateService} languageTranslateService - Service to retrieve differents all component labels in active language.
   * @param {LoadPlanService} loadPlanService - Service with main methods related with display info from load plan.
   * @param {UtilsService} utilsService - Service with utils methods for general use.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogLoadPlan,
    private dialogRef: MatDialogRef<DialogLoadPlanComponent>,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private loadPlanService: LoadPlanService,
    private utilsService: UtilsService
  ) {
    this.setLanguage();
    this.isLandscapeVersion = false;
    this.ordersProductsPerPage = [];
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvents();
    await this.getLoadPlanLabels();
    this.buildLoadPlanContent();
    this.initDialog();
  }

  /**
   * @description Method called when user changes load plan version displayed, to update the info and design of load plan displayed.
   * @param {boolean} isLandscapeVersion - Flag to know where the user button had clicked to change design of load plan.
   */
  public onLoadPlanVersion(isLandscapeVersion: boolean): void {
    this.isLandscapeVersion = isLandscapeVersion;
    this.buildLoadPlanContent();

    if (isLandscapeVersion) {
      this.dialogRef.updateSize(LOAD_PLAN_PROPERTIES.loadPlanLandscapeModalwidth, LOAD_PLAN_PROPERTIES.loadPlanLandscapeModalHeight);
    } else {
      this.dialogRef.updateSize(LOAD_PLAN_PROPERTIES.loadPlanPortraitModalWidth, LOAD_PLAN_PROPERTIES.loadPlanLandscapeModalHeight);
    }
  }

  /**
   * @description Check if exists orders with products to get the total value with products data.
   * @param {Array<OrdersApi>} orders - All orders from current shipment to process info.
   */
  private buildAndSetTotalPrice(orders: Array<OrdersApi>): void {
    const ordersWithProducts: Array<OrdersApi> = orders.filter((order: OrdersApi) => {
      return order.products?.length;
    });

    if (ordersWithProducts.length) {
      this.setTotalPriceFromShipment(ordersWithProducts);
    } else {
      this.data.totalPrice = LOAD_PLAN_PROPERTIES.shipmentWithoutValue;
    }
  }

  /**
   * @description Builds load plan content from orders and products to display (if applies).
   */
  private buildLoadPlanContent(): void {
    this.buildOrdersContent();
    this.buildAndSetTotalPrice(this.data.fullOrdersData);

    if (this.shouldDisplayProductsSection()) {
      this.buildProductsContent();
    }
  }

  /**
   * @description Builds Shipment orders content by pages for load plan.
   */
  private buildOrdersContent(): void {
    const allPages = [];
    let isFirstPage = true;
    let pageNumber = 1;
    const availableShipmentStops: Array<OrderByStop> = structuredClone(this.data.shipment.ordersByStop);

    do {
      let availableRowsInCurrentPage = this.setMaxRows(this.isLandscapeVersion, isFirstPage);
      let isOverflowData = false;
      const pageContent: LoadPlanContentByStopAndOrder = {
        data: [],
        page: pageNumber
      };

      for (let i = 0; !isOverflowData && availableShipmentStops.length; i++) {
        const rowsRequiredForStop = availableShipmentStops[0].orders.length + 1;

        if (availableRowsInCurrentPage >= rowsRequiredForStop) {
          pageContent.data.push(availableShipmentStops[0]);
          availableShipmentStops.splice(0, 1);
          availableRowsInCurrentPage = availableRowsInCurrentPage - rowsRequiredForStop;
        } else {
          const params: HandleExceededStopInfoParams = {
            availableRowsInCurrentPage: availableRowsInCurrentPage,
            availableShipmentStops: availableShipmentStops,
            pageContent: pageContent,
            stop: availableShipmentStops[0]
          };
          this.handleExceededStopInfo(params);
          isOverflowData = true;
        }
      }

      if (pageContent.data.length) {
        allPages.push(pageContent);
      }
      pageNumber++;
      isFirstPage = false;
    } while (availableShipmentStops.length);
    this.ordersPerPage = allPages;
  }

  /**
   * @description Builds orders products content by pages for load plan.
   */
  private buildProductsContent(): void {
    const allPages = [];
    const availableOrders: Array<OrdersApi> = structuredClone(this.data.fullOrdersData);
    let isBuildingFirstProductsData = true;
    let isFirstPage = this.ordersPerPage?.length > 1 ? false : true;

    do {
      let availableRowsInCurrentPage = this.setMaxRows(this.isLandscapeVersion, isFirstPage);
      let isOverflowData = false;
      const pageContent: LoadPlanContentByOrder = {
        data: [],
        shouldApplyBreakPage: true
      };
      availableRowsInCurrentPage = this.checkAndResetAvailableRowsForFirstProductsInfo(isBuildingFirstProductsData,
        availableRowsInCurrentPage);
      isBuildingFirstProductsData = false;

      for (let i = 0; !isOverflowData && availableOrders.length; i++) {
        const rowsRequiredForOrder = availableOrders[0].products.length + 1;

        if (rowsRequiredForOrder === 1) {
          availableOrders.splice(0, 1);
          continue;
        }

        if (availableRowsInCurrentPage >= rowsRequiredForOrder) {
          pageContent.data.push(availableOrders[0]);
          availableOrders.splice(0, 1);
          availableRowsInCurrentPage = availableRowsInCurrentPage - rowsRequiredForOrder;
        } else {
          const params: HandleExceededOrderInfoParams = {
            availableOrders: availableOrders,
            availableRowsInCurrentPage: availableRowsInCurrentPage,
            order: availableOrders[0],
            pageContent: pageContent
          };
          this.handleExceededOrderInfo(params);
          isOverflowData = true;
        }
      }

      if (!availableOrders.length) {
        pageContent.shouldApplyBreakPage = false;
      }
      if (pageContent.data.length) {
        allPages.push(pageContent);
      }
      isFirstPage = false;
    } while (availableOrders.length);
    this.ordersProductsPerPage = allPages;
  }

  /**
   * @description Checks if are first register of products (to display after orders section) to re-set available rows in last page
   * used by orders to only add data can be displayed in same page.
   * @param {boolean} areFirstProductsData - Flag to know if are first register of products are being processed.
   * @param {number} availableRowsInCurrentPage - Quantity available in current page (without check current rows used by orders section).
   * @returns {number} Available rows re-setted in current page.
   */
  private checkAndResetAvailableRowsForFirstProductsInfo(areFirstProductsData: boolean, availableRowsInCurrentPage: number): number {
    if (areFirstProductsData) {
      let ordersQuantity = 0;
      const lastOrderData = this.ordersPerPage[this.ordersPerPage.length - 1];
      lastOrderData.data.forEach((stop: OrderByStop) => {
        ordersQuantity = ordersQuantity + stop.orders.length;
      });

      return availableRowsInCurrentPage - lastOrderData.data.length - ordersQuantity - 1;
    }

    return availableRowsInCurrentPage;
  }

  /**
   * @description Checks characters from aditional requirements and special requirements from shipment
   * to decrease from available rows in page for space occupied by that info.
   * @returns {number} Number of rows is occupied by quantity info from shipment requirements.
   */
  private checkAndSetOccupiedRowsByRequirementsData(): number {
    const specials = this.data.shipment?.specialRequirements.requirements;
    const aditionals = this.data.shipment?.specialRequirements.otherRequirements;

    if (specials.length || aditionals) {
      if (specials.length >= LOAD_PLAN_PROPERTIES.minsSpecialRequirementsForSixRows ||
        aditionals.length >= LOAD_PLAN_PROPERTIES.minsAditionalCharactersForSixRows) {
        return LOAD_PLAN_PROPERTIES.sixRowsFull;
      } else if (specials.length >= LOAD_PLAN_PROPERTIES.minsSpecialRequirementsForFiveRows ||
        aditionals.length >= LOAD_PLAN_PROPERTIES.minsAditionalCharactersForFiveRows) {
        return LOAD_PLAN_PROPERTIES.fiveRowsFull;
      } else if (specials.length >= LOAD_PLAN_PROPERTIES.minsSpecialRequirementsForFourRows ||
        aditionals.length >= LOAD_PLAN_PROPERTIES.minsAditionalCharactersForFourRows) {
        return LOAD_PLAN_PROPERTIES.fourRowsFull;
      } else if (specials.length >= LOAD_PLAN_PROPERTIES.minsSpecialRequirementsForThreeRows ||
        aditionals.length >= LOAD_PLAN_PROPERTIES.minsAditionalCharactersForThreeRows) {
        return LOAD_PLAN_PROPERTIES.threeRowsFull;
      } else if (specials.length >= 1 || aditionals.length >= 1) {
        return LOAD_PLAN_PROPERTIES.twoRowsFull;
      }
    }

    return 1;
  }

  /**
   * @description Checks the language setted and gets the labels of component and orders in the language setted in scf.
   */
  private async getLoadPlanLabels(): Promise<void> {
    try {
      this.labels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.LOAD_PLAN_LABELS);
    } catch (error) { }
  }

  /**
   * @description Divides order products with available products when order data exceeds the maximum of available rows in current page.
   * @param {HandleExceededOrderInfoParams} params - Params that contains neccesary info to process order data,
   * like available rows in page, all orders available, current order is being processed and current page content.
   */
  private handleExceededOrderInfo(params: HandleExceededOrderInfoParams): void {
    const orderAux = structuredClone(params.order);
    orderAux.products = [];

    for (let i = 0; i < params.availableRowsInCurrentPage; i++) {
      orderAux.products.push(params.availableOrders[0].products[0]);
      params.availableOrders[0].products.splice(0, 1);

      if (!params.availableOrders[0].products?.length) {
        params.availableOrders.splice(0, 1);
      }
    }
    if (orderAux.products.length) {
      params.pageContent.data.push(orderAux);
    }
  }

  /**
   * @description Divides orders from stop with available orders when stop data exceeds the maximum of available rows in current page.
   * @param {HandleExceededStopInfoParams} params - Params that contains neccesary info to process stop data,
   * like available rows in page, all stops available, current stop is being processed and current page content.
   */
  private handleExceededStopInfo(params: HandleExceededStopInfoParams): void {
    const stopAux = structuredClone(params.availableShipmentStops[0]);
    stopAux.orders = [];

    for (let i = 0; i < params.availableRowsInCurrentPage; i++) {
      stopAux.orders.push(params.availableShipmentStops[0].orders[0]);
      params.availableShipmentStops[0].orders.splice(0, 1);

      if (!params.availableShipmentStops[0].orders?.length) {
        params.availableShipmentStops.splice(0, 1);
      }
    }
    if (stopAux.orders.length) {
      params.pageContent.data.push(stopAux);
    }
  }

  /**
   * @description Inits main variables for load plan dialog.
   */
  private async initDialog(): Promise<void> {
    this.ordersIcon = LOAD_PLAN_PROPERTIES.ordersIcon;
    this.piecesIcon = LOAD_PLAN_PROPERTIES.piecesIcon;
    this.boxesIcon = LOAD_PLAN_PROPERTIES.boxesIcon;
    this.palletsIcon = LOAD_PLAN_PROPERTIES.palletsIcon;
    this.stopsIcon = LOAD_PLAN_PROPERTIES.stopsIcon;
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Optional language key string, default is spanish 'es'.
   */
  private setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Checks active format for load plan and if is the first page or not to determinate a max rows available for page.
   * @param {boolean} isLandscapeVersion - Flag to know if active version of load plan is landscape or not.
   * @param {boolean} isFirstPage - Flag to know if page to determitae max rows is the first page or not.
   * @returns {number} Number of max rows available for current page.
   */
  private setMaxRows(isLandscapeVersion: boolean, isFirstPage: boolean): number {
    const internalReferenceRows = this.data.shipment.internalReference && isFirstPage ?
      LOAD_PLAN_PROPERTIES.rowsRequiredForInternalReferenceField : 0;
    const occupiedRowsByRequirements = this.checkAndSetOccupiedRowsByRequirementsData();

    if (isFirstPage) {
      if (isLandscapeVersion) {
        return LOAD_PLAN_PROPERTIES.availableRowsForFirstPageInLandscapeVersion - internalReferenceRows;
      } else {
        const extraRowsByRequirementsSection = this.loadPlanService.shouldHideRequirementsSection(this.data.shipment) ?
          LOAD_PLAN_PROPERTIES.rowsOccupiedByRequirementsSection : 0;

        return LOAD_PLAN_PROPERTIES.availableRowsForFirstPageInPortraitVersion - internalReferenceRows - occupiedRowsByRequirements +
        extraRowsByRequirementsSection;
      }
    } else {
      if (isLandscapeVersion) {
        return LOAD_PLAN_PROPERTIES.availableRowsForPagesInLandscapeVersion - internalReferenceRows;
      } else {
        return LOAD_PLAN_PROPERTIES.availableRowsForPagesInPortraitVersion - internalReferenceRows;
      }
    }
  }

  /**
   * @description Iterates over all orders with products data to summarize total.
   * @param {Array<OrdersApi>} orders - Orders from current shipment with products data.
   */
  private setTotalPriceFromShipment(orders: Array<OrdersApi>): void {
    let total = 0;

    for (const order of orders) {
      total = total + this.summarizeTotalFromOrderProducts(order.products);
    }
    this.data.totalPrice = this.utilsService.formatNumberWithDecimals(total,
      LOAD_PLAN_PROPERTIES.maxDecimals, LOAD_PLAN_PROPERTIES.maxDecimals);
  }

  /**
   * @description Determinates if "products" section should be displayed in load plan, checking shipper config and
   * if order's shipment have products associated.
   * @returns {boolean} - True if exists at least one order with products info and if shipper have the config to display
   * this section in load plan. Otherwise false.
   */
  private shouldDisplayProductsSection(): boolean {
    const hasOrdersWithProducts = this.data.fullOrdersData.some((order: OrdersApi) => {
      return order.products.length;
    });

    return this.data.shouldDisplayProductsDetails && hasOrdersWithProducts;
  }

  /**
   * @description Subscribes to the language service to detect changes in the languaje selected and refresh component's labels.
   */
  private subscribeLanguageChangeEvents(): void {
    this.languageSubscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLoadPlanLabels();
      },
      (error) => { }
    );
  }

  /**
   * @description Iterates over all products from provided order to check if price property exists to get the total value from order.
   * @param {Array<ProductsDetail>} products - All products data from order to process.
   * @returns {number} Total value from order obtained with all products data. (total * price).
   */
  private summarizeTotalFromOrderProducts(products: Array<ProductsDetail>): number {
    let total = 0;

    for (const product of products) {
      if (product.price) {
        total = total + ((Number(product.price.toFixed(LOAD_PLAN_PROPERTIES.maxDecimals))) *
          Number(product.total.toFixed(LOAD_PLAN_PROPERTIES.maxDecimals)));
      }
    }

    return total;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccountInfo } from './../../interfaces/account';
import { AppConstants } from '../../constants/app-constants.constants';
import { AppService } from './../../app.service';
import {
        ApportionmentApiResponse,
        ApportionmentUpdateBody,
        CedisAccess,
        ConfigurationChangesLog,
        DriverConfig,
        FieldsSettings,
        ShipmentUndeliveredOrdersStatus,
        TenderingConfig,
        Withholdings
      } from './../../interfaces/configuration';
import { CostingConfigurationBody, CustodyShipperSettings, DocumentsSettings, ParcelCompanySettings, ShipperConfiguration } from './../../interfaces/ShipperConfiguration';
import { TemperaturesFormBody } from './../../interfaces/temperature-body';
import { environment } from '../../../environments/environment';

const BLOB_STORAGE_URL = environment.baseStorageUrl + environment.mainContainer;
const LOGOIMAGE = '../../../assets/logo.png';
const coreApiURL = environment.apiUrl;
const freightCostApiUrl = environment.freightRateApiUrl;

@Injectable()
export class ConfigurationProvider {
  constructor(
    private http: HttpClient,
    private appService: AppService
  ) { }

  public async getCostApiSettings(tenantId: string): Promise<Object> {
    return await this.http.get<Object>(`${freightCostApiUrl}/rateSetting/${tenantId}`).toPromise();
  }

  public async createCostApiSettings(tenantId: string, body: Object): Promise<Object> {
    return await this.http.post<Object>(`${freightCostApiUrl}/rateSetting/${tenantId}`, body).toPromise();
  }

  public async getChargesTypesByTenant(tenantId: string): Promise<Object> {
    return await this.http.get<Object>(`${freightCostApiUrl}/charge/tenant/${tenantId}`).toPromise();
  }

  public async createCharges(tenantId: string, body: Object): Promise<Object> {
    return await this.http.post<Object>(`${freightCostApiUrl}/charge/tenant/${tenantId}`, body).toPromise();
  }

    /**
   * @description Creates the shipper withholdings configuration
   * @param {string} shipperOid - Param to link withholdings configuration with Shipper
   * @param {Withholdings} body - Withholdings Configuration Attributes
   * @returns {Promise<Object>} Withholdings configuration created or updated
   */
  public async createWithholdings(body: Withholdings, shipperOId?: string): Promise<Object> {
    let shipperOid = '';
    if (!shipperOId) {
      shipperOid = this.appService.getShipperOid();
    } else {
      shipperOid = shipperOId;
    }
    return await this.http.put<Object>(`${coreApiURL}/shipper/withholdings/${shipperOid}`, body).toPromise();
  }

  /**
   * @description Gets the shipper config
   * @param {string} shipperOId ShipperOId
   * @returns {Promise<ShipperConfiguration>} Found shipper config
   */
  public async getShipperConfig(shipperOId?: string): Promise<ShipperConfiguration> {
    let shipperOid = '';
    if (!shipperOId) {
      shipperOid = this.appService.getShipperOid();
    } else {
      shipperOid = shipperOId;
    }
    return this.http.get<ShipperConfiguration>(`${coreApiURL}/config/${shipperOid}`).toPromise();
  }

  /**
   * @description Sets the new field to know if the 'Not delivered' orders must be shipped
   * @param {string} shipperOId ShipperOId
   * @param {ShipmentUndeliveredOrdersStatus} shipmentUndeliveredOrdersStatus object with configuration to set shipper
   * @returns {Promise<boolean>} Updated status value
   */
   public async setShipUndeliveredOrdersStatus(
    shipperOId: string,
    shipmentUndeliveredOrdersStatus: ShipmentUndeliveredOrdersStatus
  ): Promise<boolean> {
    return this.http.put<boolean>(
      `${coreApiURL}/shipper/${shipperOId}/shipNotDeliveredOrders`,
      shipmentUndeliveredOrdersStatus
    ).toPromise();
  }

  /**
   * @description Saves configuration logs to the given shipper
   * @param {Array<ConfigurationChangesLog>} logsToSave Logs to be saved on db
   * @param {string} shipperOid Identifier of the shipper
   * @returns {Promise<boolean>} True if the logs where saved correctly
   */
  public async saveConfigurationLogs(logsToSave: Array<ConfigurationChangesLog>, shipperOid: string): Promise<boolean> {
    return this.http.put<boolean>(`${coreApiURL}/shipper/${shipperOid}/shipperConfigurationLogs`, logsToSave).toPromise();
  }

  /**
   * @description Sets the new shipper's logo
   * @param {string} logoUrl Logo name saved in azure container
   * @param {string} shipperOId ShipperOId
   * @returns {Promise<string>} Updated logo name in azure
   */
  public async setShipperLogo(logoUrl: string, shipperOId?: string): Promise<string> {
    let shipperOid = '';
    if (!shipperOId) {
      shipperOid = this.appService.getShipperOid();
    } else {
      shipperOid = shipperOId;
    }
    return this.http.put<string>(`${coreApiURL}/shipper/${shipperOid}/logo`, { logoUrl }).toPromise();
  }

  /**
   * @description Gets the current shipper logo saved
   * @returns {Promise<string>} Logo Url pointing assets or azure's blob storage
   */
  public async getShipperLogo(): Promise<string> {
    const shipperConfig = await this.getShipperConfig();
    let logoUrl = AppConstants.SYSTEM_DEFAULT_LOGO;

    if (shipperConfig && shipperConfig.logoUrl
      && shipperConfig.logoUrl !== '') {
      logoUrl = BLOB_STORAGE_URL + shipperConfig.logoUrl;
    }
    return logoUrl;
  }

  /**
   * @description Gets shipper's current apportionment settings
   * @returns Apportionment settings
   */
  public async getApportionmentSettings(shipperId: string): Promise<Array<ApportionmentApiResponse>> {
    return this.http.get<Array<ApportionmentApiResponse>>(`${freightCostApiUrl}/apportionment/shipperId/${shipperId}`).toPromise();
  }

  /**
   * @description Create shippers apportionment settings
   * @returns Apportionment settings
   */
  public async postApportionmentSettings(shipperId: string, body: ApportionmentUpdateBody): Promise<Array<ApportionmentApiResponse>> {
    return this.http.post<Array<ApportionmentApiResponse>>(`${freightCostApiUrl}/apportionment/shipperId/${shipperId}`, body)
    .toPromise();
  }

  /**
   * @description Update a shippers apportionment settings
   * @returns Apportionment settings
   */
  public async putApportionmentSettings(shipperId: string, body: ApportionmentUpdateBody): Promise<Array<ApportionmentApiResponse>> {
    return this.http.put<Array<ApportionmentApiResponse>>(`${freightCostApiUrl}/apportionment/shipperId/${shipperId}`, body)
    .toPromise();
  }

  /**
   * @description Post temperature
   * @param {string} shipperOid - Param to link temperatures with Shipper
   * @param {TemperaturesFormBody} temperatureBody - Temperature Attributes
   * @returns {Promise<TemperaturesFormBody>} Temperature created or updated
   */
  public async postTemperatureSettings(shipperOid: string, temperatureBody: TemperaturesFormBody): Promise<TemperaturesFormBody> {
    return await this.http.put<TemperaturesFormBody>(`${coreApiURL}/shipper/${shipperOid}/temperatures`, temperatureBody).toPromise();
  }

  /**
   * @description Post temperature
   * @param {string} shipperOid - Param to link temperatures with Shipper
   * @returns {Promise<TemperaturesFormBody>} Shipper temperatures
   */
  public async getTemperatureSettings(shipperOid: string): Promise<TemperaturesFormBody> {
    return await this.http.get<TemperaturesFormBody>(`${coreApiURL}/shipper/${shipperOid}/temperatures`).toPromise();
  }

  /**
   * @description Update the Tolerance Time in Cedis Access configuration
   * @param {CedisAccess} cedisAccessBody - Cedis access with shipper attributes
   * @param {string} shipperOId - Param to link cedis access with shipper
   * @returns {Promise<CedisAccess>} CedisAccess configuration created or updated
   */
  public async putCedisAccessSetting(shipperOid: string, cedisAccessBody: CedisAccess): Promise<CedisAccess> {
    return await this.http.put<CedisAccess>(`${coreApiURL}/shipper/${shipperOid}/cedisAccess`, cedisAccessBody).toPromise();
  }

  /**
   * @description saves tendering configurations
   * @param {string} shipperOid Id from shipper
   * @param {TenderingConfig} tenderingConfigBody object with the tendering config to be saved
   * @returns {Promise<TenderingConfig>} tendering config created/updated
   */
  public async postTenderingSettings(shipperOid: string, tenderingConfigBody: TenderingConfig): Promise<TenderingConfig> {
    return await this.http.put<TenderingConfig>(`${coreApiURL}/shipper/${shipperOid}/tenderingConfiguration`,
      tenderingConfigBody).toPromise();
  }

  /**
   * @description Save Driver config
   * @param shipperOid Shipper's Oid
   * @param driverConfig Driver configuration to be saved
   * @returns {Promise<DriverConfig>} Driver config saved
   */
  public async saveDriverConfig(shipperOid: string, driverConfig: DriverConfig): Promise<DriverConfig> {
    return await this.http.put<DriverConfig>(`${coreApiURL}/shipper/${shipperOid}/driverConfig`, driverConfig).toPromise();
  }

  /**
   * @description Saves the account's of public access of shipper
   * @param shipperOid Shipper's Oid
   * @param accountsConfig Accounts config to allow public access
   * @returns {} response of endpoint
   */
  public async savePublicAccessAccountConfig(shipperOid: string, accountsConfig: Array<AccountInfo>): Promise<Array<AccountInfo>> {
    return await this.http.put<Array<AccountInfo>>(`${coreApiURL}/shipper/${shipperOid}/publicAccessAccounts`, accountsConfig).toPromise();
  }

  /**
   * @description Saves shippers fields settings
   * @param {string} shipperOid Current shipper Oid
   * @param {FieldsSettings} fieldsSetting Fields settings to update
   * @returns {object} An response from endpoint
   */
  public async saveShipperFieldsSettings(shipperOid: string, fieldsSetting: FieldsSettings): Promise<object> {
    return await this.http.put<object>(`${coreApiURL}/shipper/${shipperOid}/shipperFieldsSettings`, fieldsSetting).toPromise();
  }

  /**
   * @description connects with core-api to update costing related configurations in shipper collection
   * @param shipperOid currect shipper object id
   * @param body body with configurations to be updated
   * @returns Response from core-api with success/failed message
   */
  public async saveCostingConfiguration(shipperOid: string, body: CostingConfigurationBody): Promise<object> {
    return await this.http.put<object>(`${coreApiURL}/shipperId/${shipperOid}/costingConfigurations`, body).toPromise();
  }

  /**
   * @description Saves documents setings for the current shipper.
   * @param { string } shipperOid - Current unique shipper identifier.
   * @param { DocumentsSettings } body - Documents setttings to save.
   * @returns { ShipperConfiguration } Shipper configurations updated.
   */
  public async saveDocumentsSettings(shipperOid: string, body: DocumentsSettings): Promise<ShipperConfiguration> {
    return await this.http.put<ShipperConfiguration>(`${coreApiURL}/shipper/${shipperOid}/documentsSettings`, body).toPromise();
  }

  /**
   * @description Saves parcel company settings for the current shipper.
   * @param { string } shipperOid - Current unique shipper identifier.
   * @param { ParcelCompanySettings } body - Parcel Company setttings to save.
   * @returns { ShipperConfiguration } Shipper configurations updated.
   */
  public async saveParcelCompanySettings(shipperOid: string, body: ParcelCompanySettings): Promise<ShipperConfiguration> {
    return await this.http.put<ShipperConfiguration>(`${coreApiURL}/shipper/${shipperOid}/parcelCompanySettings`, body).toPromise();
  }

  /**
   * @description Saves custody shipper settings for the current shipper.
   * @param { string } shipperOid - Current unique shipper identifier.
   * @param { CustodyShipperSettings } body - Custody shipper setttings to save.
   * @returns { ShipperConfiguration } Shipper configurations updated.
   */
  public async saveCustodyShipperSettings(shipperOid: string, body: CustodyShipperSettings): Promise<ShipperConfiguration> {
    return await this.http.put<ShipperConfiguration>(`${coreApiURL}/shipper/${shipperOid}/custodyShipperSettings`, body).toPromise();
  }
}

/**
 * @class
 * @description - Net Promoter Score object class.
 */
export class NetPromoterScore {
  public id?: string;
  public attempts: number;
  public comments: string;
  public creationDate?: Date;
  public creationUserId?: string;
  public creationWmsUserId?: number;
  public email?: string;
  public isActive?: boolean;
  public isAnswered: boolean;
  public isProductBoughtAgain?: boolean;
  public isRemoved?: boolean;
  public isTestimonyProvided?: boolean;
  public scoreValue: number;
  public solutionId: string;
  public tenantId: string;
  public tenantName: string;
  public type?: string;
  public updateDate?: Date;
  public updateUserId?: string;
  
  constructor() {
    this.id = undefined;
    this.scoreValue = undefined;
    this.type = undefined;
    this.comments = undefined;
    this.email = undefined;
    this.isProductBoughtAgain = undefined;
    this.isTestimonyProvided = undefined;
    this.solutionId  = undefined;
    this.tenantId  = undefined;
    this.tenantName  = undefined;
    this.isAnswered  = undefined;
    this.attempts = undefined;
    this.isActive = undefined;
    this.isRemoved = undefined;
    this.creationDate  = undefined;
    this.creationUserId  = undefined;
    this.creationWmsUserId  = undefined;
    this.updateDate  = undefined;
    this.updateUserId  = undefined;
  }
}

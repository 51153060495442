<div class="reponsive-card">
  <mat-grid-list cols="3" rowHeight="1:.15">
    <mat-grid-tile colspan="2">
      <span class="dialog-title">
        <p>{{ 'dialogTransportAssignmentLabels.title' | translate }}</p>
      </span>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-icon
        style="cursor: pointer"
        class="dialog-close-icon"
        (click)="onClose()">
        close
      </mat-icon>
    </mat-grid-tile>
  </mat-grid-list>

  <form id="transportAssignment" [formGroup]="transportAssignmentForm">
    <div class="dialog-card-container">

      <mat-card>
        <mat-card-header class="card-header">
          <mat-card-title>
            {{ 'dialogTransportAssignmentLabels.transportAssignmentTitle' | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>

          <mat-grid-list cols="5" rowHeight="1:.15">
            <mat-grid-tile *ngIf="isLeaseholderCheckBoxDisplayed">
              <mat-checkbox
                id="checkbox-isLeaseholder"
                [matTooltip]="'dialogTransportAssignmentLabels.isLeaseholder' | translate"
                formControlName="isLeaseholder"
                (change)="isLeaserCarrierValidateCheck($event)">
                  {{ 'dialogTransportAssignmentLabels.isLeaseholder' | translate }}
              </mat-checkbox>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="1:.30">
            <mat-grid-tile *ngIf="!injectData.isCustomerPickup">
              <mat-form-field>
                <mat-select
                  id="carrierSelect"
                  placeholder="{{ 'dialogTransportAssignmentLabels.carrier' | translate }}"
                  formControlName="carrier"
                  required
                  [disabled] = "isCarrierSelectDisabled"
                  (selectionChange)="onCarrierSelected(); assignLeaseholder();"
                  [compareWith]="compareCarriers"
                  disableOptionCentering="true">
                  <mat-option *ngFor="let carrier of carrierList" [value]="carrier">{{ carrier.nombre }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.carrierRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="injectData.isCustomerPickup">
              <mat-form-field>
                <input
                  matInput
                  id="carrierInput"
                  maxlength="120"
                  placeholder="{{ 'dialogTransportAssignmentLabels.carrier' | translate }}"
                  formControlName="carrier"
                  required
                  [disabled] = "isCarrierSelectDisabled">
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.carrierRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="isLeaseholder">
              <mat-form-field >
                <mat-select
                  id="select-leaseholder"
                  required
                  formControlName="leaseholder"
                  (selectionChange)="onLeaseholderSelected()"
                  placeholder="{{ 'dialogTransportAssignmentLabels.leaseholder' | translate }}"
                  [disabled] = "injectData.isCourierShipment"
                  [compareWith]="compareCarriers"
                  disableOptionCentering="true">
                  <mat-option *ngFor="let carrier of carrierLeaseholderList" [value]="carrier">{{ carrier.nombre }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.leaseholderRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="shouldUseVehicleSelectField()">
              <mat-form-field>
                <mat-select
                  id="vehicle-select"
                  required
                  placeholder="{{ 'dialogTransportAssignmentLabels.vehicle' | translate }}"
                  formControlName="vehicle"
                  (selectionChange)="onVehicleSelected()"
                  [disabled] = "isVehicleSelectDisabled"
                  [compareWith]="compareVehicles"
                  disableOptionCentering="true">
                  <mat-option *ngFor="let vehicle of vehicleList" [value]="vehicle">{{ vehicle }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.vehicleRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="!shouldUseVehicleSelectField()">
             <mat-form-field>
               <input
                id="vehicleInput"
                matInput
                maxlength="50"
                autocomplete="off"
                required
                placeholder="{{ 'dialogTransportAssignmentLabels.vehicle' | translate }}"
                formControlName="vehicle">
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.vehicleRequired' | translate }}
                </mat-error>
             </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="shouldUseDriverAndPlatesInputFields()">
              <mat-form-field>
                <input
                  id="platesInput"
                  matInput
                  maxlength="15"
                  autocomplete="off"
                  required
                  placeholder="{{ 'dialogTransportAssignmentLabels.plates' | translate }}"
                  formControlName="plates">
                 <mat-error>
                  {{ 'dialogTransportAssignmentLabels.platesRequired' | translate }}
                </mat-error>
              </mat-form-field>
             </mat-grid-tile>
             <mat-grid-tile *ngIf="shouldUseDriverAndPlatesInputFields()">
              <mat-form-field>
                <input
                  id="driverInput"
                  matInput
                  maxlength="150"
                  autocomplete="off"
                  required
                  placeholder="{{ 'dialogTransportAssignmentLabels.driver' | translate }}"
                  formControlName="driver">
                 <mat-error>
                  {{ 'dialogTransportAssignmentLabels.driverRequired' | translate }}
                </mat-error>
              </mat-form-field>
             </mat-grid-tile>
            <mat-grid-tile *ngIf="shouldUseDriverAndPlatesSelectFields()">
              <mat-form-field>
                <mat-select
                  id="platesSelect"
                  required
                  placeholder="{{ 'dialogTransportAssignmentLabels.plates' | translate }}"
                  formControlName="plates"
                  (selectionChange)="setDefaultDriver()"
                  [compareWith]="comparePlates"
                  disableOptionCentering="true">
                  <mat-option *ngFor="let plates of platesList" [value]="plates">{{ plates.placas }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.platesRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="shouldUseDriverAndPlatesSelectFields()">
              <mat-form-field>
                <mat-select
                  id="driverSelect"
                  required
                  placeholder="{{ 'dialogTransportAssignmentLabels.driver' | translate }}"
                  formControlName="driver"
                  (selectionChange)="setDefaultVehicle()"
                  [compareWith]="compareDrivers"
                  disableOptionCentering="true">
                  <mat-option *ngFor="let driver of driverList" [value]="driver">{{ driver.nombre + ' ' + driver.appaterno + ' ' + driver.apmaterno }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.driverRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <input
                  id="helperInput"
                  placeholder="{{ 'dialogTransportAssignmentLabels.helper' | translate }}"
                  matInput
                  autocomplete="off"
                  formControlName="helper">
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="shouldUseDriverAndPlatesSelectFields()">
              <mat-form-field >
                <mat-select
                  id="select-trailer"
                  formControlName="trailer"
                  (selectionChange)="selectTrailers()"
                  multiple
                  placeholder="{{ 'dialogTransportAssignmentLabels.trailer' | translate }}"
                  [disabled] = "injectData.isCourierShipment"
                  [compareWith]="compareTrailers"
                  disableOptionCentering="true">
                  <mat-option *ngFor="let trailerItem of trailerList" [value]="trailerItem">{{ trailerItem.plates }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.trailerRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="isTrailerInputDisplayed">
              <mat-form-field >
                <input
                  matInput
                  id="trailerInput"
                  maxlength="30"
                  formControlName="trailer"
                  placeholder="{{ 'dialogTransportAssignmentLabels.trailer' | translate }}"
                  [disabled] = "injectData.isCourierShipment">
                <mat-error>
                  {{ 'dialogTransportAssignmentLabels.trailerRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="isAssignShipperCheckBoxDisplayed">
              <mat-checkbox
                id="assignShipperCheckBox"
                [disabled]="injectData.isCustomerPickup"
                [matTooltip]="'dialogTransportAssignmentLabels.assignByShipperTooltip' | translate"
                formControlName="assignByShipper"
                (change)="assignByShipperValidateCheck($event)">
                  {{ 'dialogTransportAssignmentLabels.assignByShipper' | translate }}
              </mat-checkbox>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="!injectData.isCourierShipmentTrip">
              <mat-form-field>
                <mat-select
                  multiple
                  id="dtac-suppliers-select"
                  placeholder="{{ 'dialogTransportAssignmentLabels.additionalSuppliers' | translate }}"
                  (selectionChange)="onSelectSupplier()"
                  (click)="checkSuppliers()"
                  disableOptionCentering="true"
                  [compareWith]="compareSuppliers"
                  formControlName="additionalsSuppliers">
                  <mat-option
                    *ngFor="let supplier of additionalsSuppliers"
                    [value]="supplier">{{ supplier.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <form id="accessDateFormGroup" [formGroup]="accessDateForm" *ngIf="isAccessDateFormSectionDisplayed">
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    id="admissionCedisDate"
                    (keydown.enter)="handleKeyEnter($event)"
                    (dateChange)="changeDate($event)"
                    class="input--pointer"
                    matInput
                    [matDatepicker]="admissionCedisDate"
                    placeholder="{{ 'dialogTransportAssignmentLabels.admissionCedisDate' | translate }}"
                    readonly
                    [min]="currentDay"
                    formControlName="admissionCedisDate"
                    (click)="admissionCedisDate.open()">
                  <mat-datepicker-toggle matSuffix [for]="admissionCedisDate"></mat-datepicker-toggle>
                  <mat-datepicker #admissionCedisDate></mat-datepicker>
                  <mat-error>
                    {{ 'dialogTransportAssignmentLabels.admissionCedisDateRequired' | translate }}
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    id="admissionCedisHour"
                    #admissionCedisHour
                    matInput
                    (keydown.enter)="handleKeyEnter($event)"
                    class="input--pointer"
                    type="time"
                    formControlName="admissionCedisHour"
                    placeholder="{{ 'dialogTransportAssignmentLabels.admissionCedisHour' | translate }}">
                  <mat-error>
                    {{ 'dialogTransportAssignmentLabels.admissionCedisHourRequired' | translate }}
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    id="loadStartDate"
                    class="input--pointer"
                    matInput
                    (dateChange)="changeDate($event)"
                    [min]="currentDay"
                    [matDatepicker]="loadStartDate"
                    readonly
                    placeholder="{{ 'dialogTransportAssignmentLabels.loadStartDate' | translate }}"
                    formControlName="loadStartDate"
                    (click)="loadStartDate.open()">
                  <mat-datepicker-toggle matSuffix [for]="loadStartDate"></mat-datepicker-toggle>
                  <mat-datepicker #loadStartDate></mat-datepicker>
                  <mat-error>
                    {{ 'dialogTransportAssignmentLabels.loadStartDateRequired' | translate }}
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    id="loadStartHour"
                    #loadStartHour
                    matInput
                    (keydown.enter)="handleKeyEnter($event)"
                    class="input--pointer"
                    type="time"
                    formControlName="loadStartHour"
                    placeholder="{{ 'dialogTransportAssignmentLabels.loadStartHour' | translate }}">
                    <mat-error>
                      {{ 'dialogTransportAssignmentLabels.loadStartHourRequired' | translate }}
                    </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    id="loadEndDate"
                    class="input--pointer"
                    matInput
                    (dateChange)="changeDate($event)"
                    [min]="currentDay"
                    [matDatepicker]="loadEndDate"
                    readonly
                    placeholder="{{ 'dialogTransportAssignmentLabels.loadEndDate' | translate }}"
                    formControlName="loadEndDate"
                    (click)="loadEndDate.open()">
                  <mat-datepicker-toggle matSuffix [for]="loadEndDate"></mat-datepicker-toggle>
                  <mat-datepicker #loadEndDate></mat-datepicker>
                  <mat-error>
                    {{ 'dialogTransportAssignmentLabels.loadEndDateRequired' | translate }}
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    id="loadEndHour"
                    #loadEndHour
                    matInput
                    (keydown.enter)="handleKeyEnter($event)"
                    class="input--pointer"
                    type="time"
                    formControlName="loadEndHour"
                    placeholder="{{ 'dialogTransportAssignmentLabels.loadEndHour' | translate }}">
                    <mat-error>
                      {{ 'dialogTransportAssignmentLabels.loadEndHourRequired' | translate }}
                    </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    id="onRouteDateTime"
                    class="input--pointer"
                    matInput
                    (dateChange)="changeDate($event)"
                    [min]="currentDay"
                    [matDatepicker]="onRouteDateTime"
                    readonly
                    placeholder="{{ 'dialogTransportAssignmentLabels.onRouteDateTime' | translate }}"
                    formControlName="onRouteDateTime"
                    (click)="onRouteDateTime.open()">
                  <mat-datepicker-toggle matSuffix [for]="onRouteDateTime"></mat-datepicker-toggle>
                  <mat-datepicker #onRouteDateTime></mat-datepicker>
                  <mat-error>
                    {{ 'dialogTransportAssignmentLabels.onRouteDateTimeRequired' | translate }}
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field>
                  <input
                    id="onRouteDateHour"
                    #onRouteDateHour
                    matInput
                    (keydown.enter)="handleKeyEnter($event)"
                    class="input--pointer"
                    type="time"
                    formControlName="onRouteDateHour"
                    placeholder="{{ 'dialogTransportAssignmentLabels.onRouteDateHour' | translate }}">
                    <mat-error>
                      {{ 'dialogTransportAssignmentLabels.onRouteDateHourRequired' | translate }}
                    </mat-error>
                </mat-form-field>
              </mat-grid-tile>
            </form>
          </mat-grid-list>
        </mat-card-content>
        <div class="container-places" *ngIf="isDateAndTimeSectionDisplayed">
          <div id='dateAndTimeContainer' class="dateAndTime-container">
            <div class="dateAndTime-table-container">
              <div class="dateAndTime-header">
                <div class="subtitle-divider-container">
                  <div class="subtitle__name">
                    <h4>{{ 'dialogTransportAssignmentLabels.dateAndTimeInfo' | translate }}</h4>
                  </div>
                  <div class="subtitle__hr">
                    <hr>
                  </div>
                </div>
              </div>

              <table class="resume-table" id="dateAndTime-table" role="presentation">
                <tr>
                  <td class="date-data-property">{{ 'dialogTransportAssignmentLabels.admissionCedisDate' | translate }}:</td>
                  <td class="date-data-property">{{ 'dialogTransportAssignmentLabels.loadStartDate' | translate }}:</td>
                  <td class="date-data-property">{{ 'dialogTransportAssignmentLabels.loadEndDate' | translate }}:</td>
                  <td class="date-data-property">{{ 'dialogTransportAssignmentLabels.onRouteDateTime' | translate }}:</td>
                </tr>
                <tr>
                  <td class="date-data-value">{{ injectData.admissionCedisDate | date: 'dd/MM/yyyy, HH:mm a' | nodata }}</td>
                  <td class="date-data-value">{{ injectData.loadStartDate | date: 'dd/MM/yyyy, HH:mm a' | nodata }}</td>
                  <td class="date-data-value">{{ injectData.loadEndDate | date: 'dd/MM/yyyy, HH:mm a' | nodata }}</td>
                  <td class="date-data-value">{{ injectData.onRouteDateTime | date: 'dd/MM/yyyy, HH:mm a' | nodata }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card *ngIf="injectData.isCustomerPickup && !injectData.isCourierShipment">
        <mat-card-header>
          <mat-card-title>
            <div class="add-file">
              <span>{{ 'dialogTransportAssignmentLabels.identificationFiles' | translate }}</span>
              <button
                [ngClass]="{'disabled-effect': isLimitFilesReached}"
                class="icon-button"
                id="openFileBrowserButton"
                [disabled]="isLimitFilesReached"
                (click)="openFilesBrowser()"
                isIconButton="true">
                <mat-icon>add</mat-icon>
              </button>
              <input
                [ngClass]="{'disabled-effect': isLimitFilesReached}"
                [disabled]="isLimitFilesReached"
                type="file"
                name="files"
                id="filesInput"
                (change)="addFiles()"
                hidden
                accept=".pdf,.jpg,.jpeg,.png">
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="padding-drop-zone">
          <app-drag-and-drop class="drag-and-drop-container"
            [filesInDropZone]="identificationFilesConverted"
            [allowedFormatExtensions]="filesFormatsAllowed"
            [maxFilesAllowed]="3"
            [filesAddedByButton]="filesAddedByButton"
            (filesDropped)="filesDropped($event)">
          </app-drag-and-drop>
        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="isSpecialRequirementsSectionDisplayed">
        <mat-card-header class="card-header">
          <mat-card-title>
            {{ 'dialogTransportAssignmentLabels.specialRequirementsTitle' | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list cols="4" rowHeight="1:.17">
            <mat-grid-tile formArrayName="specialRequirementsModal" *ngFor="let req of requirementsFormArray.controls; let i = index">
              <mat-checkbox
                [formControlName]="i"
                (change)="checkboxChange(specialRequirements[i].value,$event)"
                color="primary"
                class="checkbox__title">
                {{ getTranslatedShipmentSpecialRequirementLabel(specialRequirements[i].value) }}
              </mat-checkbox>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="1:.25">
            <mat-grid-tile colspan="3">
              <mat-form-field class="long-input">
                <input
                  id="additionalRequerimentsInput"
                  matInput
                  autocomplete="off"
                  placeholder="{{ 'dialogTransportAssignmentLabels.additionalRequirements' | translate }}"
                  formControlName="otherRequirements"
                  maxlength="1000">
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
  <mat-grid-list cols="4" rowHeight="1:.3">
    <mat-grid-tile colspan="3"></mat-grid-tile>
    <mat-grid-tile class="button-container">
      <button
        id="cancelButton"
        mat-button
        class="secondary-button align-left"
        (click)="onCancel()">
        {{ 'dialogTransportAssignmentLabels.exit' | translate }}
      </button>
      <button
        id="saveButton"
        mat-raised-button
        class="primary-button align-right"
        (click)="onSave()"
        [disabled]="transportAssignmentForm.invalid ||
                    accessDateForm.invalid">
        {{ 'dialogTransportAssignmentLabels.save' | translate }}
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>

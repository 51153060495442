import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { AppConstants } from '../../constants/app-constants.constants';
import { CommunicationService } from '../../services/communication';
import { GenericRegexp } from '../../regexp/generic.regexp';
import { LanguageChangeEventService } from '../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';
import { SearchMatSelectResults } from '../../interfaces/search-mat-select';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

const AT_LEAST_STRING_LONG = 2;
const MULTI_SELECT_KEY = 'multiSelect';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss', '../../app.component.scss']
})
export class SelectSearchComponent implements OnDestroy, OnInit, OnChanges {
  protected _onDestroy = new Subject<void>();
  protected fields: Array<SearchMatSelectResults>;

  public fieldMultiCtrl: UntypedFormControl;
  public fieldMultiFilterCtrl: UntypedFormControl;
  public filteredFieldsCache: Array<SearchMatSelectResults>;
  public filteredFieldsMulti: ReplaySubject<Array<SearchMatSelectResults>>;
  public filteredSingleResults: Array<SearchMatSelectResults>;
  public isChecked: boolean;
  public isIndeterminate: boolean;
  public selectSearchLabels: any;
  public languageLabels: any;
  public languageSuscription: Subscription;
  public noEntriesFoundLabel: string;
  public searchingfield: boolean;
  public showToggleAllCheckbox: boolean;

  @Input() public controlName: string;
  @Input() public enableInput?: boolean;
  @Input() public isFirstPreload?: boolean;
  @Input() public isRequiredInput?: boolean;
  @Input() public isSingleSelection: boolean;
  @Input() public multipleSelectSearch?: boolean;
  @Input() public pre_results?: Array<SearchMatSelectResults>;
  @Input() public requiredErrorLabel: string;
  @Input() public results: Array<SearchMatSelectResults>;
  @Input() public selectLabel: string;
  @Input() public shouldAllowOnlyAlphanumeric: boolean;
  @Input() public shouldDisplayRequiredError: boolean;
  @ViewChild(MULTI_SELECT_KEY, { static: true }) multiSelect: MatSelect;

  constructor(
    private comService: CommunicationService,
    private toastrService: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();

    if (this.isSingleSelection) {
      this.filteredFieldsMulti = new ReplaySubject(1);
      this.filteredFieldsMulti.next([]);
    }
  }

  /**
   * @description Validates if the input only has alphanumeric characters and deletes the non-alphanumeric characteres.
   * @param event - Input data.
   */
  public validateAlphanumericInput(event: Event): void {
    if (this.shouldAllowOnlyAlphanumeric) {
      const inputElement = event.target as HTMLInputElement;
      inputElement.value = inputElement.value.replace(GenericRegexp.ALPHANUMERIC_ONLY, AppConstants.EMPTY_STRING);
    }
  }

  /**
   * @description Angular init Lifecycle
   */
  public async ngOnInit(): Promise<void> {
    await this.initComponent();

    if (this.enableInput === false) {
      this.fieldMultiCtrl.disable();
    } else {
      this.fieldMultiCtrl.enable();
    }
  }

  /**
   * @description Angular onChanges Lifecycle
   */
  public ngOnChanges(): void {
    this.searchingfield = false;
    this.checkChangesType();

    if (this.filteredFieldsCache && this.filteredFieldsCache.length > 0) {
      this.showToggleAllCheckbox = true;
    } else {
      this.showToggleAllCheckbox = false;
    }
    this.filteredFieldsMulti?.next(this.filteredFieldsCache);
    this.fieldMultiFilterCtrl?.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSearch();
      });

    if (this.isSingleSelection) {
      this.fieldMultiCtrl?.setValue(Array.isArray(this.fieldMultiCtrl.value) ? this.fieldMultiCtrl.value[0] : this.fieldMultiCtrl.value);
    }
  }

  /**
   * @description Returns current results
   */
  protected filterSearch(): void {
    if (!this.results) {
      return;
    }
    let search = this.fieldMultiFilterCtrl.value;
    if (search.length < 3) {
      this.filteredFieldsMulti.next(this.fieldMultiCtrl.value);
      this.showToggleAllCheckbox = false;
    }}

  /**
   * @description checks changes triggered in the component to set validator in the input, pre-charge
   * data in the selector, remove duplicated results and update the toggle status of results
   */
  public checkChangesType(): void {
    if (this.isRequiredInput) {
      this.fieldMultiCtrl?.setValidators(Validators.required);
    }

    if (!this.isSingleSelection) {
      if (this.isFirstPreload) {
        this.checkAndPreLoadInfo();
      } else if (this.results?.length) {
        const resultsChecked = this.fieldMultiCtrl?.value;

        if (resultsChecked) {
          const searchResults = this.removeDuplicatedResults(resultsChecked);
          this.filteredFieldsCache = resultsChecked ? resultsChecked.concat(searchResults) : this.results;
          this.setToggleAllCheckboxState();
        } else {
            this.filteredFieldsCache = this.results;
        }
      }
    } else {
      this.filteredSingleResults = this.results;
    }
    this.enableOrDisableInput();
  }

  /**
   * @description validates if the flag to enable/disable selectInput is invoiced as false to block to selector,
   * otherwise lets the selector enabled
   */
  public enableOrDisableInput(): void {
    if (!this.fieldMultiCtrl) {
      return;
    }

    if (this.enableInput === false) {
      this.fieldMultiCtrl.markAsUntouched();
      this.fieldMultiCtrl.setValue(null);
      this.toggleSelectAll(false);
    } else {
      this.fieldMultiCtrl.markAsTouched();
    }
    this.fieldMultiCtrl.updateValueAndValidity();
  }

  /**
   * @description method to validate the new results and remove the same results selected from previous search
   * @param {Array<SearchMatSelectResults>} results results actually selected to make the comparisons
   * @returns {Array<SearchMatSelectResults>} just results that have not been selected yet
   */
  public removeDuplicatedResults(results: Array<SearchMatSelectResults>): Array<SearchMatSelectResults> {
    if (!this.isSingleSelection) {
      for (const result of results) {
        if (this.results.find(element => element._id === result._id)) {
          const indexResult = this.results.findIndex(element => element._id === result._id);
          this.results.splice(indexResult, 1);
        }
      }
    } else {
      if (!Array.isArray(results)) {
        results = [results];
      }
    }

    return this.results;
  }

  /**
   * @description Angular destroy Lifecycle
   */
  public ngOnDestroy(): void {
    this.languageSuscription?.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * @description checks if have been passed pre-results to the the loader to display it in the selector and mark as checked
   */
  public checkAndPreLoadInfo(): void {
    this.filteredFieldsCache = this.pre_results ? this.pre_results : null;
    this.fieldMultiCtrl.setValue(this.pre_results);
    this.setToggleAllCheckboxState();
  }

  /**
   * @description Emit search action
   */
  public onSearch(): void {
    this.filterFieldsMulti();
    this.setToggleAllCheckboxState();
  }

  /**
   * @description Listen for toggle all selection
   * @param selectAllValue
   */
  public toggleSelectAll(selectAllValue: boolean): void {
    if (!this.isSingleSelection) {
      if (selectAllValue) {
        const items = this.filteredFieldsCache;
        items.forEach(item => item.controlName = this.controlName);
        this.comService.searchMatSelection(items);
      } else if (!selectAllValue && this.multipleSelectSearch) {
        this.comService.searchMatSelection([{ controlName: this.controlName }]);
      } else {
        this.comService.searchMatSelection([]);
      }
      this.filteredFieldsMulti.pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(val => {
          if (selectAllValue) {
            this.fieldMultiCtrl.patchValue(val);
          } else {
            this.fieldMultiCtrl.patchValue([]);
          }
        });
    }
  }

  /**
   * @description Set select/unselect state for all Checkbox items
   */
  protected setToggleAllCheckboxState(): void {
    let filteredLength = 0;
    if (!this.isSingleSelection && this.fieldMultiCtrl && this.fieldMultiCtrl.value) {
      this.filteredFieldsCache.forEach(result => {
        if (this.fieldMultiCtrl.value.indexOf(result) > -1) {
          filteredLength++;
        }
      });
      this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredFieldsCache.length;
      this.isChecked = filteredLength > 0 && filteredLength === this.filteredFieldsCache.length;
    }
  }

  /**
   * @description Validates if the account required error should be displayed.
   * @returns {boolean} - TRUE if it shoyld be displayed.
   */
  public shouldAccountIsRequiredErrorBeDisplayed(): boolean {
    if (this.enableInput === false) {
      this.fieldMultiCtrl.disable();
    } else {
      this.fieldMultiCtrl.enable();
    }

    return !this.isSingleSelection && this.fieldMultiCtrl?.hasError('required');
  }

  /**
   * @description Handle search event
   */
  private filterFieldsMulti(): void {
    let search = this.fieldMultiFilterCtrl.value;
    if (!search) {
      this.showToggleAllCheckbox = false;
      this.filteredFieldsCache = [];

      if (!this.isSingleSelection) {
        this.filteredFieldsMulti?.next(this.filteredFieldsCache);
      } else {
        this.filteredSingleResults = this.results;
      }

      return;
    } else if (search.length <= AT_LEAST_STRING_LONG) {
      this.showToggleAllCheckbox = false;
      this.filteredFieldsCache = [];

      if (!this.isSingleSelection) {
        this.filteredFieldsMulti?.next(this.filteredFieldsCache);
      } else {
        this.filteredSingleResults = this.results;
      }

      return;
    } else {
      search = search.toLowerCase();
    }
    this.searchingfield = true;
    this.comService.searchMatSelectEvent({controlName: this.controlName, pharse: search});
  }

  /**
   * @description Emit selection changes by user
   * @param {MatSelectChange} selection Option selection by user
   */
  public emitSelectionChange(selection: MatSelectChange): void {
    let items = selection.value;

    if (!this.enableInput) {
      this.fieldMultiCtrl.disable();
    } else {
      this.fieldMultiCtrl.enable();
    }

    if (!this.isSingleSelection) {
      items.map(item => item.controlName = this.controlName);

      if (!items.length && this.multipleSelectSearch) {
        items = [];
        items.push({ controlName: this.controlName });
      }
    }

    this.comService.searchMatSelection(items);
  }

  /**
   * @description Validate no white spaces in input field
   * @param {FormControl} control Input field to be validated
   * @returns {whitespace: boolean} FormControl error if exists
   */
  public noWhitespaceValidator(control: UntypedFormControl): { whitespace: boolean } {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  /**
   * Listen for multi select field value changes
   */
   private listenInputChanges(): void {
    this.fieldMultiCtrl?.valueChanges
    .pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.setToggleAllCheckboxState();
    });
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getSelectSearchLabels();
      },
      () => {
        this.toastrService.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
      .catch(() => {
        this.toastrService.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description Gets select search labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getSelectSearchLabels(): Promise<void> {
    this.selectSearchLabels =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.SELECT_SEARCH)
        .catch(() => {
          this.toastrService.errorAlert(this.languageLabels.errorGettingLabels);
        });
  }

  /**
   * @description Start the tasks in the component.
   */
  private async initComponent(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    this.fieldMultiCtrl = new UntypedFormControl(!this.isSingleSelection ? [] : null);
    this.fieldMultiFilterCtrl = new UntypedFormControl(null, [this.noWhitespaceValidator]);
    this.filteredFieldsMulti = new ReplaySubject<Array<SearchMatSelectResults>>(1);
    this.filteredFieldsCache = [];
    this.noEntriesFoundLabel = null;
    this.searchingfield = false;
    this.showToggleAllCheckbox = false;
    this.isIndeterminate = false;
    this.isChecked = false;
    this.listenInputChanges();
    this.checkAndPreLoadInfo();
    await this.getLanguageTags();
    await this.getSelectSearchLabels();
    await this.clearFilters();
  }

  /**
   * @description Clear select search component.
   */
  public async clearFilters(): Promise<void> {
    this.comService.resetComponentSubscribe().subscribe(async () => {
      await this.initComponent();
    })
  }
}

<mat-form-field class="search-mat-container">
  <mat-select
    id="mat-multiple-select-wrapper"
    [formControl]="fieldMultiCtrl"
    [placeholder]="selectLabel"
    [disabled]="enableInput === false"
    [multiple]="!isSingleSelection"
    (selectionChange)="emitSelectionChange($event)"
    #multiSelect>
    <mat-option>
      <ngx-mat-select-search 
        id="mat-multiple-select"
        (input)="validateAlphanumericInput($event)"
        placeholderLabel="{{ 'selectSearchLabels.placeholderLabel' | translate }}"
        [noEntriesFoundLabel]="null"
        toggleAllCheckboxTooltipMessage="{{ 'selectSearchLabels.toggleAllCheckboxTooltipMessage' | translate }}"
        [showToggleAllCheckbox]="showToggleAllCheckbox"
        [toggleAllCheckboxIndeterminate]="isIndeterminate"
        [toggleAllCheckboxChecked]="isChecked"
        (toggleAll)="toggleSelectAll($event)"
        [formControl]="fieldMultiFilterCtrl"
        [hideClearSearchButton]="true"
        [searching]="searchingfield"
        minlength="3">
      </ngx-mat-select-search>
    </mat-option>
    <div class="search-button-container">
      <button
        id="mat-multiple-select-search-button"
        class="search-button"
        (click)="onSearch()"
        *ngIf="!fieldMultiFilterCtrl.hasError('minlength') && !fieldMultiFilterCtrl.hasError('whitespace')">
        {{ 'selectSearchLabels.search' | translate }}
      </button>
    </div>
    <div *ngIf="!isSingleSelection">
      <mat-option
        *ngFor="let field of filteredFieldsMulti | async"
        [value]="field">
        {{ field.name }}
      </mat-option>
    </div>
    <div *ngIf="isSingleSelection">
      <mat-option
        *ngFor="let result of filteredSingleResults"
        [value]="result">
        {{ result.name }}
      </mat-option>
    </div>
  </mat-select>
  <mat-error *ngIf="shouldAccountIsRequiredErrorBeDisplayed()">
    {{ 'selectSearchLabels.selectOneAccount' | translate }}
  </mat-error>
  <mat-error *ngIf="shouldDisplayRequiredError">
    <strong>{{ requiredErrorLabel }}</strong>
  </mat-error>
</mat-form-field>

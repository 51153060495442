import { Injectable } from '@angular/core';

import { Carrier, ResponseShipmentRequest, WarehouseBody } from '../interfaces';
import { CarrierProvider } from '../providers/carrier/carrier.provider.service';
import { ConfigurationProvider } from '../providers/configuration/configuration.provider.service';
import { CouriersEnabled } from '../interfaces/couriers/couriers-enabled.interface';
import { DriverProvider } from '../providers/driver/driver.provider.service';
import { LoadTypeProvider } from '../providers/load-type/load-type.provider.service';
import { LocationProvider } from '../providers/locations/location-provider.service';
import { OrderProvider } from '../providers/orders/order-provider.service';
import { ShipmentProvider } from '../providers/shipments/shipment-provider.service';
import { ShipmentRowExtended } from '../interfaces/shipment-view';
import { ToastrAlertsService } from './utils/toastr-alerts.service';
import { VehiculeTypeProvider } from '../providers/vehiculeType/vehiculeType.provider.service';
import { WarehouseProvider } from '../providers/warehouse/warehouse-provider.service';

import * as _ from 'lodash';
import * as moment from 'moment';

const BUILDSTOPSDETAILSERROR = 'Error al construir el detalle de las paradas';
const CARRIER = 'lineasTransporte';
const CARRIEROID = 'carrierOid';
const CONSOLIDATED = 'Consolidado';
const COURIERTRIP = 'Paquetera';
const DISTRIBUTION = 'Reparto';
const DRIVEROID = 'driverOid';
const FINDBYNAME = 1;
const FIRSTLASTNAME = 'appaterno';
const GETCARRIERERROR = 'Ocurrió un error al recuperar la línea de transporte';
const GETDRIVERERROR = 'Ocurrió un error al recuperar el chofer';
const GETLOADTYPESERROR = 'Ocurrió un error al recuperar los tipos de carga';
const GETORDERSERROR = 'Error al obtener la información de las órdenes';
const GETVEHICLESERROR = 'Ocurrió un error al recuperar el vehículo';
const ID = '_id';
const LOADTYPEOID = 'loadTypeOid';
const NAME = 'nombre';
const ORDERSREQUESTCREATIONERROR = 'Error al crear la solicitud de las ordenes';
const REQUIREMENT = 'requisito';
const SECONDLASTNAME = 'apmaterno';
const SHIPMENT = 'solicitud';
const SHIPMENTCREATED = 'El embarque se confirmó con éxito con el folio: ';
const SHIPMENTCREATEDERROR = 'Error al crear el embarque';
const SHIPMENTCREATEDFAILED = 'Hubo un error, intente nuevamente';
const STRING_TYPE = 'string';
const STOP = 'stop';
const STR_EMPTY = '';
const TO_UNIX_VALUE = 1000;
const TYPE = 'tipo';
const TYPE_TRIP_PICK_UP = 'Cliente recoge';
const VEHICLEOID = 'vehicleOid';
const WAITWHILECREATING = 'Espere mientras se procesa la información';

@Injectable()
export class MobilityShipmentService {

  constructor(
    private configProvider: ConfigurationProvider,
    private carrierProvider: CarrierProvider,
    private driverProvider: DriverProvider,
    private loadTypeProvider: LoadTypeProvider,
    private locationProvider: LocationProvider,
    private orderProvider: OrderProvider,
    private shipmentProvider: ShipmentProvider,
    private toastrAlertService: ToastrAlertsService,
    private vehicleTypeProvider: VehiculeTypeProvider,
    private warehouseProvider: WarehouseProvider
  ) {}

  public async createMobilityShipmentRequest(shipperOid: string, userOid: string, row: ShipmentRowExtended): Promise<Object> {
    let specialRequirements;
    let stopDetails;
    let remainingOids;
    let shipmentCreated;

    this.toastrAlertService.infoAlert(WAITWHILECREATING);

    await this.setStopDetails(row)
      .then((res) => { stopDetails = res; })
      .catch((err) => { throw new Error(err); });

    await this.getRemainingOids(row, shipperOid)
      .then((res) => { remainingOids = res; })
      .catch((err) => { throw new Error(err); });

    specialRequirements = this.setSpecialRequirements(row);

    if (row.tripType === COURIERTRIP) {
      row.tripType = CONSOLIDATED;
    }

    await this.createShipment(this.setShipmentRequestValues(row, shipperOid, userOid, remainingOids, stopDetails, specialRequirements))
      .then((res) => { shipmentCreated = res; })
      .catch((err) => { throw new Error(err); });

    return shipmentCreated;
  }

  /**
   * @description prepare the object to send for the generation of the shipmentRequest
   * @param {string} shipperOid shipper who generate the request
   * @param {string} userOid user who generate the request
   * @param {ShipmentRowExtended} row Shipment data
   * @return {Promise<ResponseShipmentRequest>} response of request for generate the shipmentRequest
   */
  public async generateShipmentRequest(shipperOid: string, userOid: string, row: ShipmentRowExtended): Promise<ResponseShipmentRequest> {
    this.toastrAlertService.infoAlert(WAITWHILECREATING);
    const stopDetails = await this.setStopDetails(row)
      .catch((err) => { throw new Error(err); });

    const remainingOids = {};
    const specialRequirements = this.setSpecialRequirements(row);

    await this.loadTypeProvider.getLoadTypeByShipperId(shipperOid)
    .then((res: Array<Object>) => {
      const loadTypes = res;
      remainingOids[LOADTYPEOID] = loadTypes.find((loadType) => loadType[NAME] === row.loadType.toUpperCase());
    }).catch(() => {
      throw new Error(GETLOADTYPESERROR);
    });

    if (row.tripType === COURIERTRIP) {
      row.tripType = CONSOLIDATED;
    }
    const body = this.setShipmentRequestValues(row, shipperOid, userOid, remainingOids, stopDetails, specialRequirements);

    const response = await this.shipmentProvider.createShipmentRequest(body);
    if (response.item?.solicitud) {
      this.toastrAlertService.successAlert(SHIPMENTCREATED + response.item.solicitud.id);
    }

    return response;
  }

  public setSpecialRequirements(row): Object {
    let specialRequirements: Array<Object>;
    specialRequirements = [];

    if (row.specialRequirements.requirements.length) {
        row.specialRequirements.requirements.forEach((requirement) => {
        specialRequirements.push({ nombre: requirement });
      });
    }
    if (row.specialRequirements.otherRequirements) {
      specialRequirements.push({ nombre: row.specialRequirements.otherRequirements });
    }
    return specialRequirements;
  }

  public async setStopDetails(row): Promise<Object> {
    let ordersIdsByStop: Array<Array<string>>;
    let orderIdsInStop: Array<string>;
    let ordersFound: Object;
    let paramsIds: Array<string>;
    let stopDetails: Array<Object>;
    ordersIdsByStop = [];
    orderIdsInStop = [];
    paramsIds = [];
    stopDetails = [];

    const ordersGroupByStop = _.groupBy(row.orders, STOP);
    for (const stop in ordersGroupByStop) {
      if (stop) {
        let ordersIds;
        orderIdsInStop = [];
        ordersGroupByStop[stop].forEach((order) => {
          orderIdsInStop.push(order._id);
        });
        ordersIdsByStop.push(orderIdsInStop);
        ordersIds = orderIdsInStop;
      }

    }
    await this.createRequestOrdersIdsArray(row, ordersIdsByStop).then((responseArray) => {
      if (responseArray) {
        paramsIds = responseArray;
      }
    }).catch((err) => {
      throw new Error(ORDERSREQUESTCREATIONERROR);
    });

    await this.orderProvider.getOrderByOids({ ordersIds: paramsIds }).then((res) => {
      ordersFound = res;
    }).catch((err) => {
      throw new Error(GETORDERSERROR);
    });

    const warehouses = await this.getWarehouses(row);

    await this.buildStopDetails(ordersFound, row, ordersIdsByStop, warehouses).then((stopD) => {
      stopDetails = stopD;
    }).catch((err) => {
      throw new Error(BUILDSTOPSDETAILSERROR);
    });
    return stopDetails;
  }

  /**
   * @description Get all planned warehouses of shipment stops
   * @param {object} row shipment to get orders and planned warehouses
   * @returns {Array<WarehouseBody>} found warehouses
   */
  public async getWarehouses(row: object): Promise<Array<WarehouseBody>> {
    const orders = Object(row).orders;
    const warehouses = [];

    for (const order of orders) {
      if (order.plannedWarehouse) {
        const warehouse = await this.warehouseProvider.getWarehouseByOid(order.plannedWarehouse._id);
        warehouses.push(warehouse);
      }
    }

    return warehouses;
  }

  public async getRemainingOids(row, shipperOid): Promise<Object> {
    const TRANSPORT_CARRIER = row.transport.leaseholder ? row.transport.leaseholder : row.transport.transportCarrier;
    let courierSelected: CouriersEnabled;
    let remainingOids: Object;
    let carrierData: Carrier;
    remainingOids = {};

    if (row.tripType === COURIERTRIP) {
      const shipperConfig = await this.configProvider.getShipperConfig();
      courierSelected = shipperConfig.courierConfig.couriers.find((courierSelect) => courierSelect.name === TRANSPORT_CARRIER);
    }

    await this.carrierProvider.getShipperCarriers().then(async (res) => {
      this.cleanNameOfCarriers(res.lineasTransporte);
      if (row.tripType === COURIERTRIP) {
        carrierData = res[CARRIER].find((carrier) => carrier._id === courierSelected.carrierId);
      } else {
        carrierData = res[CARRIER].find((carrier) => carrier.nombre === TRANSPORT_CARRIER.trim());
      }
      remainingOids[CARRIEROID] = carrierData;
    }).catch(() => {
      throw new Error(GETCARRIERERROR);
    });

    await this.vehicleTypeProvider.getVehiculeTypesActive(carrierData[ID])
      .then((res: Array<any>) => {
        const vehicles = res;
        if (row.tripType === COURIERTRIP) {
          remainingOids[VEHICLEOID] = vehicles.find((vehicle) => vehicle._id === courierSelected.vehicleId);
        } else {
          remainingOids[VEHICLEOID] = vehicles.find((vehicle) => vehicle.placas === row.transport.plates);
        }
      }).catch(() => {
        throw new Error(GETVEHICLESERROR);
      });

    await this.driverProvider.getDriversByCarrierId(carrierData[ID])
      .then((res: Array<any>) => {
        const drivers = res;

        if (row.tripType === COURIERTRIP) {
          remainingOids[DRIVEROID] = drivers.find((driver) => driver._id === courierSelected.driverId);
        } else {
          remainingOids[DRIVEROID] = drivers.find((driver) =>
          (driver[NAME] + ' ' + driver[FIRSTLASTNAME] + ' ' + driver[SECONDLASTNAME]) === row.transport.driver);
        }
      }).catch(() => {
        throw new Error(GETDRIVERERROR);
      });

      await this.loadTypeProvider.getLoadTypeByShipperId(shipperOid)
      .then((res: Array<Object>) => {
        const loadTypes = res;
        remainingOids[LOADTYPEOID] = loadTypes.find((loadType) => loadType[NAME] === row.loadType.toUpperCase());
      }).catch(() => {
        throw new Error(GETLOADTYPESERROR);
      });

      return remainingOids;
  }

  /**
   * @description Cleans string properties from all data carrier object to avoid problems with match/find by carrier name
   * @param {Array<Carrier>} carriers All Carriers to clean string info
   */
  public cleanNameOfCarriers(carriers: Array<Carrier>) {
    for (const carrier of carriers) {
      Object.keys(carrier).forEach(property => carrier[property] = typeof carrier[property] === STRING_TYPE ? carrier[property].trim() :
      carrier[property]);
    }
  }

  public setShipmentRequestValues(row, shipperOid, userOid, remainingOids, stopDetails, specialRequirements): Object {
    let trailer = STR_EMPTY;
    if (typeof row.transport.trailer === 'string' && row.transport.trailer !== STR_EMPTY) {
      trailer = row.transport.trailer;
    } else if (Array.isArray(row.transport.trailer) && row.transport.trailer.length) {
      trailer = row.transport.trailer;
    }

    const data = {
      fechaCarga: Math.floor(+new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
                              new Date().getHours(), new Date().getMinutes())) / TO_UNIX_VALUE,
      fechaCaduca: Math.floor(+new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) / TO_UNIX_VALUE,
      fechaInicioCarga: 0,
      fechaFinCarga: 0,
      cita: false,
      unidadTransporte:
        (row.tripType.toLowerCase() === TYPE_TRIP_PICK_UP.toLowerCase() || row.isCourierShipmentTrip) ?
          STR_EMPTY : remainingOids?.vehicleOid[TYPE][ID],
      totalCajas: row.boxes,
      shipmentServiceType: row.shipmentServiceType,
      totalPeso: row.weight,
      totalVolumen: row.volume,
      totalTarimas: row.pallets,
      totalPiezas: row.pieces,
      usuario_id: userOid,
      requiereAyudante: Boolean(row.transport.helper),
      nombreAyudante: row.transport.helper,
      operador: row.transport.driver,
      vehicleTypeName: row.transport.vehicle,
      placas: row.transport.plates,
      trailer: trailer,
      transportCarrier: row.transport.transportCarrier,
      referenciaInterna: row.shipmentId,
      seguro: false,
      tarifa: 0,
      embarcador: shipperOid,
      tipoCarga: remainingOids?.loadTypeOid,
      lineaTransporte: (row.tripType.toLowerCase() === TYPE_TRIP_PICK_UP.toLowerCase() || row.isCourierShipmentTrip) ?
        STR_EMPTY : remainingOids?.carrierOid[ID],
      embarque: {
        lineasTransporte: (row.tripType.toLowerCase() === TYPE_TRIP_PICK_UP.toLowerCase() || row.isCourierShipmentTrip) ?
          STR_EMPTY : remainingOids?.carrierOid[ID]
      },
      detalles: stopDetails,
      tipoViaje: row.tripType === CONSOLIDATED ? DISTRIBUTION : row.tripType,
      loadTendering: { activo: false },
      vehiculo_id: (row.tripType.toLowerCase() === TYPE_TRIP_PICK_UP.toLowerCase() || row.isCourierShipmentTrip) ?
        STR_EMPTY : remainingOids?.vehicleOid[ID],
      chofer_id: (row.tripType.toLowerCase() === TYPE_TRIP_PICK_UP.toLowerCase() || row.isCourierShipmentTrip) ?
        STR_EMPTY : remainingOids?.driverOid[ID],
      secondReference: row.internalReference,
      tipoCobro: row.typeOfCharge
    };

    if (row.loadStartDate) {
      data.fechaInicioCarga = moment(row.loadStartDate).unix();
    }
    if (row.loadEndDate) {
      data.fechaFinCarga = moment(row.loadEndDate).unix();
    }

    if (specialRequirements.length) {
      data[REQUIREMENT] = {
        requisitos: specialRequirements,
        tipo: (row.tripType.toLowerCase() === TYPE_TRIP_PICK_UP.toLowerCase() || row.isCourierShipmentTrip) ?
          STR_EMPTY : remainingOids?.loadTypeOid,
        tipoCarga: remainingOids?.loadTypeOid
      };
    }

    return data;
  }

  public async createShipment(data): Promise<Object> {
    let shipment;

    shipment = await this.shipmentProvider.createMobilityShipment(data).then((res) => {
      this.toastrAlertService.successAlert(SHIPMENTCREATED + res[SHIPMENT].id);
      return res;
    })
    .catch((err) => {
      const error = {
        message: SHIPMENTCREATEDERROR,
        error: err
      };
      return error;
    });

    return shipment;
  }

  /**
   * @description Creates an Array of requested orders ids
   * @param {row} row shipment information from view shipments table
   * @param {ordersIdsByStop} ordersIdsByStop orders ids on each shipment stop
   * @returns {response} Array of orders ids
   */
  public async createRequestOrdersIdsArray (row, ordersIdsByStop): Promise<Array<string>> {
    let response: Array<string>;
    response = [];
    if (ordersIdsByStop.length === row.orders.length) {
      row.orders.forEach(order => {
        response.push(order._id);
      });
      return response;
    } else {
      ordersIdsByStop.forEach(order => {
        response.push(order[0]);
      });
      return response;
    }
  }

  /**
   * @description Creates an Array of an object needed to set shipment values
   * @param {orders} orders found
   * @param {row} row shipment information from view shipments table
   * @param {Array<Array<string>>} ordersIdsByStop orders ids on each shipment stop
   * @param {Array<WarehouseBody>} warehouses to get info if is portage
   * @returns {Array<object>} Array of stops details
   */
  public async buildStopDetails (orders, row, ordersIdsByStop: Array<Array<string>>,
    warehouses: Array<WarehouseBody>): Promise<Array<object>> {
    const stopDetails = [];
    orders.forEach(stop => {
      const order = row.orders.find((singleOrder: { _id: any; }) => singleOrder._id === stop._id);
      const documentos = ordersIdsByStop.find(array => array.find(orderFound => orderFound === stop._id));
      const destination = this.buildStopDestination(stop, order, warehouses);
      const stopDetail = {
        fechaEntrega: stop.deliveryDate,
        origen: {
          direccion: stop.origin.address,
          codigoPostal: stop.origin.postalCode,
          municipio: stop.origin.municipality,
          estado: stop.origin.state,
          nombre: stop.origin.name,
          colonia: stop.origin.settlement,
          latitud: stop.origin.latitude,
          longitud: stop.origin.longitude
        },
        destino: destination,
        dimensiones: {
          alto: 0,
          ancho: 0,
          largo: 0
        },
        observaciones: row.observations,
        documentos: documentos,
        stopIsPortage: order.stopIsPortage,
        stop: order.stop
      };
      stopDetails.push(stopDetail);
    });

    const sortedStops = stopDetails.sort((stoptA, stoptB) =>  stoptA.stop - stoptB.stop);

    return sortedStops;
  }

  /**
   * @description Build stop destination depending if stop is portage or not
   * @param {object} currentStop to get info if isn't portage
   * @param {object} currentOrder order to verify if is portage
   * @param {Array<WarehouseBody>} warehouses to get info if is portage
   * @returns {object} stop destination
   */
  private buildStopDestination(currentStop: object, currentOrder: object, warehouses: Array<WarehouseBody>): object {
    let destination;
    const stop = Object(currentStop);
    const order = Object(currentOrder);
    if (!order.stopIsPortage) {
      destination = {
        direccion: stop.destination.address,
        codigoPostal: stop.destination.postalCode,
        municipio: stop.destination.municipality,
        estado: stop.destination.state,
        nombre: stop.destination.name,
        colonia: stop.destination.settlement,
        latitud: stop.destination.latitude,
        longitud: stop.destination.longitude,
        cuenta: stop.account._id,
        documentationDirections: stop.destination.documentationDirections,
        evidenceDirections: stop.destination.evidenceDirections
      };
    } else if (order.stopIsPortage) {
      const warehouseFound = warehouses.find(warehouse => warehouse._id === order.plannedWarehouse._id);
      destination = {
        direccion: warehouseFound.addressInfo.address,
        codigoPostal: warehouseFound.addressInfo.postalCode,
        municipio: warehouseFound.addressInfo.municipality,
        estado: warehouseFound.addressInfo.state,
        nombre: warehouseFound.warehouseName,
        colonia: warehouseFound.addressInfo.settlement,
        latitud: warehouseFound.addressInfo.latitude,
        longitud: warehouseFound.addressInfo.longitude,
        cuenta: warehouseFound.account ? warehouseFound.account._id : undefined,
        documentationDirections: stop.destination.documentationDirections,
        evidenceDirections: stop.destination.evidenceDirections
      };
    }

    return destination;
  }
}

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { NetPromoterScoreComponent } from '../../components/net-promoter-score/net-promoter-score.component';
import { NetPromoterScoreService } from '../../providers/net-promoter-score/net-promoter-score.service';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    UtilsModule
  ],
  declarations: [NetPromoterScoreComponent],
  exports: [NetPromoterScoreComponent],
  providers: [NetPromoterScoreService]
})

export class NetPromoterScoreModule { }
